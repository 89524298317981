import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import AdminLayout from "../../layout/AdminLayout";
import { useAuth } from "../../../context/AuthContext";
import * as model from "../../../models/CompaniesModel";
import { useNotificationCount } from "../../../context/NotificationCountContext";
import CompanyTable from "./CompanyTable";
import { ConfirmDelete } from "../../modals/ConfirmDelete";
import { apiError } from "../../../helpers/ErrorHelper";
import { toast } from "react-toastify";
import { CompanyForm } from "./CompanyForm";
import PageHeader from "../../common/PageHeader";
import config from "../../../config/config";
import { checkPermission } from "../../../helpers/GeneralHelper";
import { CompanyView } from "./CompanyView";
import { useDebouncedCallback } from 'use-debounce';

export default function Companies() {
  const module = {
    title: 'Companies',
    singularTitle: 'Company',
    key: 'companies',
    route: 'companies',
    primaryCol: '_company_id',
  };

  const { state } = useAuth();
  let user_data = state.user_data;

  const navigate = useNavigate();

  const { notificationCount, setNotificationCount } = useNotificationCount();

  const [modalKey, setModalKey] = useState(0);

  const [toggleAddModal, setToggleAddModal] = useState(false);

  // Update Start
  const [toggleUpdateModal, setToggleUpdateModal] = useState(false);
  const [singleData, setSingleData] = useState({});
  const openUpdateModal = (data) => {
    setSingleData(data);
    setModalKey((prevKey) => prevKey + 1);
    setToggleUpdateModal(true);
  };
  // Update End

  // Delete Start
  const [toggleDeleteModal, setToggleDeleteModal] = useState(false);
  const openDeleteModal = (data) => {
    setSingleData(data);
    setToggleDeleteModal(true);
  };

  const deleteData = async() => {
    setLoading(true);

    try {
      const response = await model.remove(user_data.user_token, singleData[module.primaryCol]);
      if (response.status) {
        setNotificationCount(localStorage.getItem('unread_notification_count'));
        toast.success(response.message);

        model.saveToStorage(response.data[module.key], response.data.pagination);
        refreshData(); 
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      apiError(error);

      if (error.response?.data.invalid_token == 1) {
        navigate('/re-login');
      }
    }

    setToggleDeleteModal(false);
    setLoading(false);
  }
  // Delete End

  const [toggleViewModal, setToggleViewModal] = useState(false);
  const handleView = (data) => {
    setSingleData(data);
    setToggleViewModal(true);
  }

  // Flush Start
  const flashData = async () => {
    localStorage.removeItem(module.key);
    fetchData(limit, page);
  };
  // Flush End

  // List Start
  const [listData, setListData] = useState([]);
  const [cacheTime, setCacheTime] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [isTrash, setIsTrash] = useState(false);

  const fetchTrash = (isTrash) => {
    setIsTrash(() => (isTrash));
  }

  const debounced = useDebouncedCallback(() => fetchData(limit, page), 500);

  const fetchData = async (newLimit, page) => {
    setLoading(true);

    try {
      let apiParam = {
        search: searchQuery,
        page: page,
        limit: newLimit,
        user_token: user_data.user_token,
        isTrash: isTrash ? 1 : 0,
      };
      const response = await model.getData(apiParam);
      if (typeof response == typeof undefined) {
        navigate("/re-login");
        return;
      }

      setListData(response[module.key]);
      setTotalRows(response.pagination.total_rows);
      setLimit(newLimit);
      setPage(page);

      setNotificationCount(localStorage.getItem("unread_notification_count"));

      if (response.time) {
        setCacheTime(response.time);
      }

      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };
  // List End

  const refreshData = async () => {
    fetchData(limit, page);
  }

  return (
    <AdminLayout>
      <Helmet>
        <title>{module.title} - Digital CMS</title>
      </Helmet>

      <div className="main-content app-content">
        <div className="container-fluid">
          <PageHeader module={module} isTrash={isTrash} toggleTrash={fetchTrash} />

          {/* <!-- Start::row-1 --> */}
          <div className="row">
            <div className="col-xl-12">
              <div className="card custom-card">
                {! isTrash && <div className="card-header d-flex align-items-center justify-content-between flex-wrap gap-3">
                <div className="card-title">{ config.isCache ? `Last Synced On ${cacheTime}` : module.title }</div>

                  <div className="d-flex flex-wrap gap-2">
                      {/* <input type="text" className="form-control table-search" onChange={(e) => {debounced(e.target.value); setSearchQuery(e.target.value);}} placeholder="Search..." autoComplete='off' /> */}

                      {checkPermission(module.route, 'add') &&
                        <button className="btn btn-primary btn-sm" onClick={() => setToggleAddModal(true)}>
                          <i className="ri-add-line me-1 fw-semibold align-middle"></i>
                          Add
                        </button>
                      }

                    {config.isCache ? 
                      <button className="btn btn-success-light btn-sm" onClick={() => flashData()}>
                        <i className="ri-refresh-line me-1 fw-semibold align-middle"></i> Refresh
                      </button> : ''
                    }
                  </div>
                </div>}
                <div className="card-body">
                  <div className="table-responsive">
                    <CompanyTable
                      module={module}
                      data={listData}
                      loading={loading}
                      totalRows={totalRows}
                      page={page}
                      limit={limit}
                      fetchData={fetchData}
                      openUpdateModal={openUpdateModal}
                      openDeleteModal={openDeleteModal}
                      handleView={handleView}
                      isTrash={isTrash}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!--End::row-1 --> */}

          {/* <!--Add Modal Start--> */}
          <CompanyForm
            module={module}
            showModal={toggleAddModal}
            setShowModal={setToggleAddModal}
            refreshData={refreshData}
          />
          {/* <!--Add Modal End--> */}

          {/* <!--Update Modal Start--> */}
          <CompanyForm
            module={module}
            showModal={toggleUpdateModal}
            setShowModal={setToggleUpdateModal}
            refreshData={refreshData}
            data={singleData}
            modalKey={modalKey}
          />
          {/* <!--Update Modal End--> */}

          {/* <!--Delete Modal Start--> */}
          <ConfirmDelete
            module={module}
            showModal={toggleDeleteModal}
            setShowModal={setToggleDeleteModal}
            loading={loading}
            onConfirm={deleteData}
          />
          {/* <!--Delete Modal End--> */}

          {/* View Modal */}
          <CompanyView
            module={module}
            showModal={toggleViewModal}
            setShowModal={setToggleViewModal}
            data={singleData}
          />
        </div>
      </div>

      <Helmet></Helmet>
    </AdminLayout>
  );
}
