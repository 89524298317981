import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import AdminLayout from '../../layout/AdminLayout';
import { useTheme } from '../../../context/ThemeContext';
import { useAuth } from '../../../context/AuthContext';
import { useNotificationCount } from '../../../context/NotificationCountContext';
import AddTabModal from '../../modals/Forms/AddTabModal';
import AddSectionModal from '../../modals/Forms/AddSectionModal';
import AddFieldModal from '../../modals/Forms/AddFieldModal';
import Input from '../../../components/Input';
import { editForm, getFormDetails, saveFormsToStorage } from '../../../models/FormModel';
import { getData as getCompanies, getFields } from '../../../models/CompaniesModel';
import config from '../../../config/config';
import EditFieldModal from '../../modals/Forms/EditFieldModal';
import DeleteFieldModal from '../../modals/Forms/DeleteFieldModal';
import EditSectionModal from '../../modals/Forms/EditSectionModal';
import DeleteSectionModal from '../../modals/Forms/DeleteSectionModal';
import EditTabModal from '../../modals/Forms/EditTabModal';
import DeleteTabModal from '../../modals/Forms/DeleteTabModal';
import AddWidgetModal from '../../modals/Forms/AddWidgetModal';
import EditWidgetModal from '../../modals/Forms/EditWidgetModal';
import DeleteWidgetModal from '../../modals/Forms/DeleteWidgetModal';
import AddWidgetFieldModal from '../../modals/Forms/AddWidgetFieldModal';
import EditWidgetFieldModal from '../../modals/Forms/EditWidgetFieldModal';
import DeleteWidgetFieldModal from '../../modals/Forms/DeleteWidgetFieldModal';

function filterFormFields(formData) {
    let filterdForm = {};
    filterdForm.tabs = [];
    filterdForm.widgets = [];

    for (let i = 0; i < formData?.tabs.length; i++) {
        let tab = {};
        tab.name = formData?.tabs[i]?.name;
        tab.sections = [];

        for (let j = 0; j < formData?.tabs[i]?.sections?.length; j++) {
            let section = {};
            section.heading = formData?.tabs[i]?.sections[j]?.heading;
            section.fields = [];

            for (let k = 0; k < formData?.tabs[i]?.sections[j]?.fields?.length; k++) {
                if (formData.tabs[i].sections[j].fields[k] != null) {
                    section.fields.push(formData.tabs[i].sections[j].fields[k]);
                }
            }

            tab.sections.push(section);
        }

        filterdForm.tabs.push(tab);
    }
    
    for (let i = 0; i < formData?.widgets?.length; i++) {
        let widget = {};
        widget.heading = formData?.widgets[i]?.heading;
        widget.isShowHeading = formData?.widgets[i]?.isShowHeading;
        widget.fields = [];

        for (let k = 0; k < formData?.widgets[i]?.fields?.length; k++) {
            if (formData.widgets[i].fields[k] != null) {
                widget.fields.push(formData.widgets[i].fields[k]);
            }
        }

        filterdForm.widgets.push(widget);
    }

    filterdForm.selectedTab = formData?.selectedTab;

    return filterdForm;
}

function EditForm() {

  const { notificationCount, setNotificationCount } = useNotificationCount();

  const { theme, toggleTheme } = useTheme();

  const { state } = useAuth(); 

  let user_data = state.user_data;

  const navigate = useNavigate();  

  const { form_unique_id } = useParams();

  const currentRoute = useLocation().pathname;  

  const loader = document.getElementById('loading');


  const [modalKey, setModalKey] = useState(0);

  const [companies, setCompanies] = useState([]);
  const [companyFields, setCompanyFields] = useState([]);
  
  const [form_title, setFormTitle] = useState('');
  const [formType, setFormType] = useState('');
  const [formCallbackUrl, setFormCallbackUrl] = useState('');
  const [formCompanyId, setFormCompanyId] = useState('');
  const [formData, setFormData] = useState({
    tabs: [],
    widgets: []
  });
  const [loading, setLoading] = useState(true);

  const [errors, setErrors] = useState({form_title: '', form_type: '', form_callback_url: '', form_field_json: ''});

  const formTypes = [{value: 'onboarding', label: 'OnBoarding'}, {value: 'avp', label: 'AVP'}];

  //Add Tab Modal Start
  const [showAddTabModal, setShowAddTabModal] = useState(false);
  //Add Tab Modal End


  const [selectedTab, setSelectedTab] = useState(0);


  //Add Section Modal Start
  const [showAddSectionModal, setShowAddSectionModal] = useState(false);

  const openAddSectionModal = (tabIndex) =>{
    setSelectedTab(tabIndex);
    setShowAddSectionModal(true);
  }
  //Add Section Modal End


  //Add Field Modal Start
  const [showAddFieldModal, setShowAddFieldModal] = useState(false);

  const openAddFieldModal = (sectionIndex, tabIndex) => {
    setSelectedTab(tabIndex);
    setSectionIndexData(sectionIndex);
    setShowAddFieldModal(true);
  }
  //Add Field Modal End

  const updateForm = async()=>{
    // Start the loading state
    loader.style.display = 'block';


    try {
        // Clear previous errors
        setErrors({});

        const form_data = {form_title, form_type: formType, form_callback_url: formCallbackUrl, form_field_json: JSON.stringify(filterFormFields(formData))};

        const response = await editForm(user_data.user_token, form_unique_id, form_data);

        if (response.status) {
            toast.success(response.message);

            saveFormsToStorage(response.data.forms, response.data.pagination);

            navigate(`${config.adminDir}/forms`);

            setNotificationCount(localStorage.getItem('unread_notification_count'));
        }
        else{
            setErrors(response.error);
            toast.error(response.message);
        }    
        
    } catch (error) {
        if (error?.response?.data?.invalid_token==1) {
            toast.error(error.response.data.message);
            navigate(`${config.adminDir}/re-login?redirect=${currentRoute}`);
        }
    }

    // Stop the loading state
    loader.style.display = 'none';
  }

  const fetchFields = async(companyId) => {
    if (! companyId) {
        return;
    }

    try {
        const response = await getFields(user_data.user_token, companyId);
        if (typeof response != typeof undefined) {
            let temp = [];
            response.data.fields.forEach(function(item) {
                temp.push({ value: item.cf_field_name, label: item.cf_field_name });
            });

            setCompanyFields(temp);
        }
    } catch (error) {
        console.error(error);
    }
  };

  const fetchFormDetails = async() => {
    setLoading(true);

    try {
        const response = await getFormDetails(form_unique_id);
        if (response.status) {
            if (response.data.form_data) {
                if (response.data.form_data.form_title) {
                    setFormTitle(response.data.form_data.form_title);
                    setFormType(response.data.form_data.form_type);
                    setFormCallbackUrl(response.data.form_data.form_callback_url);
                    setFormCompanyId(response.data.form_data.form_company_id);

                    fetchFields(response.data.form_data.form_company_id);
                }

                if (response.data.form_data.form_field_json) {
                    setFormData(filterFormFields(JSON.parse(response.data.form_data.form_field_json)));
                }

                setLoading(false);
            }
        } else {
            toast.error(response.message);
        }    
        
    } catch (error) {
        if (error?.response?.data?.invalid_token==1) {
            toast.error(error.response.data.message);
            navigate(`${config.adminDir}/re-login?redirect=${currentRoute}`);
        }
    }
}

useEffect(()=>{
    fetchFormDetails();

    const fetchCompanies = async() => {
        try {
            const response = await getCompanies({ user_token: user_data.user_token, limit: '-1'});
            if (typeof response != typeof undefined && response) {
                let result = [];
                response.companies.forEach(function(item) {
                    result.push({value: item._company_id, label: item.company_name});
                });

                setCompanies(result);
            }
        } catch (error) {
            console.error(error);
        }
    }

    fetchCompanies();
}, [form_unique_id]);

const [jsonData, setJsonData] = useState('');

const setJson = () => {
    try {
        const newFormObj = JSON.parse(jsonData);

        setFormData(filterFormFields(newFormObj));
        
    } catch (error) {
        toast.error('Invalid Json data');
    }
}

useEffect(()=>{
    setJsonData(JSON.stringify(formData, null, 2));  
}, [formData]);



//Drag & Drop Tab Start
const [draggedTab, setDraggedTab] = useState(null);

const handleTabDragStart = (event, tab) => {
    setDraggedTab(tab);
};

const handleTabDragOver = (event) => {
    event.preventDefault();
};

const handleTabDrop = (event, dropIndex) => {
    event.preventDefault();

    if (!draggedTab) return;

    const updatedTabs = [...formData.tabs];
    const draggedTabIndex = formData.tabs.indexOf(draggedTab);

    updatedTabs.splice(draggedTabIndex, 1); // Remove dragged tab
    updatedTabs.splice(dropIndex, 0, draggedTab); // Insert dragged tab at drop index

    setFormData(filterFormFields({ ...formData, tabs: updatedTabs }));
    setDraggedTab(null);
};
//Drag & Drop Tab End


//Drag & Drop Sections Start
const [draggedSection, setDraggedSection] = useState(null);

const handleSectionDragStart = (event, tabIdx, sectionIdx) => {
    setDraggedSection({ tabIdx, sectionIdx });
};

const handleSectionDragOver = (event) => {
    event.preventDefault();
};

const handleSectionDrop = (event, tabIndex, sectionIndex) => {
    event.preventDefault();

    if (!draggedSection) return;

    const { tabIdx: sourceTabIdx, sectionIdx: sourceSectionIdx } = draggedSection;
    const updatedTabs = [...formData.tabs];
    const draggedSectionData = updatedTabs[sourceTabIdx].sections.splice(sourceSectionIdx, 1)[0];

    if (sourceTabIdx === tabIndex) {
      updatedTabs[tabIndex].sections.splice(sectionIndex, 0, draggedSectionData);
    } else {
      updatedTabs[tabIndex].sections.splice(sectionIndex, 0, draggedSectionData);
    }

    setFormData(filterFormFields({ ...formData, tabs: updatedTabs }));
    setDraggedSection(null);
};
//Drag & Drop Sections End


//Drag & Drop Fields Start
const [draggedField, setDraggedField] = useState(null);

const handleFieldDragStart = (event, tabIdx, sectionIdx, fieldIdx) => {
    setDraggedField({ tabIdx, sectionIdx, fieldIdx });
};

const handleFieldDragOver = (event) => {
    event.preventDefault();
};

const handleFieldDrop = (event, tabIndex, sectionIndex, dropIndex) => {
    event.preventDefault();

    if (!draggedField) return;

    const { tabIdx: sourceTabIdx, sectionIdx: sourceSectionIdx, fieldIdx: sourceFieldIdx } = draggedField;
    const updatedTabs = [...formData.tabs];
    const draggedFieldData = updatedTabs[sourceTabIdx].sections[sourceSectionIdx].fields.splice(sourceFieldIdx, 1)[0];

    if (tabIndex === sourceTabIdx && sectionIndex === sourceSectionIdx) {
      updatedTabs[tabIndex].sections[sectionIndex].fields.splice(dropIndex, 0, draggedFieldData);
    } else {
      updatedTabs[tabIndex].sections[sectionIndex].fields.splice(dropIndex, 0, draggedFieldData);
    }

    setFormData(filterFormFields({ ...formData, tabs: updatedTabs }));
    setDraggedField(null);
};
//Drag & Drop Fields End


//Edit Field Modal Start
const [showUpdateFieldModal, setShowUpdateFieldModal] = useState(false);

const [fieldData, setFieldData] = useState({});

const [fieldIndexData, setFieldIndexData] = useState('');
const [sectionIndexData, setSectionIndexData] = useState('');
const [tabIndexData, setTabIndexData] = useState('');

const openUpdateFieldModal = (field_data, fieldIndex, sectionIndex, tabIndex) =>{
    //console.log(field_data);
    setFieldData(field_data);

    setFieldIndexData(fieldIndex);
    setSectionIndexData(sectionIndex);
    setTabIndexData(tabIndex);

    setModalKey(prevKey => prevKey + 1); 
    setShowUpdateFieldModal(true);
} 
//Edit Field Modal End


//Delete Field Modal Start
const [showDeleteFieldModal, setShowDeleteFieldModal] = useState(false);

const openDeleteFieldModal = (field_data, fieldIndex, sectionIndex, tabIndex) =>{
    //console.log(field_data);
    setFieldData(field_data);

    setFieldIndexData(fieldIndex);
    setSectionIndexData(sectionIndex);
    setTabIndexData(tabIndex);

    setModalKey(prevKey => prevKey + 1); 
    setShowDeleteFieldModal(true);
} 
//Delete Field Modal End



//Edit Section Modal Start
const [showUpdateSectionModal, setShowUpdateSectionModal] = useState(false);

const [sectionData, setSectionData] = useState({});

const openUpdateSectionModal = (section_data, sectionIndex, tabIndex) =>{
    //console.log(section_data);
    setSectionData(section_data);

    setSectionIndexData(sectionIndex);
    setTabIndexData(tabIndex);

    setModalKey(prevKey => prevKey + 1); 
    setShowUpdateSectionModal(true);
} 
//Edit Section Modal End


//Delete Section Modal Start
const [showDeleteSectionModal, setShowDeleteSectionModal] = useState(false);

const openDeleteSectionModal = (section_data, sectionIndex, tabIndex) =>{
    //console.log(section_data);
    setSectionData(section_data);

    setSectionIndexData(sectionIndex);
    setTabIndexData(tabIndex);

    setModalKey(prevKey => prevKey + 1); 
    setShowDeleteSectionModal(true);
} 
//Delete Section Modal Start


//Edit Tab Modal Start
const [showUpdateTabModal, setShowUpdateTabModal] = useState(false);

const [tabData, setTabData] = useState({});

const openUpdateTabModal = (tab_data, tabIndex) =>{
    //console.log(section_data);
    setTabData(tab_data);

    setTabIndexData(tabIndex);

    setModalKey(prevKey => prevKey + 1); 
    setShowUpdateTabModal(true);
} 
//Edit Tab Modal End


//Delete Tab Modal Start
const [showDeleteTabModal, setShowDeleteTabModal] = useState(false);

const openDeleteTabModal = (tab_data, tabIndex) =>{
    //console.log(section_data);
    setTabData(tab_data);

    setTabIndexData(tabIndex);

    setModalKey(prevKey => prevKey + 1); 
    setShowDeleteTabModal(true);
} 
//Delete Tab Modal Start


//Add Widget Modal Start
const [showAddWidgetModal, setShowAddWidgetModal] = useState(false);
//Add Widget Modal End


//Edit Widget Modal Start
const [showUpdateWidgetModal, setShowUpdateWidgetModal] = useState(false);

const [widgetData, setWidgetData] = useState({});

const [widgetIndexData, setWidgetIndexData] = useState('');

const openUpdateWidgetModal = (widgetIndex, widget) =>{

    setWidgetData(widget);

    setWidgetIndexData(widgetIndex);


    setModalKey(prevKey => prevKey + 1); 
    setShowUpdateWidgetModal(true);
}    
//Edit Widget Modal End


//Delete Widget Modal Start
const [showDeleteWidgetModal, setShowDeleteWidgetModal] = useState(false);

const openDeleteWidgetModal = (widgetIndex, widget) =>{

    setWidgetData(widget);

    setWidgetIndexData(widgetIndex);


    setModalKey(prevKey => prevKey + 1); 
    setShowDeleteWidgetModal(true);
} 
//Delete Widget Modal End


//Add Widget Field Modal Start
const [showAddWidgetFieldModal, setShowAddWidgetFieldModal] = useState(false);

const openAddWidgetFieldModal = (widgetIndex, widget) =>{
    //console.log(field_data);
    setWidgetData(widget);

    setWidgetIndexData(widgetIndex);


    setModalKey(prevKey => prevKey + 1); 
    setShowAddWidgetFieldModal(true);
} 
//Add Widget Field Modal End


//Edit Widget Field Modal Start
const [widgetFieldData, setWidgetFieldData] = useState({});
const [widgetFieldIndexData, setWidgetFieldIndexData] = useState('');

const [showUpdateWidgetFieldModal, setShowUpdateWidgetFieldModal] = useState(false);

const openUpdateWidgetFieldModal = (field, fieldIndex, widgetIndex) =>{
    //console.log(field_data);
    setWidgetFieldData(field);

    setWidgetIndexData(widgetIndex);

    setWidgetFieldIndexData(fieldIndex);


    setModalKey(prevKey => prevKey + 1); 
    setShowUpdateWidgetFieldModal(true);
} 
//Edit Widget Field Modal End


//Delete Widget Field Modal Start
const [showDeleteWidgetFieldModal, setShowDeleteWidgetFieldModal] = useState(false);

const openDeleteWidgetFieldModal = (field, fieldIndex, widgetIndex) =>{
    //console.log(field_data);
    setWidgetFieldData(field);

    setWidgetIndexData(widgetIndex);

    setWidgetFieldIndexData(fieldIndex);


    setModalKey(prevKey => prevKey + 1); 
    setShowDeleteWidgetFieldModal(true);
} 
//Delete Widget Field Modal End

const copyJson = () =>{
    navigator.clipboard.writeText(jsonData)
      .then(() => {
        toast.success('Copied to clipboard:', jsonData);
        // You can optionally provide feedback to the user that the text was copied successfully
      })
      .catch((error) => {
        toast.error('Error copying to clipboard:');
        // You can provide feedback to the user about the error
      });
}

  return (
        <AdminLayout>
        
            <Helmet>
                <title>Update Form - Funnl Onboarding </title>
            </Helmet>

            <div className="main-content app-content">

                
                <div className="container-fluid">

                    {/* <!-- Page Header --> */}
                    <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                        <h1 className="page-title fw-semibold fs-18 mb-0">
                            Update Form
                            &nbsp;
                            <Link to={`/form/${form_unique_id}`} target="_blank" className="btn btn-icon btn-sm btn-dark" title="Form Link" >
                                <i className='bi bi-box-arrow-up-right'></i>
                            </Link>
                        </h1>
                        <div className="ms-md-1 ms-0">
                            <nav>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><a href="javascript:void(0);">Dashboard</a></li>
                                    <li className="breadcrumb-item">Forms</li>
                                    <li className="breadcrumb-item active" aria-current="page">Update Form</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    {/* <!-- Page Header Close --> */}

                   
                    
                   {/* <!-- Start::row-1 --> */}
                   {loading===true ? (
                    <div className="row">
                        <div className="col-xl-12">
                            <div className='d-flex justify-content-center my-3'>
                                <span className="loader-new"></span>
                            </div>
                        </div>
                    </div>
                    ) : (
                        <div className="row">
                            <div className="col-xl-8">
                                <div className="card custom-card">
                                    <div className="card-header justify-content-between">
                                        <div className="card-title me-auto">
                                            Form Builder
                                        </div>
                                    </div>
                                    <div className="card-body" style={{minHeight: '200px'}}>
                                        <div className='row'>
                                            <div className='col-xl-12'>
                                                <label className="form-label">Title <span className="text-danger">*</span></label>
                                                <input type='text' className='form-control' value={form_title} onChange={(e) => setFormTitle(e.target.value)} />

                                                {errors.form_title && <p style={{ color: 'red' }}>{errors.form_title}</p>}
                                            </div>

                                            <div className="col-xl-12 mt-3">
                                                <label className="form-label">Company: </label>
                                                
                                                <select className='form-control' value={formCompanyId} disabled>
                                                    <option value="">Company Not Selected</option>
                                                    {companies.map((item) => (
                                                        <option key={item.value} value={item.value}>{item.label}</option>
                                                    ))}
                                                </select>
                                                {errors.title && <p style={{ color: 'red' }}>{errors.title}</p>}
                                            </div>

                                            <div className="col-xl-12 mt-3">
                                                <label className="form-label">Form Type: </label>
                                                
                                                <select className='form-control' value={formType} onChange={(e) => setFormType(e.target.value)}>
                                                    <option value="">(select form type)</option>
                                                    {formTypes.map((item) => (
                                                        <option key={item.value} value={item.value}>{item.label}</option>
                                                    ))}
                                                </select>
                                                {errors.form_type && <p style={{ color: 'red' }}>{errors.form_type}</p>}
                                            </div>

                                            <div className="col-xl-12 mt-3">
                                                <label className="form-label">Callback URL: </label>
                                                <input type='text' className='form-control' value={formCallbackUrl} onChange={(e) => setFormCallbackUrl(e.target.value)} />
                                                {errors.form_callback_url && <p style={{ color: 'red' }}>{errors.form_callback_url}</p>}
                                            </div>
                                        </div>  
                                        
                                        <div className='row mt-4'>
                                            <div className='col-xl-12'>
                                            <div className="btn-group my-1">
                                                <button type="button" className="btn btn-light">Tabular Forms</button>
                                                <button type="button" title='Add Tab' className="btn btn-light dropdown-toggle-split me-2"
                                                    onClick={() => setShowAddTabModal(true)} >
                                                    <i className="bi bi-plus-circle"></i>
                                                </button>
                                            </div>


                                            {formData.tabs.length > 0 && (
                                            <ul className="nav nav-pills justify-content-center nav-style-2 mb-3" role="tablist">
                                                {formData.tabs.map((tab, tabIndex) => (
                                                <li key={tabIndex} className="nav-item" role="presentation"
                                                onDragOver={(event) => handleTabDragOver(event)}
                                                onDrop={(event) => handleTabDrop(event, 0)} >

                                                    <div className="btn-group my-1">
                                                        <a className={`nav-link ${tabIndex==0 ? 'active' : ''}`} data-bs-toggle="tab" role="tab" 
                                                            aria-current="page" 
                                                            href={`#tab-${tabIndex}`} 
                                                            aria-selected="true">{tab.name}
                                                        </a>
                                                        <button type="button"
                                                            className="btn btn-primary dropdown-toggle dropdown-toggle-split me-2"
                                                            data-bs-toggle="dropdown" aria-expanded="false">
                                                            
                                                        </button>
                                                        <ul className="dropdown-menu">
                                                            <li>
                                                                <a className="dropdown-item cur-point" onClick={() => openAddSectionModal(tabIndex)}>
                                                                    <i className="bi bi-plus-circle"></i> Add Section
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a className="dropdown-item cur-point" onClick={() => openUpdateTabModal(tab, tabIndex)}>
                                                                    <i className="bi bi-pencil-square"></i> Edit
                                                                </a>
                                                            </li>
                                                            <li><hr className="dropdown-divider" /></li>
                                                            <li>
                                                                <a className="dropdown-item cur-point" onClick={() => openDeleteTabModal(tab, tabIndex)}>
                                                                    <i className="bi bi-trash"></i> Delete
                                                                </a>
                                                            </li>
                                                            <li><hr className="dropdown-divider" /></li>
                                                            <li>
                                                                <a className="dropdown-item cur-point" draggable
                                                                    onDragStart={(event) => handleTabDragStart(event, tab)}>
                                                                    <i className="bi bi-arrows-move"></i> Move
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>
                                                ))}
                                            </ul>
                                            )}

                                            {formData?.tabs.length > 0 && (
                                            <div className="tab-content">
                                                {formData?.tabs?.map((tab, tabIndex) => (
                                                    <div className={`tab-pane show ${tabIndex==0 ? 'active' : ''} text-muted`} id={`tab-${tabIndex}`} role="tabpanel" key={tabIndex}>
                                                        {tab?.sections?.map((section, sectionIndex) => (
                                                            <>
                                                            <div className='row mb-3' key={sectionIndex}>

                                                                <div className='col-md-12'>
                                                                    <div className="btn-group dropend">
                                                                        <button className="btn btn-light dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                            {section.heading}
                                                                        </button>
                                                                        <ul className="dropdown-menu dropdown-menu-light">
                                                                            <li>
                                                                                <a className="dropdown-item cur-point" onClick={() => openAddFieldModal(sectionIndex, tabIndex)}>
                                                                                    <i className="bi bi-plus-circle"></i> Add Field
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a className="dropdown-item cur-point" onClick={() => openUpdateSectionModal(section, sectionIndex, tabIndex)}>
                                                                                    <i className="bi bi-pencil-square"></i> Edit
                                                                                </a>
                                                                            </li>
                                                                            <li><hr className="dropdown-divider" /></li>
                                                                            <li>
                                                                                <a className="dropdown-item cur-point" onClick={() => openDeleteSectionModal(section, sectionIndex, tabIndex)}>
                                                                                    <i className="bi bi-trash"></i> Delete
                                                                                </a>
                                                                            </li>
                                                                            <li><hr className="dropdown-divider" /></li>
                                                                            <li>
                                                                                <a className="dropdown-item cur-point" draggable
                                                                                    onDragStart={(event) => handleSectionDragStart(event, tabIndex, sectionIndex)}>
                                                                                    <i className="bi bi-arrows-move"></i> Move
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>

                                                                <div className='col-md-12' style={{display: draggedSection!==null &&  draggedSection.sectionIdx!=sectionIndex ? 'block' : 'none'}}>
                                                                    <div className='form-control mt-2 mb-2 drop-section-box' 
                                                                    onDragOver={(event) => handleSectionDragOver(event)}
                                                                    onDrop={(event) => handleSectionDrop(event, tabIndex, sectionIndex)} >
                                                                        Drop Section Here
                                                                    </div>
                                                                </div>

                                                            </div>
                                                                
                                                                
                                                            <div className='row g-3 mb-4'>
                                                                {section?.fields.map((field, fieldIndex) => {

                                                                    if (! field) return;

                                                                    return <div className={`col-md-${field?.width}`} 
                                                                        key={fieldIndex}
                                                                        onDragOver={(event) => handleFieldDragOver(event)}
                                                                        onDrop={(event) => handleFieldDrop(event, tabIndex, sectionIndex, 0)}
                                                                        draggable
                                                                        onDragStart={(event) => handleFieldDragStart(event, tabIndex, sectionIndex, fieldIndex)} >
                                                                            <div className="input-group flex-nowrap">
                                                                            {/* justify-content-between */}

                                                                            <Input 
                                                                                type={field.type} 
                                                                                name={field.name}
                                                                                placeholder={field.placeholder} 
                                                                                label={field.label}
                                                                                width={field.width}
                                                                                fontSize={field.fontSize}
                                                                                options={field.options}
                                                                                isSearchable={field.isSearchable}
                                                                                isMulti={field.isMulti}
                                                                                showLabel={false}
                                                                                mapField={field.mapField} />
                                                                            
                                                                            {/* <!---Input Dropdown Start---> */}
                                                                            <div className="dropdown">
                                                                                <a className="btn btn-light cur-point"  role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                    <i className="bi bi-three-dots-vertical"></i>
                                                                                </a>
                                                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                                                    <li>
                                                                                        <a className="dropdown-item cur-point" onClick={() => openUpdateFieldModal(field, fieldIndex, sectionIndex, tabIndex)}>
                                                                                            <i className="bi bi-pencil-square"></i> Edit
                                                                                        </a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a className="dropdown-item cur-point" onClick={() => openDeleteFieldModal(field, fieldIndex, sectionIndex, tabIndex)}>
                                                                                            <i className="bi bi-trash"></i> Delete
                                                                                        </a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a className="dropdown-item cur-point d-none">
                                                                                            <i className="bi bi-arrows-move"></i> Move
                                                                                        </a>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                            {/* <!---Input Dropdown End---> */}

                                                                            </div>
                                                                    </div>
                                                                })}   
                                                            </div>
                                                            </>
                                                        ))}    
                                                    </div>
                                                ))}   
                                            </div>   
                                            )} 

                                            {errors.form_field_json && <p style={{ color: 'red' }}>{errors.form_field_json}</p>}
                                            </div>
                                        </div>
                                        
                                    </div>

                                    <div className="card-footer">
                                        <button className="btn btn-primary-light btn-wave ms-auto float-end" onClick={updateForm}>Update</button>
                                    </div> 
                                </div>
                            </div>

                            <div className="col-xl-4">
                                <div className="card custom-card">
                                    <div className="card-header justify-content-between">
                                        <div className="card-title me-auto">
                                            

                                            <div className="btn-group my-1">
                                                <button type="button" className="btn btn-light">Widgets</button>
                                                <button type="button" title='Add Widget' className="btn btn-light dropdown-toggle-split me-2"
                                                    onClick={() => setShowAddWidgetModal(true)} >
                                                    <i className="bi bi-plus-circle"></i>
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="card-body" style={{minHeight: '200px'}}>
                                    
                                        {formData.widgets.length > 0 && (
                                            formData.widgets.map((widget, widgetIndex) => (
                                            <div className="accordion" key={'widget_'.widgetIndex}>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id={`widget-heading-${widgetIndex}`}>
                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                                            data-bs-target={`#widget-id-${widgetIndex}`} aria-expanded={widgetIndex == 0}
                                                            aria-controls={`widget-id-${widgetIndex}`}>
                                                            {widget.heading}
                                                        </button>
                                                    </h2>
                                                    <div id={`widget-id-${widgetIndex}`} className={`accordion-collapse collapse ${widgetIndex==0 ? 'show' : ''}`}
                                                        aria-labelledby={`widget-heading-${widgetIndex}`}>
                                                        <div className="accordion-body">
                                                            
                                                        <div className='row mb-3' key={widgetIndex}>

                                                            <div className='col-md-12'>
                                                                <div className="btn-group dropend">
                                                                    <button className="btn btn-light dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <i className="bi bi-gear"></i>
                                                                    </button>
                                                                    <ul className="dropdown-menu dropdown-menu-light">
                                                                        <li>
                                                                            <a className="dropdown-item cur-point" onClick={() => openAddWidgetFieldModal(widgetIndex)}>
                                                                                <i className="bi bi-plus-circle"></i> Add Field
                                                                            </a>
                                                                        </li>
                                                                        <li>
                                                                            <a className="dropdown-item cur-point" onClick={() => openUpdateWidgetModal(widgetIndex, widget)}>
                                                                                <i className="bi bi-pencil-square"></i> Edit
                                                                            </a>
                                                                        </li>
                                                                        <li><hr className="dropdown-divider" /></li>
                                                                        <li>
                                                                            <a className="dropdown-item cur-point" onClick={() => openDeleteWidgetModal(widgetIndex, widget)}>
                                                                                <i className="bi bi-trash"></i> Delete
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>

                                                        </div>


                                                            <div className='row g-3 mb-4'>
                                                                {widget?.fields.map((field, fieldIndex) => (

                                                                <div className={`col-md-${field.width}`} 
                                                                    key={field.name + fieldIndex}
                                                                    >
                                                                    <div className="input-group flex-nowrap">

                                                                        <Input 
                                                                            type={field.type} 
                                                                            name={field.name}
                                                                            placeholder={field.placeholder} 
                                                                            label={field.label}
                                                                            width={field.width}
                                                                            fontSize={field.fontSize}
                                                                            options={field.options}
                                                                            isSearchable={field.isSearchable}
                                                                            isMulti={field.isMulti}
                                                                            showLabel={false}
                                                                            mapField={field.mapField} />
                                                                        
                                                                        {/* <!---Input Dropdown Start---> */}
                                                                        <div className="dropdown">
                                                                            <a className="btn btn-light cur-point"  role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                <i className="bi bi-three-dots-vertical"></i>
                                                                            </a>
                                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                                                <li>
                                                                                    <a className="dropdown-item cur-point" onClick={() => openUpdateWidgetFieldModal(field, fieldIndex, widgetIndex)}>
                                                                                        <i className="bi bi-pencil-square"></i> Edit
                                                                                    </a>
                                                                                </li>
                                                                                <li>
                                                                                    <a className="dropdown-item cur-point" onClick={() => openDeleteWidgetFieldModal(field, fieldIndex, widgetIndex)}>
                                                                                        <i className="bi bi-trash"></i> Delete
                                                                                    </a>
                                                                                </li>
                                                                                
                                                                            </ul>
                                                                        </div>
                                                                        {/* <!---Input Dropdown End---> */}

                                                                    </div>
                                                                </div>

                                                                ))}          
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
            
                                            </div> 
                                            ))    
                                        )}     
                                           
                                    </div>

                                </div>    

                                <div className="card custom-card d-none-1">

                                    <div className="card-header justify-content-between">
                                        <div className="card-title me-auto">
                                            Form Builder JSON &nbsp;&nbsp;&nbsp;
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" onClick={copyJson} fill="currentColor" title="Copy to Clipboard" className="bi bi-copy cur-point"  viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/>
                                            </svg> 
                                        </div>
                                    </div>
                                        
                                    <div className="card-body" style={{minHeight: '400px'}}>
                                        <textarea className='form-control' rows="15" value={jsonData} onChange={(e) => setJsonData(e.target.value)}></textarea>
                                    </div>

                                    <div className="card-footer">
                                        <button className="btn btn-primary-light btn-wave ms-auto float-end" onClick={setJson}>Set JSON</button>
                                    </div> 
                                </div>     
                            </div>

                            
                        </div>
                    )}
                   {/* <!-- End::row-1 --> */}

                    {/* <!--Add Tab Modal Start--> */}
                    <AddTabModal
                        showModal={showAddTabModal}
                        setShowModal={setShowAddTabModal}
                        formData={formData} 
                        setFormData={setFormData} />
                    {/* <!--Add Tab Modal End--> */}

                    {/* <!--Edit Tab Modal Start--> */}
                    <EditTabModal
                        showModal={showUpdateTabModal}
                        setShowModal={setShowUpdateTabModal}
                        formData={formData} 
                        setFormData={setFormData}
                        tabData={tabData}
                        modalKey={modalKey}
                        tabIndex={tabIndexData}  />
                    {/* <!--Edit Tab Modal End--> */}


                    {/* <!--Delete Tab Modal Start--> */}
                    <DeleteTabModal
                        showModal={showDeleteTabModal}
                        setShowModal={setShowDeleteTabModal}
                        formData={formData} 
                        setFormData={setFormData}
                        modalKey={modalKey}
                        tabIndex={tabIndexData}  />
                    {/* <!--Delete Tab Modal End--> */}



                    {/* <!--Add Section Modal Start--> */}
                    <AddSectionModal
                        showModal={showAddSectionModal}
                        setShowModal={setShowAddSectionModal}
                        selectedTab={selectedTab}
                        formData={formData} 
                        setFormData={setFormData} />
                    {/* <!--Add Section Modal End--> */}


                    {/* <!--Edit Section Modal Start--> */}
                    <EditSectionModal
                        showModal={showUpdateSectionModal}
                        setShowModal={setShowUpdateSectionModal}
                        selectedTab={selectedTab}
                        formData={formData} 
                        setFormData={setFormData}
                        modalKey={modalKey}
                        sectionData={sectionData}
                        sectionIndex={sectionIndexData} />
                    {/* <!--Edit Section Modal End--> */}


                    {/* <!--Delete Section Modal Start--> */}
                    <DeleteSectionModal
                        showModal={showDeleteSectionModal}
                        setShowModal={setShowDeleteSectionModal}
                        formData={formData} 
                        setFormData={setFormData}
                        sectionIndex={sectionIndexData}
                        tabIndex={tabIndexData} />
                    {/* <!--Delete Section Modal End--> */}


                    {/* <!--Add Field Modal Start--> */}
                    <AddFieldModal
                        showModal={showAddFieldModal}
                        setShowModal={setShowAddFieldModal}
                        selectedTab={selectedTab}
                        formData={formData} 
                        setFormData={setFormData}
                        sectionIndex={sectionIndexData}
                        companyFields={companyFields} />
                    {/* <!--Add Field Modal End--> */}


                    {/* <!--Edit Field Modal Start--> */}
                    <EditFieldModal
                        showModal={showUpdateFieldModal}
                        setShowModal={setShowUpdateFieldModal}
                        selectedTab={selectedTab}
                        formData={formData} 
                        setFormData={setFormData}
                        modalKey={modalKey}
                        fieldData={fieldData}
                        fieldIndex={fieldIndexData}
                        sectionIndex={sectionIndexData}
                        tabIndex={tabIndexData}
                        companyFields={companyFields} />
                    {/* <!--Edit Field Modal End--> */}


                    {/* <!--Delete Field Modal Start--> */}
                    <DeleteFieldModal
                        showModal={showDeleteFieldModal}
                        setShowModal={setShowDeleteFieldModal}
                        formData={formData} 
                        setFormData={setFormData}
                        fieldIndex={fieldIndexData}
                        sectionIndex={sectionIndexData}
                        tabIndex={tabIndexData} />
                    {/* <!--Delete Field Modal End--> */}


                    {/* <!--Add Widget Modal Start--> */}
                    <AddWidgetModal
                        showModal={showAddWidgetModal}
                        setShowModal={setShowAddWidgetModal}
                        formData={formData} 
                        setFormData={setFormData} />
                    {/* <!--Add Widget Modal End--> */}


                    {/* <!--Edit Widget Modal Start--> */}
                    <EditWidgetModal
                        showModal={showUpdateWidgetModal}
                        setShowModal={setShowUpdateWidgetModal}
                        formData={formData} 
                        setFormData={setFormData}
                        modalKey={modalKey}
                        widgetIndex={widgetIndexData}
                        widgetData={widgetData} />
                    {/* <!--Edit Widget Modal End--> */}


                    {/* <!--Delete Widget Modal Start--> */}
                    <DeleteWidgetModal
                        showModal={showDeleteWidgetModal}
                        setShowModal={setShowDeleteWidgetModal}
                        formData={formData} 
                        setFormData={setFormData}
                        widgetIndex={widgetIndexData} />
                    {/* <!--Delete Widget Modal End--> */}


                     {/* <!--Add Widget Field Modal Start--> */}
                     <AddWidgetFieldModal
                        showModal={showAddWidgetFieldModal}
                        setShowModal={setShowAddWidgetFieldModal}
                        formData={formData} 
                        setFormData={setFormData}
                        widgetIndex={widgetIndexData}
                        companyFields={companyFields} />
                    {/* <!--Add Widget Field Modal End--> */}


                    {/* <!--Edit Widget Field Modal Start--> */}
                    <EditWidgetFieldModal
                        showModal={showUpdateWidgetFieldModal}
                        setShowModal={setShowUpdateWidgetFieldModal}
                        formData={formData} 
                        setFormData={setFormData}
                        modalKey={modalKey}
                        fieldData={widgetFieldData}
                        fieldIndex={widgetFieldIndexData}
                        widgetIndex={widgetIndexData}
                        companyFields={companyFields} />
                    {/* <!--Edit Widget Field Modal End--> */}


                    {/* <!--Delete Widget Field Modal Start--> */}
                    <DeleteWidgetFieldModal
                        showModal={showDeleteWidgetFieldModal}
                        setShowModal={setShowDeleteWidgetFieldModal}
                        formData={formData} 
                        setFormData={setFormData}
                        fieldIndex={widgetFieldIndexData}
                        widgetIndex={widgetIndexData} />
                    {/* <!--Edit Widget Field Modal End--> */}

                </div>


            </div>

            <Helmet>
                
            </Helmet>
        
        </AdminLayout>
        
  )
}

export default EditForm
