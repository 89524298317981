import React, { useEffect, useRef,  useState }  from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { apiError } from '../../helpers/ErrorHelper';
import { decryptData } from '../../helpers/EncryptHelper';
import { useAuth } from '../../context/AuthContext';
import { useTheme } from '../../context/ThemeContext';
import { useSideMenu } from '../../context/SideMenuContext';
import { profilePic } from '../../helpers/GeneralHelper';
import { clearStorageData, userLogout } from '../../models/UserModel';
import { useNotificationCount } from '../../context/NotificationCountContext';
import { getNotifications, updateReadNotification } from '../../models/NotificationModel';
import config from '../../config/config';



function Header() { 

  const currentRoute =  useLocation().pathname;    

  const { notificationCount, setNotificationCount } = useNotificationCount();

  const { theme, toggleTheme, selectBoxTheme } = useTheme();

  const { sideMenu, toggleSideMenu } = useSideMenu();

  const [fullScreen, setFullScreen] = useState(false);

  const fullScreenToggle = ()=> {
    //alert('hello');
    if (fullScreen===true) {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
            document.msExitFullscreen();
        }

        setFullScreen(false);
    }
    else{
        let elem = document.documentElement;

        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
            elem.msRequestFullscreen();
        }

        setFullScreen(true);
    }
  }
  
  const [user_data, setUserData] = useState({}); 

  const navigate = useNavigate(); 
  
  const { state, dispatch } = useAuth();  

    useEffect(()=>{
        if (!state.isAuthenticated) {
            navigate(`${config.adminDir}/login`);
            //toast.error('You are not login yet to access here');
          }
          else{
            setUserData(state.user_data);
          }  
    }, [state]);

  const loader = document.getElementById('loading');  

  //Logout Start
  const doLogout = async () => {
    // Start the loading state
    loader.style.display = 'block';

    try {
        const response = await userLogout(user_data.user_token);

        if (response.status) {
            toast.success(response.message);
        } 
        else{
            toast.error(response.message);
        }
        
    } catch (error) {
        apiError(error);
    }


    // Stop the loading state
    loader.style.display = 'none';

    clearStorageData();
    
    dispatch({ type: 'LOGOUT', payload: {} });
    navigate(`${config.adminDir}/login`);
  }
  //Logout End




  //Desktop Notification Start
  const [permission, setPermission] = useState('');

  const allowDesktopNotification = () =>{
    if (!("Notification" in window)) {
	    toast.error("This browser does not support desktop notification");
	}
    // Let's check whether notification permissions have already been granted
    else if (Notification.permission === "granted") {
      toast.error("Your desktop notification is already enabled.");
      setPermission('granted');
    }
    // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== "denied") {
      Notification.requestPermission().then(function (permission) {
        // If the user accepts, let's create a notification
	    if (permission === "granted") {
	        toast.success("Desktop notification is enabled successfully.");
	        setPermission('granted');
	    }
	    else if(permission === "denied"){
	    	toast.error("You have blocked your desktop notification. You won't receive any further updates.");
	        setPermission('denied');
	    }
      });
    }	
  }

  useEffect(() => {  
    setPermission(Notification.permission);
  }, [Notification.permission]);
  //Desktop Notification End


  //Fetch Notifications Start
  const [notificationLoading, setNotificationLoading] = useState(true);
  const [notifications, setNotifications] = useState([]); 

  const fetchNotifications = async() => {
    try {
        let apiParam = {page: 1, limit: 5, is_read: 0};

        const response = await getNotifications(user_data.user_token, apiParam);

        if (response.status===true) {

            if (response.data.notifications) {
                setNotifications(response.data.notifications);
            }

            setNotificationCount(localStorage.getItem('unread_notification_count'));
        }
        else{
            if (typeof response.response.data.invalid_token != typeof undefined && response.response.data.invalid_token=='1') {
                navigate(`/re-login?redirect=${currentRoute}`);
            }
    
            toast.error(response.message);
        }
        
        setNotificationLoading(false);
        
    } catch (error) {
        //console.log(error);
    }
  }
  //Fetch Notifications End


//Read Notification Start
const readNotification = async(notificationObj) => {
    try {
        const response = await updateReadNotification(user_data.user_token, notificationObj._notification_id);

        if (response.status===true) {

            setNotificationCount(localStorage.getItem('unread_notification_count'));
        }
        else{
            if (typeof response.response.data.invalid_token != typeof undefined && response.response.data.invalid_token=='1') {
                navigate(`/re-login?redirect=${currentRoute}`);
            }
    
            toast.error(response.message);
        }
        
    } catch (error) {
        //console.log(error);
    }
  }
  //Read Notification End  
  

  return (
    <>
      <header className="app-header">

        {/* <!-- Start::main-header-container --> */}
        <div className="main-header-container container-fluid">

            {/* <!-- Start::header-content-left --> */}
            <div className="header-content-left">

                {/* <!-- Start::header-element --> */}
                <div className="header-element">
                    <div className="horizontal-logo">
                        <Link to={`${config.adminDir}/dashboard`} className="header-logo">
                        <img src={`${process.env.REACT_APP_BASE_URL}/assets/images/logo-wide.png`} alt="logo" className="desktop-logo" />
                        <img src={`${process.env.REACT_APP_ASSETS_URL}/assets/images/logo-small.png`} alt="logo" className="toggle-logo" />
                        <img src={`${process.env.REACT_APP_BASE_URL}/assets/images/logo-wide.png`} alt="logo" className="desktop-dark" />
                        <img src={`${process.env.REACT_APP_ASSETS_URL}/assets/images/logo-small.png`} alt="logo" className="toggle-dark" />
                        <img src={`${process.env.REACT_APP_BASE_URL}/assets/images/logo-wide.png`} alt="logo" className="desktop-white" />
                        <img src={`${process.env.REACT_APP_ASSETS_URL}/assets/images/logo-small.png`} alt="logo" className="toggle-white" />
                        </Link>
                    </div>
                </div>
                {/* <!-- End::header-element --> */}

                {/* <!-- Start::header-element --> */}
                <div className="header-element">
                    {/* <!-- Start::header-link --> */}
                    <a aria-label="Hide Sidebar" className="sidemenu-toggle header-link animated-arrow hor-toggle horizontal-navtoggle" data-bs-toggle="sidebar" href="javascript:void(0);" onClick={toggleSideMenu}><span></span>
                    </a>
                    {/* <!-- End::header-link --> */}
                </div>
                {/* <!-- End::header-element --> */}

            </div>
            {/* <!-- End::header-content-left --> */}

            {/* <!-- Start::header-content-right --> */}
            <div className="header-content-right">



            {/* <!-- Start::header-element --> */}
            <div className="header-element header-theme-mode">
                {/* <!-- Start::header-link|layout-setting --> */}
                <a href="javascript:void(0);" className="header-link layout-setting" onClick={toggleTheme}>
                    <span className="light-layout">
                        {/* <!-- Start::header-link-icon --> */}
                    <i className="bx bx-moon header-link-icon"></i>
                        {/* <!-- End::header-link-icon --> */}
                    </span>
                    <span className="dark-layout">
                        {/* <!-- Start::header-link-icon --> */}
                    <i className="bx bx-sun header-link-icon"></i>
                        {/* <!-- End::header-link-icon --> */}
                    </span>
                </a>
                {/* <!-- End::header-link|layout-setting --> */}
            </div>
            {/* <!-- End::header-element --> */}

            {/* <!-- Start::header-element --> */}
            <div className="header-element notifications-dropdown d-none">
                {/* <!-- Start::header-link|dropdown-toggle --> */}
                <a href="javascript:void(0);" className="header-link dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside" id="messageDropdown" aria-expanded="false" onClick={fetchNotifications}>
                    <i className="bx bx-bell header-link-icon"></i>
                    <span className={`badge bg-secondary rounded-pill header-icon-badge pulse pulse-secondary ${notificationCount==0 ? 'd-none' : ''}`} id="notification-icon-badge">{notificationCount}</span>
                </a>
                {/* <!-- End::header-link|dropdown-toggle --> */}
                {/* <!-- Start::main-header-dropdown --> */}
                <div className="main-header-dropdown dropdown-menu dropdown-menu-end" data-popper-placement="none">
                    <div className="p-3">
                        <div className="d-flex align-items-center justify-content-between">
                            <p className="mb-0 fs-17 fw-semibold">Notifications</p>
                            <span className={`badge bg-secondary-transparent ${notificationCount==0 ? 'd-none' : ''}`} id="notifiation-data">{notificationCount} Unread</span>
                        </div>
                    </div>
                    <div className="dropdown-divider"></div>

                    {notificationLoading===true ? (
                        <div className='d-flex justify-content-center my-3'>
                            <span className="loader-new"></span>
                        </div>
                    ) : notifications.length > 0 ? (
                        <ul className="list-unstyled mb-0" id="header-notification-scroll">

                            {notifications.map((notification) => (
                            <li className="dropdown-item" onClick={() => readNotification(notification)}>
                                <div className="d-flex align-items-start">
                                    <div className="pe-2">
                                        <span className="avatar avatar-md bg-pink-transparent avatar-rounded">
                                            {notification.from_user_id !== '' ? (

                                                profilePic(notification.from_first_name, notification.from_last_name, notification.from_user_image)
                                            ) : (
                                                
                                                    <i className="ti ti-user-check fs-18"></i>
                                            
                                            )}
                                        </span>
                                    </div>
                                    <div className="flex-grow-1 d-flex align-items-center justify-content-between">
                                        <div>
                                            <p className="mb-0 fw-semibold"><a href="#">{notification.from_first_name} {notification.from_last_name}</a></p>
                                            <span className="text-muted fw-normal fs-12 header-notification-text">{notification.notification_title}</span>
                                        </div>
                                        <div>
                                            <a href="javascript:void(0);" className="min-w-fit-content text-muted me-1 dropdown-item-close1"><i className="ti ti-x fs-16"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            )) }
                            
                            
                        </ul>
                        ) : 
                        <div className="p-5 empty-item1 d-nonea">
                            <div className="text-center">
                                <span className="avatar avatar-xl avatar-rounded bg-secondary-transparent">
                                    <i className="ri-notification-off-line fs-2"></i>
                                </span>
                                <h6 className="fw-semibold mt-3">No New Notifications</h6>
                            </div>
                        </div>
                    }
                    <div className="p-3 empty-header-item1 border-top">
                        <div className="d-grid">
                            <Link to='/notifications' className="btn btn-primary">View All</Link>
                        </div>
                    </div>
                    
                    <div className="p-3 empty-header-item1 border-top">
                        <div className="d-grid">
                            <Link style={{cursor: 'pointer'}} className={`btn btn-primary ${permission==='granted' ? 'd-none' : ''}`} 
                               onClick={allowDesktopNotification}>
                                <i className="bi bi-check-circle"></i> Allow Desktop Notification
                            </Link>
                        </div>
                    </div>
                </div>
                {/* <!-- End::main-header-dropdown --> */}
            </div>
            {/* <!-- End::header-element --> */}

            {/* <!-- Start::header-element --> */}
            <div className="header-element header-shortcuts-dropdown d-none">
                {/* <!-- Start::header-link|dropdown-toggle --> */}
                <a href="javascript:void(0);" className="header-link dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside" id="notificationDropdown" aria-expanded="false">
                    <i className="bx bx-grid-alt header-link-icon"></i>
                </a>
                {/* <!-- End::header-link|dropdown-toggle --> */}
                {/* <!-- Start::main-header-dropdown --> */}
                <div className="main-header-dropdown header-shortcuts-dropdown dropdown-menu pb-0 dropdown-menu-end" aria-labelledby="notificationDropdown">
                    <div className="p-3">
                        <div className="d-flex align-items-center justify-content-between">
                            <p className="mb-0 fs-17 fw-semibold">Related Apps</p>
                        </div>
                    </div>
                    <div className="dropdown-divider mb-0"></div>
                    <div className="main-header-shortcuts p-2" id="header-shortcut-scroll">
                    <div className="row g-2">
                        <div className="col-4">
                            <a href="javascript:void(0);">
                                    <div className="text-center p-3 related-app">
                                        <span className="avatar avatar-sm avatar-rounded">
                                            <img src="assets/images/apps/figma.png" alt="" />
                                        </span>
                                        <span className="d-block fs-12">Figma</span>
                                    </div>
                                </a>
                        </div>
                        <div className="col-4">
                                <a href="javascript:void(0);">
                                    <div className="text-center p-3 related-app">
                                        <span className="avatar avatar-sm avatar-rounded">
                                            <img src="assets/images/apps/microsoft-powerpoint.png" alt="" />
                                        </span>
                                        <span className="d-block fs-12">Power Point</span>
                                    </div>
                                </a>
                        </div>
                        <div className="col-4">
                                <a href="javascript:void(0);">
                                    <div className="text-center p-3 related-app">
                                        <span className="avatar avatar-sm avatar-rounded">
                                            <img src="assets/images/apps/microsoft-word.png" alt="" />
                                        </span>
                                        <span className="d-block fs-12">MS Word</span>
                                    </div>
                                </a>
                        </div>
                        <div className="col-4">
                                <a href="javascript:void(0);">
                                    <div className="text-center p-3 related-app">
                                        <span className="avatar avatar-sm avatar-rounded">
                                            <img src="assets/images/apps/calender.png" alt="" />
                                        </span>
                                        <span className="d-block fs-12">Calendar</span>
                                    </div>
                                </a>
                        </div>
                        <div className="col-4">
                                <a href="javascript:void(0);">
                                    <div className="text-center p-3 related-app">
                                        <span className="avatar avatar-sm avatar-rounded">
                                            <img src="assets/images/apps/sketch.png" alt="" />
                                        </span>
                                        <span className="d-block fs-12">Sketch</span>
                                    </div>
                                </a>
                        </div>
                        <div className="col-4">
                                <a href="javascript:void(0);">
                                    <div className="text-center p-3 related-app">
                                        <span className="avatar avatar-sm avatar-rounded">
                                            <img src="assets/images/apps/google-docs.png" alt="" />
                                        </span>
                                        <span className="d-block fs-12">Docs</span>
                                    </div>
                                </a>
                        </div>
                        <div className="col-4">
                                <a href="javascript:void(0);">
                                    <div className="text-center p-3 related-app">
                                        <span className="avatar avatar-sm avatar-rounded">
                                            <img src="assets/images/apps/google.png" alt="" />
                                        </span>
                                        <span className="d-block fs-12">Google</span>
                                    </div>
                                </a>
                        </div>
                        <div className="col-4">
                                <a href="javascript:void(0);">
                                    <div className="text-center p-3 related-app">
                                        <span className="avatar avatar-sm avatar-rounded">
                                            <img src="assets/images/apps/translate.png" alt="" />
                                        </span>
                                        <span className="d-block fs-12">Translate</span>
                                    </div>
                                </a>
                        </div>
                        <div className="col-4">
                                <a href="javascript:void(0);">
                                    <div className="text-center p-3 related-app">
                                        <span className="avatar avatar-sm avatar-rounded">
                                            <img src="assets/images/apps/google-sheets.png" alt="" />
                                        </span>
                                        <span className="d-block fs-12">Sheets</span>
                                    </div>
                                </a>
                        </div>
                    </div>
                    </div>
                    <div className="p-3 border-top">
                        <div className="d-grid">
                            <a href="javascript:void(0);" className="btn btn-primary">View All</a>
                        </div>
                    </div>
                </div>
                {/* <!-- End::main-header-dropdown --> */}
            </div>
            {/* <!-- End::header-element --> */}

            {/* <!-- Start::header-element --> */}
            <div className="header-element header-fullscreen">
                {/* <!-- Start::header-link --> */}
                <a onClick={fullScreenToggle} href="javascript:void(0);" className="header-link">
                    <i className={`bx bx-fullscreen full-screen-open header-link-icon ${fullScreen===true ? 'd-none' : ''}`}></i>
                    <i className={`bx bx-exit-fullscreen full-screen-close header-link-icon ${fullScreen===false ? 'd-none' : ''}`}></i>
                </a>
                {/* <!-- End::header-link --> */}
            </div>
            {/* <!-- End::header-element --> */}

            {/* <!-- Start::header-element --> */}
            <div className="header-element">
                {/* <!-- Start::header-link|dropdown-toggle --> */}
                <a href="javascript:void(0);" className="header-link dropdown-toggle" id="mainHeaderProfile" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                    <div className="d-flex align-items-center">
                        <div className="me-sm-2 me-0">
                            
                            <div className="avatar avatar-sm me-2 avatar-rounded">
                                {profilePic(user_data.user_first_name, user_data.user_last_name, user_data.user_image, '32px', '12px')}
                            </div>
                            
                        </div>
                        <div className="d-sm-block d-none">
                            <p className="fw-semibold mb-0 lh-1">{user_data.user_first_name} {user_data.user_last_name}</p>
                            <span className="op-7 fw-normal d-block fs-11">{user_data.user_job_title}</span>
                        </div>
                    </div>
                </a>
                {/* <!-- End::header-link|dropdown-toggle --> */}
                <ul className="main-header-dropdown dropdown-menu pt-0 overflow-hidden header-profile-dropdown dropdown-menu-end" aria-labelledby="mainHeaderProfile">
                    <li><Link className="dropdown-item d-flex" to={`${config.adminDir}/profile`}><i className="ti ti-user-circle fs-18 me-2 op-7"></i>Profile</Link></li>
                    
                    <li><button className="dropdown-item d-flex" type="button" onClick={doLogout}><i className="ti ti-logout fs-18 me-2 op-7"></i>Log Out</button></li>
                </ul>
            </div>  
            {/* <!-- End::header-element --> */}

            <div className="modal fade" id="demoModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        ...
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary">Save changes</button>
                    </div>
                    </div>
                </div>
            </div>

            {/* <!-- Start::header-element --> */}
            <div className="header-element">
                {/* <!-- Start::header-link|switcher-icon --> */}
                <a href="javascript:void(0);" className="header-link switcher-icon d-none" data-bs-toggle="offcanvas" data-bs-target="#switcher-canvas">
                    <i className="bx bx-cog header-link-icon"></i>
                </a>
                {/* <!-- End::header-link|switcher-icon --> */}
            </div>
            {/* <!-- End::header-element --> */}

        </div>
        {/* <!-- End::header-content-right --> */}

    </div>
    {/* <!-- End::main-header-container --> */}

      </header>
    </>
  )
}

export default Header
