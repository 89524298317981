import React, { createContext, useContext, useState, useEffect } from 'react';

// Create the context
const SideMenuContext = createContext();

// Create a provider component
export const SideMenuProvider = ({ children }) => {
  const [sideMenu, setSideMenu] = useState('stretch');


  // Function to toggle between stretch and shrink side menu
  const toggleSideMenu = () => {
    setSideMenu((prevSideMenu) => (prevSideMenu === 'stretch' ? 'shrink' : 'stretch'));
  };


  useEffect(() => {
    const htmlElement = document.documentElement;
    if (sideMenu === 'stretch') {
      htmlElement.setAttribute('data-toggled', 'close');
    } else {
      htmlElement.setAttribute('data-toggled', 'icon-overlay-close');
    }
  }, [sideMenu]);

  // Provide the sideMenu state and toggleSideMenu function to the components
  const contextValue = {
    sideMenu,
    toggleSideMenu
  };

  return (
    <SideMenuContext.Provider value={contextValue}>
      {children}
    </SideMenuContext.Provider>
  );
};

// Create a custom hook to consume the context
export const useSideMenu = () => {
  return useContext(SideMenuContext);
};
