import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { UploadPost } from "../config/ApiConfig";

const MyDropzone = ({ onChange = null, onProgress = null, onError = null, uploadedFile = null, setUploadedFile = null }) => {
    const [progressBar, setProgressBar] = useState(0);
    const [files, setFiles] = useState([]);

    const {getRootProps, getInputProps} = useDropzone({
        noDrag: false,
        accept: {
            'image/*': []
        },
        onDrop: async acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
            onChange?.(null);
            setUploadedFile?.(null);

            let data = new FormData()
            data.append("file", acceptedFiles[0]);

            try {
                let response = await UploadPost('/public-fe/upload-file', data, (progress) => {
                    setProgressBar(progress);
                    onProgress?.(progress);
                });
                if (response) {
                    if (response.status) {
                        onChange?.(response.data.file);
                        
                        onProgress?.(100);
                        setTimeout(() => setProgressBar(100), 200);

                        return;
                    }

                    setFiles([]);
                    onError?.(response.message);
                }
            } catch (e) {}
        },

    });
    
    let thumbs = files.map(file => {
        if (! file) {
            return;
        }

        let size = (Math.round(file.size / 1024 * 100) / 100) + ' KB';
        if (Math.round(Math.round(file.size / (1024 * 1024) * 100) / 100) != 0) {
            size = (Math.round(file.size / (1024 * 1024) * 100) / 100) + ' MB';
        }

        return <>
                <div className="thumb-row" key={file.name}>
                    <div className="thumb">
                        <div className='thumb-inner'>
                            <img src={file?.preview ?? file.path}
                                // Revoke data uri after image is loaded
                                onLoad={() => { URL.revokeObjectURL(file.preview) }}
                                />
                        </div>
                    </div>
                    <div className='file-name'>{file.name} - { size }</div>
                    
                </div>
                <div style={{ backgroundColor: '#D8D8D8', height: '5px' }}><div style={{ width: progressBar + '%', height: '100%', backgroundColor: '#0CC863' }}></div></div>
            </>
    });

    let uploadedThumb = null;
    if (uploadedFile && typeof uploadedFile === 'object') {
        let size = (Math.round(uploadedFile.size / 1024 * 100) / 100) + ' KB';
        if (Math.round(Math.round(uploadedFile.size / (1024 * 1024) * 100) / 100) != 0) {
            size = (Math.round(uploadedFile.size / (1024 * 1024) * 100) / 100) + ' MB';
        }

        uploadedThumb = <a href={uploadedFile.path} target="_blank">
            <div className="thumb-row" key={uploadedFile.name}><div className="thumb">
                    <div className='thumb-inner'>
                        <img src={uploadedFile.path} />
                    </div>
                </div>
                <div className='file-name' style={{width: '75%'}}>
                    <div style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{uploadedFile.name}</div>{ size }
                </div>
            </div>
        </a>;
    }

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => files.forEach(file => { if (file?.preview) URL.revokeObjectURL(file.preview); });
    }, []);

    return (
        <>
            <div {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps()} />
                <div className='upload-button'>
                    <h4>Browse Files</h4>
                    <p>Drag and drop files here</p>
                </div>
            </div>
            <aside className='dropzone-thumb-preview'>
                {thumbs} {uploadedFile && uploadedThumb}
            </aside>
        </>
    );
}

export default MyDropzone;