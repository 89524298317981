import React, { useEffect } from 'react';
import dateFormat from "dateformat";
import DataTable, { createTheme } from 'react-data-table-component';
import { useTheme } from '../../context/ThemeContext';
import { Link } from 'react-router-dom';
import FormList from '../skeletons/FormList';
import config from '../../config/config';


const FormTables = ({
    data,
    setData,
    loading,
    setLoading,
    totalRows,
    setTotalRows,
    page,
    setPage,
    limit,
    setLimit,
    fetchForms,
    openDeleteFormModal,
    openActivateFormModal,
    openInactivateFormModal
}) => {

    const { theme } = useTheme();


    const CustomLoader = () => (
        <FormList/>
    );


    const handlePageChange = async (page) => {
        fetchForms(limit, page);
    };
    
    const handleLimitChange = async (newLimit, page) => {

        if (newLimit!==10 && page!==1) {
            fetchForms(newLimit, page); 
        }
        else if (localStorage.getItem('forms')) {
            fetchForms(newLimit, page); 
        }
    };

    useEffect(()=>{
        fetchForms(limit, page);  
    }, []);


    const columns = [
        {
            name: "#",
            selector: (row, index) => <div>{((page-1)*limit)+index+1}.</div>,
            width: '50px'
        },
        {
            name: "Title",
            selector: (row) => <Link to={`/form/${row.form_unique_id}`} target="_blank">{row.form_title}</Link>
        },
        {
            name: "Type",
            selector: (row) => row.form_type == 'onboarding' ? 'OnBoarding' : 'AVP'
        },
        {
            name: "Company",
            selector: (row) => <div>{row.company_name}</div>
        },
        {
            name: "Status",
            selector: (row) =>
            {
                if (row.form_is_active==='1') {
                    return (
                        <>
                          <span className="badge bg-success-transparent">Active</span>
                        </>
                      );
                }
                else{
                    return (
                        <>
                          <span className="badge bg-danger-transparent">Inactive</span>
                        </>
                      );
                }    
            }
        },
        {
            name: "Created On",
            selector: (row) => dateFormat(row.form_create_time, "dd mmm yyyy HH:MM")
        },
        {
            name: "Last Updated",
            selector: (row) => row.form_last_update ? dateFormat(row.form_last_update, "dd mmm yyyy HH:MM") : ''
        },
        {
            name: "Action",
            cell: row => <div className="hstack gap-2 fs-15">
                            <Link to={`${config.adminDir}/forms/edit-form/${row.form_unique_id}`} className={`btn btn-icon btn-sm btn-info ${row.form_is_active==0 ? 'd-none' : ''}`} title="Edit" >
                                <i className='bx bxs-edit'></i>
                            </Link>

                            <Link to={`/form/${row.form_unique_id}`} target="_blank" className={`btn btn-icon btn-sm btn-dark ${row.form_is_active==0 ? 'd-none' : ''}`} title="Form Link" >
                                <i className='bi bi-box-arrow-up-right'></i>
                            </Link>

                            <button type="button" className={`btn btn-icon btn-sm btn-success ${row.form_is_active==1 ? 'd-none' : ''}`} title="Activate" onClick={() => openActivateFormModal(row)}>
                                <i className="bi bi-check"></i>
                            </button>

                            <button type="button" className={`btn btn-icon btn-sm btn-danger ${row.form_is_active==0 ? 'd-none' : ''}`} title="Inactivate" onClick={() => openInactivateFormModal(row)}>
                                x
                            </button>

                            <button type="button" className="btn btn-icon btn-sm btn-danger d-none" title="Delete" onClick={() => openDeleteFormModal(row)}>
                                <i className='bx bxs-trash'></i>
                            </button>
                        </div>
        }
    ];

    createTheme('dark', {
        text: {
          primary: '#a2a3a4',
          secondary: '#2aa198',
        },
        background: {
          default: '#252729',
        },
        context: {
          background: '#cb4b16',
          text: '#a2a3a4',
        },
        divider: {
          default: '#073642',
        },
        action: {
          button: 'rgba(0,0,0,.54)',
          hover: 'rgba(0,0,0,.08)',
          disabled: 'rgba(0,0,0,.12)',
        },
    });


    return (
        <DataTable 
            title={`Manage Forms`} 
            columns={columns} 
            data={data} 
            pagination 
            paginationServer 
            paginationTotalRows={totalRows} 
            onChangeRowsPerPage={handleLimitChange} 
            onChangePage={handlePageChange} 
            progressPending={loading} 
            progressComponent={<CustomLoader />}
            highlightOnHover
            theme={`${theme==='light' ? 'light' : 'dark'}`} />
    );
};    

export default FormTables;    