import React, { createContext, useState, useContext } from 'react';

const NotificationCountContext = createContext();

export const NotificationCountProvider = ({ children }) => {
  // Initialize Sub Menu state with an empty value
  const [notificationCount, setNotificationCount] = useState(localStorage.getItem('unread_notification_count') ? localStorage.getItem('unread_notification_count') : 0);

  return (
    <NotificationCountContext.Provider value={{ notificationCount, setNotificationCount }}>
      {children}
    </NotificationCountContext.Provider>
  );
};

export const useNotificationCount = () => useContext(NotificationCountContext);
