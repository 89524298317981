import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

const AddTabModal = ({ showModal, setShowModal, formData, setFormData }) => {
  const [tabName, setTabName] = useState('');
  const [position, setPosition] = useState('after'); // Default position is 'after'
  const [errors, setErrors] = useState({ tabName: '' });

  const closeModal = () => {
    setTabName('');
    setPosition('after'); // Reset position to default
    setErrors({});
    setShowModal(false);
  };

  const addTab = () => {
    // Clear previous errors
    setErrors({});

    const validationErrors = {};

    if (!tabName) {
      validationErrors.tabName = 'Tab name is required';
    } else if (formData.tabs.some(tab => tab.name === tabName)) {
      validationErrors.tabName = 'Tab name already exists';
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const newTab = { name: tabName };
    const updatedTabs = [...formData.tabs];

    if (position === 'before') {
      const index = updatedTabs.findIndex(tab => tab.name === formData.selectedTab);
      updatedTabs.splice(index, 0, newTab);
    } else {
      const index = updatedTabs.findIndex(tab => tab.name === formData.selectedTab);
      updatedTabs.splice(index + 1, 0, newTab);
    }

    setFormData(prevFormData => ({
      ...prevFormData,
      tabs: updatedTabs
    }));

    closeModal();
  };

  return (
    <Modal show={showModal} onHide={closeModal} size="sm" style={{marginTop: '100px'}}>
      <Modal.Header closeButton>
        <Modal.Title>Add Tab</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row gy-2">
          <div className="col-xl-12">
            <label className="form-label">Tab Name : <span className="text-danger">*</span></label>
            <input type="text" className="form-control" placeholder="Tab Name" value={tabName} onChange={(e) => setTabName(e.target.value)} />
            {errors.tabName && <p style={{ color: 'red' }}>{errors.tabName}</p>}
          </div>
          {formData.tabs.length > 0 && (
            <>
              <div className="col-xl-12">
                <label className="form-label">Existing Tabs:</label>
                <select className="form-select" value={formData.selectedTab || ''} onChange={(e) => setFormData(prevFormData => ({ ...prevFormData, selectedTab: e.target.value }))}>
                  <option value="" hidden> - Select -</option>
                  {formData.tabs.map(tab => (
                    <option key={tab.name} value={tab.name}>{tab.name}</option>
                  ))}
                </select>
              </div>
              <div className="col-xl-12">
                <label className="form-label">Position:</label>
                <div>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" name="position" id="after" value="after" checked={position === 'after'} onChange={() => setPosition('after')} />
                    <label className="form-check-label" htmlFor="after">After</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" name="position" id="before" value="before" checked={position === 'before'} onChange={() => setPosition('before')} />
                    <label className="form-check-label" htmlFor="before">Before</label>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-light" onClick={closeModal}>Cancel</button>
        <button type="button" className="btn btn-primary" onClick={addTab}>Submit</button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddTabModal;
