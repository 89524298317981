import { DEL, GET, POST, setAuthToken } from "../config/ApiConfig";
import { decryptData, encryptData } from "../helpers/EncryptHelper";
import 'react-toastify/dist/ReactToastify.css';
import dateFormat from "dateformat";
import { createFakeResponse } from "../helpers/ApiHelper";


export const createInteraction = async(form_unique_id, formData) => {

    const endPoint = `/public-fe/create-interaction/${form_unique_id}`;

    const response = await POST(endPoint, formData);

    return response;
}

export const editInteraction = async(interaction_unique_id, formData) => {

    const endPoint = `/public-fe/update-interaction/${interaction_unique_id}`;

    const response = await POST(endPoint, formData);

    return response;
}

export const saveInteractionsToStorage = (interactions, pagination) => {

    let time = dateFormat('', "dd mmm yyyy HH:MM:ss");

    const dataToStore = { interactions, pagination, time };

    localStorage.setItem("interactions", encryptData(dataToStore));
}


export const getInteractions = async(param = {}) => {

    let user_token = param.user_token;

    if (!user_token) {
        return [];
    }

    let page = param.page ? param.page : 1;
    let limit = param.limit ? param.limit : 10;

    let _form_id = param._form_id ? param._form_id : '';

    let interaction_is_completed = param.interaction_is_completed ? param.interaction_is_completed : '';

    let search = param.search ? param.search : '';

    if (page === 1 && limit === 10 && localStorage.getItem('interactions') && _form_id != '' && interaction_is_completed != '' && search != '') {
        const saved_interactions_obj = decryptData(localStorage.getItem('interactions'));

        return createFakeResponse({ status: true, data: saved_interactions_obj });
    } else {
        try {
            const endPoint = '/interactions/interaction-list';
            const url = `${endPoint}?page=${page}&limit=${limit}&_form_id=${_form_id}&interaction_is_completed=${interaction_is_completed}&search=${search}`;

            setAuthToken(user_token);

            const response = await GET(url);

            if (response.status) {
                if (page === 1 && limit === 10 && _form_id != '' && interaction_is_completed != '' && search != '') {
                    saveInteractionsToStorage(response.data.interactions, response.data.pagination);

                    const saved_interactions_obj = decryptData(localStorage.getItem('interactions'));

                    return createFakeResponse({ status: true, data: saved_interactions_obj });
                }
            }

            return response;

        } catch (error) {
            return error;
        }
    }
};


export const getInteractionDetails = async(interaction_unique_id) => {

    const endPoint = '/public-fe/interaction-details';
    const url = `${endPoint}/${interaction_unique_id}`;

    const response = await GET(url);

    return response;
}


export const removeInteraction = async(user_token, interaction_unique_id) => {

    const endPoint = `/interactions/delete-interaction/${interaction_unique_id}`;

    setAuthToken(user_token);

    const response = await DEL(endPoint);

    return response;
}