import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useLocation  } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AdminLayout from '../layout/AdminLayout';
import { useAuth } from '../../context/AuthContext';
import { getUserProfile, getUserRoles, saveUserDataToStorage, saveUsersToStorage, updateMyPassword, updateMyPicture, updateUserAccountInfo, updateUserProfile} from '../../models/UserModel';
import { profilePic } from '../../helpers/GeneralHelper';
import dateFormat from "dateformat";
import { apiError } from '../../helpers/ErrorHelper';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import ProfileTabs from '../skeletons/ProfileTabs';
import { decryptData, encryptData } from '../../helpers/EncryptHelper';
import { useTheme } from '../../context/ThemeContext';
import { useNotificationCount } from '../../context/NotificationCountContext';

function Profile() {

  const { notificationCount, setNotificationCount } = useNotificationCount();  

  const { state, dispatch } = useAuth();

  const { theme, toggleTheme, selectBoxTheme } = useTheme();

  const navigate = useNavigate();

  const currentRoute = useLocation().pathname;

  let user_data = state.user_data;

  const loader = document.getElementById('loading');

  const [modalKey, setModalKey] = useState(0);
  

  //Update Profile Picture Start
  const [user_image, setUserImage] = useState('');
    
  const userImageFileRef = useRef(null);

  const handleUserImageClick = () => {
    userImageFileRef.current.click();
  }

  const handleUserImageChange = (event) => {
    const file = event.target.files[0];

    //console.log(file);

    setUserImage(file);

    //console.log(URL.createObjectURL(file));
  }

  const uploadProfilePicture = async() => {
    // Start the loading state
    loader.style.display = 'block';
    
    try {
        const formData = new FormData();
        formData.append('user_image', user_image);

        const response = await updateMyPicture(user_data.user_token, formData);

        if (response.status) {
            toast.success(response.message);

            setUserImage('');

            setProfileData(response.data.user_data);

            saveUserDataToStorage(response.data.user_data);

            saveUsersToStorage(response.data.users, response.data.pagination);

            dispatch({ type: 'UPDATE_USER_DATA', payload: { user_data: response.data.user_data } });

            setNotificationCount(localStorage.getItem('unread_notification_count'));
        }
        else{
            toast.error(response.message);
        }   
        
    } catch (error) {
        apiError(error);
    }

    // Stop the loading state
    loader.style.display = 'none';
  }
  //Update Profile Picture End


  // Get User Profile Start
  const [profileLoading, setProfileLoading] = useState(false);
  const [profileData, setProfileData] = useState([]);

  let { user_unique_id } = useParams();

  if (!user_unique_id) {
    user_unique_id = user_data.user_unique_id;
  }

  const fetchUserProfile = async () => {
    // Start the loading state
    //loader.style.display = 'block';
    setProfileLoading(true);

    try {
        let apiParam = {user_token: user_data.user_token, user_unique_id};
        const response = await getUserProfile(apiParam);

        if (typeof response != typeof undefined) {
            
            setNotificationCount(localStorage.getItem('unread_notification_count'));
    
            setUserFirstName(response.user_data.user_first_name);
            setUserLastName(response.user_data.user_last_name);

            setUserGender(response.user_data.user_gender);
            setUserEmail(response.user_data.user_email);
            setUserMobile(response.user_data.user_mobile);

    
            setUserJobTitle(response.user_data.user_job_title);
    
            setProfileData(response.user_data);
    
            //console.log(response.user_data);
    
   

    
            setUserRoleId(response.user_data.user_role_id);

            setProfileLoading(false);

        }
        else{
            //navigate('/re-login');
        }    
        
    } catch (error) {
        console.error(error);
    }

    // Stop the loading state
    //loader.style.display = 'none';
    
  };  

  
  useEffect(() => {  
    fetchUserProfile();
  }, [user_unique_id]);
  // Get User Profile End


  // Get User Roles Start
  const [userRoles, setUserRoles] = useState([]);

  const fetchUserRoles = async() => {
    try {
        let apiParam = {user_token: user_data.user_token};
        const response = await getUserRoles(apiParam);

        if (response.status) {

            if (response.data.user_roles) {
                setUserRoles(response.data.user_roles);
            }
    
            setNotificationCount(localStorage.getItem('unread_notification_count'));
        }
        else{
            if (response.response.data.invalid_token=='1') {
                navigate(`/re-login?redirect=${currentRoute}`);
            }
        }      
        
    } catch (error) {
        console.error(error);
    }
  }

  useEffect(() => {  
    fetchUserRoles();
  }, []);
  // Get User Roles End


  // Update Profile Start
  const [user_first_name, setUserFirstName] = useState('');
  const [user_last_name, setUserLastName] = useState('');
  const [user_gender, setUserGender] = useState('');
  const [user_email, setUserEmail] = useState('');
  const [user_mobile, setUserMobile] = useState('');
  const [user_job_title, setUserJobTitle] = useState('');

  const [genInfoerrors, setGenInfoErrors] = useState({user_first_name:  '',  user_last_name: '', user_gender: '', user_email: '',
  user_mobile: '', user_job_title: ''});

  const updateProfile = async() => {
    // Start the loading state
    loader.style.display = 'block';

    try {
        // Clear previous errors
        setGenInfoErrors({});
    
        const formData = { user_first_name, user_last_name, user_gender, user_email, user_mobile, user_job_title };
  
        const response = await updateUserProfile(user_data.user_token, user_unique_id, formData);

        if (response.status) {
            toast.success(response.message);

            setProfileData(response.data.user_data);

            setNotificationCount(localStorage.getItem('unread_notification_count'));
        }
        else{
            setGenInfoErrors(response.error);
            toast.error(response.message);
        }     

    } catch (error) {
        apiError(error);
    }

    // Stop the loading state
    loader.style.display = 'none';
  };
  // Update General Info End




  // Update Account Info Start
  const [user_role_id, setUserRoleId] = useState('');

  const [accInfoErrors, setAccInfoErrors] = useState({user_role_id:  ''});

  const updateAccountInfo = async() => {
    // Start the loading state
    loader.style.display = 'block';

    try {
        // Clear previous errors
        setAccInfoErrors({});
    
        const formData = { user_role_id };
  
        const response = await updateUserAccountInfo(user_data.user_token, user_unique_id, formData);

        if (response.status) {
            toast.success(response.message);

            setProfileData(response.data.user_data);

            setNotificationCount(localStorage.getItem('unread_notification_count'));
        }
        else{
            setAccInfoErrors(response.error);
            toast.error(response.message);
        }     

    } catch (error) {
        apiError(error);
    }

    // Stop the loading state
    loader.style.display = 'none';
  };
  // Update Account Info End


  // Change Password Start
  const [current_password, setCurrentPassword] = useState('');
  const [new_password, setNewPassword] = useState('');
  const [confirm_password, setConfirmPassword] = useState('');

  const [changePasswordErrors, setChangePasswordErrors] = useState({current_password:  '', new_password: '', confirm_password: ''});

  const changePassword = async() => {
    // Start the loading state
    loader.style.display = 'block';

    try {
        // Clear previous errors
        setChangePasswordErrors({});
    
        const formData = { current_password, new_password, confirm_password};
  
        const response = await updateMyPassword(user_data.user_token, formData);

        if (response.status) {
            toast.success(response.message);

            setCurrentPassword('');
            setNewPassword('');
            setConfirmPassword('');

            setNotificationCount(localStorage.getItem('unread_notification_count'));
        }
        else{
            setChangePasswordErrors(response.error);
            toast.error(response.message);
        }     

    } catch (error) {
        apiError(error);
    }

    // Stop the loading state
    loader.style.display = 'none';
  };
  // Change Password End



  return (
    <>
    
        <AdminLayout>
        
            <Helmet>
                <title>Profile - Funnl Onboarding</title>
                
            </Helmet>

            <div className="main-content app-content">

                <div className="container-fluid">

                    {/* <!-- Page Header --> */}
                    <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                        <h1 className="page-title fw-semibold fs-18 mb-0">Profile</h1>
                        <div className="ms-md-1 ms-0">
                            <nav>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Profile</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    {/* <!-- Page Header Close --> */}

{profileLoading ? (


<ProfileTabs />

) : (  
    
    <div className="row">
        <div className="col-xxl-4 col-xl-12">
            <div className="card custom-card overflow-hidden">
                <div className="card-body p-0">
                    <div className="d-sm-flex align-items-top p-4 border-bottom-0 main-profile-cover">
                        <div>
                            <span className="avatar avatar-xxl avatar-rounded online me-3">
                                {profilePic(profileData.user_first_name, profileData.user_last_name, profileData.user_image, '67px', '25px', user_image!=='' ? 'none' : 'block')}

                                <div className='d-flex justify-content-end' >
                                    <button type='button' className='btn-close profile-pic-remove' title='Remove Pic' 
                                    style={{display: user_image!=='' ? 'block' : 'none'}} onClick={(e) => setUserImage('')}
                                    ></button>
                                </div>
                                <img src={user_image ? URL.createObjectURL(user_image) : ``} alt="img"
                                style={{display: user_image!=='' ? 'block' : 'none'}} />

                                <button className="btn btn-link camera-btn" onClick={handleUserImageClick}
                                    style={{display: user_data.user_unique_id===user_unique_id ? 'block' : 'none'}}>
                                    <i className="bi bi-camera-fill"></i>
                                </button>

                                <input className='d-none' type='file' ref={userImageFileRef} onChange={handleUserImageChange} 
                                accept="image/png, image/jpeg, image/jpg" />
                            </span>
                            <div className='text-center me-3 mt-2'>
                                <button className='btn btn-link upload-btn' style={{display: user_image!=='' ? 'block' : 'none'}} title="Upload"
                                onClick={uploadProfilePicture}>
                                    <i className="bi bi-cloud-arrow-up"></i>
                                </button>
                            </div>
                        </div>
                        <div className="flex-fill main-profile-info">
                            <div className="d-flex align-items-center justify-content-between">
                                <h6 className="fw-semibold mb-1 text-fixed-white">{profileData.user_first_name} {profileData.user_last_name}</h6>
                            </div>
                            <p className="mb-1 text-muted text-fixed-white op-7">{profileData.user_job_title}</p>
                            
                        </div>
                    </div>  
                    <div className="p-4 border-bottom border-block-end-dashed">
                        <p className="fs-15 mb-2 me-4 fw-semibold">Contact Information :</p>
                        <div className="text-muted">
                            <p className="mb-2">
                                <span className="avatar avatar-sm avatar-rounded me-2 bg-light text-muted">
                                    <i className="ri-mail-line align-middle fs-14"></i>
                                </span>
                                {profileData.user_email}
                            </p>
                            <p className="mb-2">
                                <span className="avatar avatar-sm avatar-rounded me-2 bg-light text-muted">
                                    <i className="ri-phone-line align-middle fs-14"></i>
                                </span>
                                {profileData.user_mobile}
                            </p>
                            
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div className="col-xxl-8 col-xl-12">
            <div className="row">
                <div className="col-xl-12">
                    <div className="card custom-card">
                        <div className="card-body p-0">


                            {/* <!--Profile Card Start---> */}
                            <div className="card custom-card">
                                <div className="card-header">
                                    <div className="card-title">
                                        Profile
                                    </div>
                                </div>
                                <div className="card-body">

                                    <div className="row gy-3">

                                        <div className="col-xl-4">
                                            <label className="form-label">First Name : <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" placeholder="First Name"  readOnly={user_data.urole_permission !== '*' ? true : false}
                                            value={user_first_name} onChange={(e) => setUserFirstName(e.target.value)} />

                                            {genInfoerrors.user_first_name && <p style={{ color: 'red' }}>{genInfoerrors.user_first_name}</p>}
                                        </div>

                                        <div className="col-xl-4">
                                            <label className="form-label">Last Name : <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" placeholder="Last Name" readOnly={user_data.urole_permission !== '*' ? true : false}
                                            value={user_last_name} onChange={(e) => setUserLastName(e.target.value)} />

                                            {genInfoerrors.user_last_name && <p style={{ color: 'red' }}>{genInfoerrors.user_last_name}</p>}
                                        </div>


                                        <div className="col-xl-4">
                                            <label className="form-label">Gender : <span className="text-danger">*</span></label>
                                            <br/>

                                            <label className="radio-inline">
                                            <input type="radio" name="user_gender" value="male" checked={user_gender==='male' ? 'checked' : ''} disabled={user_data.urole_permission !== '*' ? true : false}
                                            onChange={(e) => setUserGender(e.target.value)}/>&nbsp;Male&nbsp;&nbsp;
                                            </label>

                                            <label className="radio-inline">
                                            <input type="radio" name="user_gender" value="female" checked={user_gender==='female' ? 'checked' : ''} disabled={user_data.urole_permission !== '*' ? true : false}
                                            onChange={(e) => setUserGender(e.target.value)} />&nbsp;Female&nbsp;&nbsp;
                                            </label>

                                            <label className="radio-inline">
                                            <input type="radio" name="user_gender" value="other" checked={user_gender==='other' ? 'checked' : ''} disabled={user_data.urole_permission !== '*' ? true : false}
                                            onChange={(e) => setUserGender(e.target.value)} />&nbsp;Other
                                            </label>

                                            {genInfoerrors.user_gender && <p style={{ color: 'red' }}>{genInfoerrors.user_gender}</p>}
                                        </div>

                                    </div>

                                    <div className="row gy-3">

                                        <div className="col-xl-4">
                                            <label htmlFor="input-label" className="form-label">Email : <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" placeholder="Email address" readOnly={user_data.urole_permission !== '*' ? true : false}
                                            value={user_email} onChange={(e) => setUserEmail(e.target.value)}/>

                                            {genInfoerrors.user_email && <p style={{ color: 'red' }}>{genInfoerrors.user_email}</p>}
                                        </div>

                                        <div className="col-xl-4">
                                            <label htmlFor="input-label" className="form-label">Mobile : <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" placeholder="Mobile number" readOnly={user_data.urole_permission !== '*' ? true : false}
                                            value={user_mobile} onChange={(e) => setUserMobile(e.target.value)}/>

                                            {genInfoerrors.user_mobile && <p style={{ color: 'red' }}>{genInfoerrors.user_mobile}</p>}
                                        </div>

                                        <div className="col-xl-4">
                                            <label htmlFor="input-label" className="form-label">Job Title : <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" placeholder="Job Title" readOnly={user_data.urole_permission !== '*' ? true : false}
                                            value={user_job_title} onChange={(e) => setUserJobTitle(e.target.value)}/>

                                            {genInfoerrors.user_job_title && <p style={{ color: 'red' }}>{genInfoerrors.user_job_title}</p>}
                                        </div>

                                    </div>   

                                </div>  
                                {user_data.urole_permission==='*' ? ( 
                                <div className="card-footer">
                                    <button className="btn btn-primary-light btn-wave ms-auto float-end" onClick={updateProfile}>Update</button>
                                </div>  
                                ):('')}
                            </div>
                            {/* <!--Profile Card End---> */}


                            {/* <!--Account Info Card Start---> */}
                            {user_data.user_unique_id===user_unique_id || user_data.urole_permission==='*' ? (
                            <div className="card custom-card">
                                <div className="card-header">
                                    <div className="card-title">
                                        Account Info
                                    </div>
                                </div>
                                <div className="card-body">

                                    <div className="row gy-3">

                                        <div className="col-xl-4">
                                            <label htmlFor="input-label" className="form-label">Role : <span className="text-danger">*</span></label>
                                            <select className="form-control" onChange={(e) => setUserRoleId(e.target.value)}>
                                                <option value="" hidden>- Select Role -</option>
                                                {userRoles && userRoles.map((role) => (
                                                    <option value={role._urole_id} selected={user_role_id === role._urole_id}>
                                                        {role.urole_title}
                                                    </option>
                                                ))}
                                            </select>

                                            {accInfoErrors.user_role_id && <p style={{ color: 'red' }}>{accInfoErrors.user_role_id}</p>}
                                        </div>

                                    </div>    

                                </div>  
                                {user_data.urole_permission==='*' ? (                   
                                <div className="card-footer">
                                    <button className="btn btn-primary-light btn-wave ms-auto float-end" onClick={updateAccountInfo}>Update</button>
                                </div>   
                                ):('')}
                            </div> 
                            ):('')}
                            {/* <!--Account Info Card End---> */}


                            {/* <!--Change Password Card Start---> */}
                            {user_data.user_unique_id===user_unique_id ? (
                            <div className="card custom-card">
                                <div className="card-header">
                                    <div className="card-title">
                                        Change Password
                                    </div>
                                </div>
                                <div className="card-body">

                                    <div className="row gy-3">

                                        <div className="col-xl-4">
                                            <label className="form-label">Current Passsword : <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" placeholder="Current Passsword" value={current_password} onChange={(e) => setCurrentPassword(e.target.value)} />

                                            {changePasswordErrors.current_password && <p style={{ color: 'red' }}>{changePasswordErrors.current_password}</p>}
                                        </div>

                                        <div className="col-xl-4">
                                            <label className="form-label">New Passsword : <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" placeholder="New Passsword" value={new_password} onChange={(e) => setNewPassword(e.target.value)} />

                                            {changePasswordErrors.new_password && <p style={{ color: 'red' }}>{changePasswordErrors.new_password}</p>}
                                        </div>

                                        <div className="col-xl-4">
                                            <label className="form-label">Confirm Passsword : <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" placeholder="Confirm Passsword" value={confirm_password} onChange={(e) => setConfirmPassword(e.target.value)} />

                                            {changePasswordErrors.confirm_password && <p style={{ color: 'red' }}>{changePasswordErrors.confirm_password}</p>}
                                        </div>

                                    </div>    

                                </div>  

                                <div className="card-footer">
                                    <button className="btn btn-primary-light btn-wave ms-auto float-end" onClick={changePassword}>Update</button>
                                </div>   
                            </div> 
                            ):('')}
                            {/* <!--Change Password Card End--->    */}
                                        
                           
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    
    )} 

</div>


</div>  

            <Helmet>
                
            </Helmet>
        
        </AdminLayout>
    </>
  )
}

export default Profile
