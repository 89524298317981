import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { formatDataBeforeView } from '../../../helpers/GeneralHelper';

export const CompanyView = ({module, showModal, setShowModal, data}) => {

  const inputFields = {
    company_name: 'Company Name',
    company_domain: 'Domain / LOB',
    company_msa_status: 'MSA Status',
    company_size: 'Size',
    company_revenue: 'Revenue',
    company_presence: 'Presence',
    company_is_msp: 'Is MSP?',
    company_msp_name: 'MSP Name',
    company_is_vms_tool_used: 'VMS Tool Used',
    company_link: 'VMS Link',
    company_login_username: 'VMS Username',
    company_login_password: 'VMS Password',
    company_website: 'Website',
    state_name: 'Billing State',
    company_address: 'Address',
    company_remarks: 'Remarks',
    company_update_time: 'Updated At',
    company_create_time: 'Created At',
  }
  const formatOptions = [
    {
      'type': 'yesNo',
      'column': 'company_is_msp',
    },
    {
      'type': 'yesNo',
      'column': 'company_is_vms_tool_used',
    },
    {
      'type': 'datetime',
      'column': 'company_update_time',
    },
    {
      'type': 'datetime',
      'column': 'company_create_time',
    },
  ];

  const [fields, setFields] = useState(inputFields);
  useEffect(() => {
    if (data) {
      setFields(formatDataBeforeView(data, formatOptions));
    }
  }, [data]);

  return (
    <Modal show={showModal} onHide={() => setShowModal(false)} size='lg'>
      <Modal.Header closeButton>
          <Modal.Title>View {module.singularTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className="table table-bordered table-hover table-sm">
          <tbody>
            {Object.keys(inputFields).map((item) => 
              <tr key={item}>
                <td>{inputFields[item]}</td>
                <th>{fields[item]}</th>
              </tr>
            )}
          </tbody>
        </table>
      </Modal.Body>
    </Modal>
  );
};
