import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import LoginLayout from '../layout/LoginLayout';
import { apiError } from '../../helpers/ErrorHelper';
import { sendEmail } from '../../models/SendEmail';

function SendEmail() {

    const loader = document.getElementById('loading');

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [url, setUrl] = useState('');
    const [errors, setErrors] = useState({name: '', email:  '',  url: '',});
    const [loading, setLoading] = useState(false); 

    const doSendEmail = async() => {
        // Start the loading state
        loader.style.display = 'block';
        setLoading(true);

        try {
          setErrors({});
    
          const formData = { name, email, url };
          const response = await sendEmail(formData);
          if (response.status) {
            toast.success(response.message);
            setName('');
            setEmail('');
            setUrl('');
            
          } else {
            setErrors(response.error);
            toast.error(response.message);
          }
        } catch (error) {
            apiError(error);
        }

        // Stop the loading state
        loader.style.display = 'none';
        setLoading(false);
    }

  return (
        <LoginLayout>
        
            <Helmet>
                <title>Candidate - Funnl Onboarding</title>
            </Helmet>

            <div className="container">
                <div className="row justify-content-center align-items-center authentication authentication-basic h-100">
                    <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-6 col-sm-8 col-12">
                        <div className="my-5 d-flex justify-content-center">
                            {/* <Link to={process.env.REACT_APP_WEBSITE_URL} target='_blank'>
                                <img src={`${process.env.REACT_APP_BASE_URL}/assets/images/logo-wide.png`} alt="logo" className="desktop-logo" />
                                <img src={`${process.env.REACT_APP_BASE_URL}/assets/images/logo-wide.png`} alt="logo" className="desktop-dark" />
                            </Link> */}
                        </div>
                        <div className="card custom-card">
                            <div className="card-body p-5">
                                <p className="h5 fw-semibold mb-2 text-center">Candidate</p>
                                <div className="row gy-3">
                                    <div className="col-xl-12">
                                        <label htmlFor="name" className="form-label text-default">Name <span className="text-danger">*</span></label>
                                        <input type="name" className="form-control form-control-lg" id="name" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />

                                        <p id='name_error'>
                                            {errors.name && <p style={{ color: 'red' }}>{errors.name}</p>}
                                        </p>
                                    </div>
                                    <div className="col-xl-12">
                                        <label htmlFor="email" className="form-label text-default">Email <span className="text-danger">*</span></label>
                                        <input type="email" className="form-control form-control-lg" id="email" placeholder="Email Address" value={email} onChange={(e) => setEmail(e.target.value)} />

                                        <p id='email_error'>
                                            {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
                                        </p>
                                    </div>
                                    <div className="col-xl-12 mb-2">
                                        <label htmlFor="url" className="form-label text-default d-block">URL <span className="text-danger">*</span></label>
                                        <input type="url" className="form-control form-control-lg" id="url" placeholder="Form URL" value={url} onChange={(e) => setUrl(e.target.value)} />

                                        <p id='url_error'>
                                            {errors.url && <p style={{ color: 'red' }}>{errors.url}</p>}
                                        </p>
                                    </div>
                                    <div className="col-xl-12 d-grid mt-2">
                                        <button type='button' className="btn btn-lg btn-primary" onClick={doSendEmail}>
                                            {loading ? (
                                                <>
                                                    <span className="spinner-border spinner-border-sm align-middle"></span>
                                                    Loading...
                                                </>
                                            ) : (     
                                                'Send'
                                            )}
                                        </button>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Helmet>

            </Helmet>
        
        </LoginLayout>
  )
}

export default SendEmail
