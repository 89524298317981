import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';

const EditSectionModal = ({ showModal, setShowModal, selectedTab, formData, setFormData, modalKey, sectionData, sectionIndex }) => {
  const [sectionHeading, setSectionHeading] = useState('');
  const [position, setPosition] = useState('after'); // Default position is 'after'
  const [errors, setErrors] = useState({ sectionHeading: '' });

  const closeModal = () => {
    setSectionHeading('');
    setPosition('after'); // Reset position to default
    setErrors({});
    setShowModal(false);
  };

  const updateSection = () => {
    // Clear previous errors
    setErrors({});

    const validationErrors = {};

    // if (!sectionHeading) {
    //   validationErrors.sectionHeading = 'Section heading is required';
    // } 

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    // Ensure selectedTab is within valid range
  if (selectedTab >= 0 && selectedTab < formData.tabs.length) {
    // Create a new section object
    const updatedSection = {
      heading: sectionHeading,
      fields: sectionData.fields // Initialize fields as an empty array
    };

    // Ensure sections array exists
    if (!formData.tabs[selectedTab].sections) {
      formData.tabs[selectedTab].sections = []; // Initialize sections array
    }

    // Add the new section to the selected tab's sections array
    //formData.tabs[selectedTab].sections.push(newSection);

    formData.tabs[selectedTab].sections[sectionIndex] = updatedSection;

    // Update the formData state with the updated tabs
    setFormData(prevFormData => ({
      ...prevFormData,
      tabs: [...prevFormData.tabs] // Make a shallow copy of tabs array
    }));
  }

    console.log(formData);

    closeModal();
  };

  useEffect(() => {
    if (sectionData) {
        setSectionHeading(sectionData.heading);
    }
}, [sectionData, modalKey]);

  return (
    <Modal show={showModal} onHide={closeModal} size="sm" style={{marginTop: '100px'}}>
      <Modal.Header closeButton>
        <Modal.Title>Update Section</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row gy-2">
          <div className="col-xl-12">
            <label className="form-label">Heading : </label>
            <input type="text" className="form-control" placeholder="Section Heading" value={sectionHeading} onChange={(e) => setSectionHeading(e.target.value)} />
            {errors.sectionHeading && <p style={{ color: 'red' }}>{errors.sectionHeading}</p>}
          </div>
          
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-light" onClick={closeModal}>Cancel</button>
        <button type="button" className="btn btn-primary" onClick={updateSection}>Update</button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditSectionModal;
