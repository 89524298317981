import React, { useEffect, useState} from 'react';
import { useParams } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import { getInteractionDetails } from '../../../models/InteractionModel';

function InteractionCompleted() {

    const { interaction_unique_id } = useParams();

    const [form_title, setFormTitle] = useState('');
    const [interaction_form_name, setInteractionFormName] = useState('');

    const [loading, setLoading] = useState(true);

    const fetchInteractionDetails = async() => {
        setLoading(true);

        try {
            const response = await getInteractionDetails(interaction_unique_id);

            if (response.status) {
                if (response.data.interaction_data.form_title) {
                    setFormTitle(response.data.interaction_data.form_title);
                }

                if (response.data.interaction_data.interaction_form_name) {
                    setInteractionFormName(response.data.interaction_data.interaction_form_name);
                }

                setLoading(false);
            }
            else{
                console.error(response.message);
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(()=>{
        fetchInteractionDetails();  
    }, [interaction_unique_id]);

 
  return (
    <>
        <Helmet>

            <title>Interaction Completed - Funnl Onboarding</title>

            <link rel="stylesheet" href={`${process.env.REACT_APP_BASE_URL}/assets/css/public/bootstrap.min.css`} />
            <link rel="stylesheet" href={`${process.env.REACT_APP_BASE_URL}/assets/css/public/jquery-ui.min.css`} />
            <link rel="stylesheet" href={`${process.env.REACT_APP_BASE_URL}/assets/css/public/jquery-ui.theme.min.css`} />
            <link rel="stylesheet" href={`${process.env.REACT_APP_BASE_URL}/assets/css/public/styles.css`} />
        
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-datetimepicker/4.17.47/css/bootstrap-datetimepicker.min.css" />
            <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" />

        
            {/* <!-- Google Fonts --> */}
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
            <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500&family=Poppins:wght@300;600&display=swap" rel="stylesheet" />
        </Helmet>
        
        {loading===true ? (
            <div className='d-flex justify-content-center my-3'>
                <span className="loader-new"></span>
            </div>
        ) : (  
            <section className='final-submit d-flex align-items-center' style={{background:"#FCF7EE", height:"100vh"}}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div className="wrapper-box p-5 text-center">
                                <div className="image mb-5 text-center">
                                    <img src={`${process.env.REACT_APP_BASE_URL}/assets/images/submit.png`} alt="" className='pulse' />
                                </div>
                                <h3 className='mb-3'>Thank You!</h3>
                                <p>Your form was submitted successfully.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
         )}
        <Helmet>
        
        </Helmet>

    </>
  )
}

export default InteractionCompleted
