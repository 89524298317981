import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const DeleteTabModal = ({showModal, setShowModal, formData, setFormData, tabIndex}) => {


    const closeModal = () =>{
        setShowModal(false);
    }

    const deleteTab = () => {

        // Remove the field from the fields array of the selected section
        const updatedFormData = { ...formData };
        //updatedFormData.tabs[tabIndex].sections[sectionIndex].fields.splice(fieldIndex, 1);

        //updatedFormData.tabs[tabIndex].sections.splice(sectionIndex, 1);

        updatedFormData.tabs.splice(tabIndex, 1);
      
        // Update the state with the modified form data
        setFormData(updatedFormData);
      
        // console.log(updatedFormData);
      
        closeModal();
    };


    return (
        <Modal show={showModal} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Tab</Modal.Title>
            </Modal.Header>
    
            <Modal.Body>
                <p>Do you want to delete this tab ? This process can't be undone</p>
            </Modal.Body>
    
            <Modal.Footer>
                <button type="button" className="btn btn-light" onClick={closeModal}>Cancel</button>
                <button type="button" className="btn btn-danger" onClick={deleteTab}>Delete</button>
            </Modal.Footer>
        </Modal>
    ); 

}; 


export default DeleteTabModal;    