import React, { useEffect, useState} from 'react';
import { useNavigate, useLocation, useParams, useSearchParams } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import { getFormDetails } from '../../../models/FormModel';
import { createInteraction } from '../../../models/InteractionModel';
import CurrencyInput from 'react-currency-input-field';
import { BsChevronLeft } from "react-icons/bs";


function CreateInteraction() {

    const location =  useLocation();  

    const loader = document.getElementById('loading');

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const { form_unique_id } = useParams();

    const [formData, setFormData] = useState({});
    // const [forms, setForms] = useState({});
    const [loading, setLoading] = useState(false);

    // const [currentTab, setCurrentTab] = useState(0);
    const [errors, setErrors] = useState([]);

    const fetchFormDetails = async() => {
        // setLoading(true);

        try {
            const response = await getFormDetails(form_unique_id);

            if (response.status) {

                if (response.data.form_data) {
                    setFormData(response.data.form_data);

                    // setForms(JSON.parse(response.data.form_data.form_field_json));

                    // const params = [];
                    for(let entry of searchParams.entries()) {
                        if (entry[0] == 'full_name') {
                            setInteractionFormName(entry[1]);
                        } else if (entry[0] == 'email_address') {
                            setInteractionFormEmail(entry[1]);
                        } else if (entry[0] == 'mobile_number') {
                            setInteractionFormMobile(entry[1]);
                        }else if (entry[0] == 'client_email') {
                            setInteractionClientEmail(entry[1]);
                        }

                        // params.push(entry);
                    }
                
                    // console.log(params);

                    setLoading(false);
                }
            }
            else{
                console.error(response.message);
            }    
            
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(()=>{
        fetchFormDetails();  
    }, [form_unique_id]);

    const [interaction_form_name, setInteractionFormName] = useState('');
    const [interaction_form_email, setInteractionFormEmail] = useState('');
    const [interaction_form_mobile, setInteractionFormMobile] = useState('');
    const [interaction_client_email, setInteractionClientEmail] = useState('');


    const addInteraction = async()=>{
        if (! interaction_form_mobile) {
            setErrors({interaction_form_mobile: 'Phone number is required!'});
            return;
        }

        if (interaction_form_mobile.length != 10) {
            setErrors({interaction_form_mobile: 'Phone number must be exactly 10 digits!'});
            return;
        }

        loader.style.display = 'block';

        try {
            setErrors({});

            const form_data = {interaction_form_name, interaction_form_email, interaction_form_mobile, interaction_client_email};

            const response = await createInteraction(form_unique_id, form_data);

            if (response.status) {
                toast.success(response.message);
                if (response.data.interaction_unique_id) {
                    navigate(`/interaction/${response.data.interaction_unique_id}?${(new URLSearchParams(location.search)).toString()}`);
                }
            } else {
                setErrors(response.error);
                toast.error(response.message);
            }
        } catch (error) {
            toast.error(error);
        }

        loader.style.display = 'none';
    }    

  return (
    <>
        <Helmet>
            <title>Create Interaction - Funnl Onboarding</title>

            <link rel="stylesheet" href={`${process.env.REACT_APP_BASE_URL}/assets/css/public/bootstrap.min.css`} />
            <link rel="stylesheet" href={`${process.env.REACT_APP_BASE_URL}/assets/css/public/jquery-ui.min.css`} />
            <link rel="stylesheet" href={`${process.env.REACT_APP_BASE_URL}/assets/css/public/jquery-ui.theme.min.css`} />
            <link rel="stylesheet" href={`${process.env.REACT_APP_BASE_URL}/assets/css/public/styles.css`} />
        
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-datetimepicker/4.17.47/css/bootstrap-datetimepicker.min.css" />
            <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" />

        
            {/* <!-- Google Fonts --> */}
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
            <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500&family=Poppins:wght@300;600&display=swap" rel="stylesheet" />
        </Helmet>
        
        {loading===true ? (
            <div className='d-flex justify-content-center my-3'>
                <span className="loader-new"></span>
            </div>
        ) : (  
            <section className='position-relative' id="recruit_form_modal" style={{maxHeight:"100vh", height:"100vh"}}>
                <div className="container d-flex align-items-center h-100">
                    <div className="wrapper-outside position-relative px-lg-5 px-3 py-4 shadow-md" style={{background:"#FCF7EE"}}>
                        {/* <div className="row mt-2">
                            <div className="col-12">
                                <p><a href="#" className='fw-bold text-dark font-poppins fs-6 text-decoration-none'><BsChevronLeft /> Back</a></p>
                            </div>
                        </div> */}
                        <div className="row align-items-center">
                            <div className="col-md-5 d-lg-block d-none">
                                <div className="card border-0 card-with-image">
                                    <img src={`${process.env.REACT_APP_BASE_URL}/assets/images/trucks.png`} className="card-img-top" alt="..." style={{height:"100%", maxHeight:"300px", objectFit:"cover"}} />
                                    <div className="card-body px-4">
                                        <img src='https://ancile.services/files/logo/logo.svg' alt='..' style={{width:"150px", height:"auto"}} />
                                        <div className="title-text">
                                            {/* <h4 className="card-title pt-5 mb-0 fw-bold">Let's Help You Scale And Power Your Business</h4>
                                            <p className="card-text text-white">Say something to start a live chat!</p> */}
                                            <h4 className="card-title pt-5 mb-0 fw-bold">Let's Help you Drive for FedEx Ground</h4>
                                            <p className="card-text text-white">Drive your Own Vehicle and Earn More! </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="d-flex flex-column ps-lg-5 ps-0">
                                    <div className="">
                                        <h4 className="modal-title text-dark text-center" id="staticBackdropLabel">{formData.form_title}</h4>
                                    </div>
                                    
                                    <div className="modal-body">
                                        <form className="row g-3">
                                            
                                            <div className="col-12 mb-3">
                                                <p className='title-heading'>{formData.form_type === 'avp' ? 'Driver' : 'Client'} Details*</p>
                                                <div className="row g-3">
                                                    <div className="col-md-12">
                                                        <div className="form-floating">
                                                            <input type="text" className="form-control" placeholder="Full Name" value={interaction_form_name} onChange={(e) => setInteractionFormName(e.target.value)} />
                                                            <label>Full Name</label> 

                                                            {errors.interaction_form_name && <p style={{ color: 'red' }}>{errors.interaction_form_name}</p>}
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="col-md-12">
                                                        <div className="form-floating">
                                                            <input type="email" className="form-control" placeholder="Email Address" value={interaction_form_email} onChange={(e) => setInteractionFormEmail(e.target.value)} />
                                                            <label>Email Address</label>

                                                            {errors.interaction_form_email && <p style={{ color: 'red' }}>{errors.interaction_form_email}</p>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-floating">
                                                            {/* <input type="tel" className="form-control"  placeholder="Mobile Number" value={interaction_form_mobile} onChange={(e) => setInteractionFormMobile(e.target.value)} /> */}

                                                            <CurrencyInput
                                                                className="form-control"
                                                                placeholder="Phone Number"
                                                                value={interaction_form_mobile}
                                                                decimalsLimit={0}
                                                                allowNegativeValue="false"
                                                                disableGroupSeparators="false"
                                                                minLength="10"
                                                                maxLength="10"
                                                                onValueChange={(value, name, values) => setInteractionFormMobile(value)}
                                                            />
                                                            <label>Mobile Number</label>

                                                            {errors.interaction_form_mobile && <p className='font-poppins' style={{ color: 'red' }}>{errors.interaction_form_mobile}</p>}
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    
                                    <div className="align-items-center justify-content-end flex-nowrap flex-lg-row flex-column gap-lg-3">
                                        
                                        <div className="d-flex justify-content-end mt-2">            
                                            <button type="button" className="btn theme-btn theme-btn-blue" onClick={addInteraction}>
                                                <span><i className="bi bi-check-circle"></i> Proceed</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
         )}
        <Helmet>
        
        </Helmet>
    </>
  )
}
export default CreateInteraction
