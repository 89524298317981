import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import LoginLayout from '../layout/LoginLayout';
import { useAuth } from '../../context/AuthContext';
import config from '../../config/config';



function Error404() {

  const { state, dispatch } = useAuth();  
    
  return (
    <>
    
        <LoginLayout>
        
            <Helmet>
                <title>Error 404 - Funnl Onboarding</title>
            </Helmet>

            <div className="page error-bg" id="particles-js">
                <div className="error-page">
                    <div className="container">
                        <div className="text-center p-5 my-auto">
                            <div className="row align-items-center justify-content-center h-100">
                                <div className="col-xl-7">
                                    <p className="error-text mb-sm-0 mb-2">404</p>
                                    <p className="fs-18 fw-semibold mb-3">Oops &#128557;,The page you are looking for is not available.</p>
                                    <div className="row justify-content-center mb-5">
                                        <div className="col-xl-6">
                                            <p className="mb-0 op-7">We are sorry for the inconvenience,The page you are trying to access has been removed or never been existed.</p>
                                        </div>
                                    </div>
                                    {state.isAuthenticated ? (
                                        <Link to={`${config.adminDir}/dashboard`} className="btn btn-primary">
                                            <i className="ri-arrow-left-line align-middle me-1 d-inline-block"></i>Back to Dashboard
                                        </Link>
                                    ) : (
                                        <Link to="/" className="btn btn-primary">
                                            <i className="ri-arrow-left-line align-middle me-1 d-inline-block"></i>Go to Home
                                        </Link>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Helmet>
                
            </Helmet>
        
        </LoginLayout>
    </>
  )
}

export default Error404
