import config from "../config/config";
import { utcToLocal } from "./DateHelper";
import { decryptData, encryptData } from "./EncryptHelper";
import dateFormat from "dateformat";

export const numberToOrdinal = (number) => {
    const suffixes = ['st', 'nd', 'rd'];
    const remainder10 = number % 10;
    const remainder100 = number % 100;

    if (remainder10 === 1 && remainder100 !== 11) {
        return `${number}st`;
    } else if (remainder10 === 2 && remainder100 !== 12) {
        return `${number}nd`;
    } else if (remainder10 === 3 && remainder100 !== 13) {
        return `${number}rd`;
    } else {
        return `${number}th`;
    }
};


export const getInitials = (name) => {
    const initials = name
      .split(' ')
      .map((word) => word.charAt(0))
      .join('')
      .toUpperCase();

    return initials;
};


export const profilePic = (firstName, lastName, pic, radius='30px', fontSize='10px', display='block') => {

    if (display!=='block') {
        return '';
    }
    else if (pic) {
        return <img src={`${process.env.REACT_APP_ASSETS_URL}/${pic}`} alt="img" style={{height: radius, width: radius}} />
    }
    else{
        let name = firstName+' '+lastName;

        return <div className="user-avatar" style={{height: radius, width: radius, fontSize: fontSize}}>
                    {getInitials(name)}
               </div>;
    }
}  

export const checkPermission = (action) => {

    const user_data = localStorage.getItem("user_data") ? decryptData(localStorage.getItem("user_data")) : {};

    if (user_data && user_data.urole_permission) {
        if (user_data.urole_permission==='*') {
            return true;
        }
        else{
            if (user_data.urole_permission.includes(action)) {
                return true;
            }    
        }
    }

    return false;
}



export const generateRandomString = (length) => {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
};


export const validateEmail = (input) => {
    // Regular expression for validating an email address
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(input);
};


export const validatePhoneNumber = (input) => {
    // Regular expression for validating a mobile number
    const regex = /^[0-9]{10}$/;
    return regex.test(input);
};


export const validateOnlyNumber = (input) => {
    // Regular expression for validating a mobile number
    const regex = /^[0-9]$/;
    return regex.test(input);
};

export const formatDataBeforeSend = (data, inputFields, formatOptions) => {
    const filterData = {};
    Object.keys(inputFields).forEach(function(item) {
      filterData[item] = data[item] ?? null;
    });
  
    if (formatOptions) {
      formatOptions.forEach(function(item) {
        if (item.type == 'react-select') {
          filterData[item.column] = filterData[item.column]?.value ?? '';
        } else if (item.type == 'react-select-multiple') {
          let temp = [];
          filterData[item.column]?.forEach(function(val) {
            temp.push(val.value);
          });
          filterData[item.column] = temp.join(',');
        } else if (item.type == 'react-datepicker') {
          filterData[item.column] = filterData[item.column] ? dateFormat(filterData[item.column], "dd-mm-yyyy") : '';
        } else if (item.type == 'react-timepicker') {
          if (! filterData[item.column] || filterData[item.column] == '00:00:00') {
            filterData[item.column] = null;
          } else {
            // This is for rc-time-picker
            // filterData[item.column] = moment(filterData[item.column]).format('HH:mm:ss');
  
            // This works for react-time-picker
            const now = new Date(Date.parse('2024-01-01 ' + filterData[item.column]));
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            const seconds = String(now.getSeconds()).padStart(2, '0');
            
            filterData[item.column] = `${hours}:${minutes}:${seconds}`;
          }
        } else if (item.type == 'file' && filterData[item.column] == null) {
          filterData[item.column] = '';
        }
      });
    }
  
    let formData = new FormData();
    for (let key in filterData) {
      formData.append(key, filterData[key]);
    }
  
    return formData;
  }
  
  export const formatDataBeforeDisplay = (data, formatOptions) => {
    const formData = JSON.parse(JSON.stringify(data));
  
    Object.keys(formData).forEach(function(item) {
      formData[item] = data[item] ?? '';
    });
  
    if (formatOptions) {
      formatOptions.forEach(function(item) {
        if (item.type == 'react-select') {
          formData[item.column] = { value: formData[item.column], label: formData[item.labelColumn] };
        } else if (item.type == 'react-datepicker') {
          if (! formData[item.column] || formData[item.column] == '0000-00-00') {
            formData[item.column] = null;
          } else {
            formData[item.column] = new Date(Date.parse(formData[item.column]));
          }
        } else if (item.type == 'react-timepicker') {
          if (! formData[item.column] || formData[item.column] == '00:00:00') {
            formData[item.column] = null;
          } else {
            // This is for rc-time-picker
            // formData[item.column] = moment('2024-04-24 ' + formData[item.column]);
  
            // This is for react-time-picker
            formData[item.column] = new Date('2024-04-24 ' + formData[item.column]);
          }
        }
      });
    }
  
    return formData;
  }
  
  export const formatDataBeforeView = (data, formatOptions) => {
    const formData = JSON.parse(JSON.stringify(data));
  
    Object.keys(formData).forEach(function(item) {
      formData[item] = data[item] ?? '';
    });
  
    if (formatOptions) {
      formatOptions.forEach(function(item) {
        if (item.type == 'date') {
          if (! formData[item.column] || formData[item.column] == '0000-00-00') {
            formData[item.column] = '';
          } else {
            formData[item.column] = dateFormat(Date.parse(formData[item.column]), 'dd/mm/yyyy');
          }
        } else if (item.type == 'time') {
          if (! formData[item.column] || formData[item.column] == '00:00:00') {
            formData[item.column] = '';
          } else {
            formData[item.column] = formatAMPM(new Date(Date.parse('2024-04-30 ' + formData[item.column])));
          }
        } else if (item.type == 'datetime') {
          if (! formData[item.column] || formData[item.column] == '0000-00-00 00:00:00') {
            formData[item.column] = '';
          } else {
            formData[item.column] = utcToLocal(formData[item.column]);
          }
        } else if (item.type == 'yesNo') {
          formData[item.column] = formData[item.column] == 1 ? 'Yes' : 'No';
        } else if (item.type == 'capitalize') {
          formData[item.column] = capitalize(formData[item.column]);
        }
      });
    }
  
    return formData;
  }
  
  export const cacheToStorage = (key, data) => {
    if (! config.isCache) {
      return;
    }
  
    let currentTime = dateFormat('', "dd mmm yyyy HH:MM:ss");
  
    const dataToStore = {
        [key]: data,
        time:currentTime
    };
  
    localStorage.setItem(key, encryptData(dataToStore));
  }

  export const validorSettings = () => {
    return {
      messages: {
        max: 'The :attribute must be at most :max digits',
        min: 'The :attribute must be at least :min digits',
  
        // email: 'Please enter an valid email',
        // url: 'Please enter an valid URL',
        // default: 'Validation has failed!'
      },
      autoForceUpdate: this
    }
  }
  
export const formatAMPM = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours || 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
  
    return hours + ':' + minutes + ' ' + ampm;
  }
  
  export const capitalize = (value) => {
    if (! value)
      return '';
  
    return value.charAt(0).toUpperCase() + value.slice(1);
  }