import React, { useEffect, useState} from 'react';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import Input from '../../../components/Input';
import { editInteraction, getInteractionDetails } from '../../../models/InteractionModel';
import { validateEmail, validatePhoneNumber } from '../../../helpers/GeneralHelper';
import { BsArrowLeft, BsChevronLeft } from 'react-icons/bs';

function FormLink() {

    const navigate = useNavigate(); 
    const [searchParams, setSearchParams] = useSearchParams();

    const { interaction_unique_id } = useParams();

    const [form_title, setFormTitle] = useState('');
    const [formData, setFormData] = useState({
        tabs: [],
        widgets: []
    });
    const [interaction, setInteraction] = useState(null);

    const [interaction_is_completed, setInteractionStatus] = useState(0);

    const loader = document.getElementById('loading');
    
    const [loading, setLoading] = useState(false);
    const [isFormProgress, setIsFormProgress] = useState(false);

    const [currentTab, setCurrentTab] = useState(0);
    const [completedTabs, setCompletedTabs] = useState([]);  // Track completed tabs
    const [errors, setErrors] = useState([]);

    const [tabCount, setTabCount] = useState(0);

    const fetchInteractionDetails = async() => {
        // setLoading(true);

        try {
            const response = await getInteractionDetails(interaction_unique_id);

            if (response.status) {
                if (response.data.interaction_data.form_title) {
                    setFormTitle(response.data.interaction_data.form_title);
                }

                setInteractionStatus(response.data.interaction_data.interaction_is_completed);    

                if (response.data.interaction_data.interaction_is_completed == 1) {
                    navigate(`/interaction-completed/${interaction_unique_id}`);
                } else {
                    setInteraction(response.data.interaction_data);
                    if (response.data.interaction_data.interaction_json) {
                        
                        let formObj = JSON.parse(response.data.interaction_data.interaction_json);

                        for(let tab of formObj.tabs) {
                            if (tab?.sections) {
                                for (let section of tab.sections) {
                                    if (section?.fields) {
                                        for (let field of section.fields) {
                                            if (field && ['text', 'email', 'number', 'password', 'textarea', 'mobile'].includes(field.type)) {
                                                let slug = slugify(field.label);
                                                for(let entry of searchParams.entries()) {
                                                    if (slug == entry[0].toLowerCase()) {
                                                        field.value = entry[1];
                                                    }
                                                }
                                            } else if (field && ['hidden'].includes(field.type)) {
                                                let slug = slugify(field.label);
                                                for(let entry of searchParams.entries()) {
                                                    if (slug == entry[0].toLowerCase()) {
                                                        field.placeholder = entry[1];
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        setTabCount(formObj.tabs.length);

                        setFormData(formObj);
                    }
                }

                setLoading(false);
            }
            else{
                console.error(response.message);

                navigate(`/error404`);
            }    
            
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(()=>{
        fetchInteractionDetails();  
    }, [interaction_unique_id]);

    // const showDependancy = (field) =>{
    //     console.log(field);

    //     //alert('hello');
    // }

    const preForm = () => {
        setCurrentTab(parseInt(currentTab)-1);
        setCompletedTabs((prevCompletedTabs) => {
            // Ensure the current tab is only added once
            if (!prevCompletedTabs.includes(parseInt(currentTab)-1)) {
                console.log('tab => ',parseInt(currentTab));
                return [...prevCompletedTabs, parseInt(currentTab)-1];
            }
            return prevCompletedTabs;
        });
    }

    const validateForm = async() => {
        let formErrors = []; // Store errors for each field
        // let isValid = true;
        // Iterate over form fields to check required fields

        let requestData = new FormData();
        if (formData.tabs[currentTab].sections) {
            formData.tabs[currentTab].sections.forEach((section, sectionIndex) => {
                section?.fields.forEach((field, fieldIndex) => {
                    // Check if field is required and its value is empty
                    if (field.required && !field.value) {
                        // isValid = false;
                        // Add error for this field to the formErrors object
                        formErrors[currentTab+'-'+sectionIndex+'-'+fieldIndex] = `${field.placeholder} is required.`;
                    }
                    else if (field.type=='email' && !validateEmail(field.value)) {
                        // isValid = false;
                        // Add error for this field to the formErrors object
                        formErrors[currentTab+'-'+sectionIndex+'-'+fieldIndex] = `Invalid email`;
                    }
                    else if (field.type=='mobile' && !validatePhoneNumber(field.value)) {
                        // isValid = false;
                        // Add error for this field to the formErrors object
                        formErrors[currentTab+'-'+sectionIndex+'-'+fieldIndex] = `Invalid mobile`;
                    }

                    // if (field.type == 'file') {
                    //     requestData.append('files_'+ currentTab+'-'+sectionIndex+'-'+fieldIndex, field.value);
                    // }

                    if (['checkbox', 'dropdown', 'radio'].includes(field.type) && field?.value && field?.dependancy) {
                        let widgetIndex = field.value?.widget?.value;
                        let widget = formData?.widgets[widgetIndex];

                        widget?.fields.forEach((field, fieldIndex) => {
                            // Check if field is required and its value is empty
                            if (field.required && !field.value) {
                                // isValid = false;
                                // Add error for this field to the formErrors object
                                formErrors['widget-'+widgetIndex+'-'+fieldIndex] = `${field.placeholder} is required.`;
                            }
                            else if (field.type=='email' && !validateEmail(field.value)) {
                                // isValid = false;
                                // Add error for this field to the formErrors object
                                formErrors['widget-'+widgetIndex+'-'+fieldIndex] = `Invalid email`;
                            }
                            else if (field.type=='mobile' && !validatePhoneNumber(field.value)) {
                                // isValid = false;
                                // Add error for this field to the formErrors object
                                formErrors['widget-'+widgetIndex+'-'+fieldIndex] = `Invalid mobile`;
                            }
        
                            // if (field.type == 'file') {
                            //     requestData.append('files_widget_'+widgetIndex+'-'+fieldIndex, field.value);
                            // }
                        });
                    }
                });
            });
        }

        console.log(formErrors);
        
        // Update errors state with the collected errors
        setErrors(formErrors);
        // Proceed if form is valid
        if (Object.keys(formErrors).length == 0) {            
            // Start the loading state
            loader.style.display = 'block';
            
            try {
                if (currentTab == formData.tabs.length-1) {
                    setInteractionStatus(1);

                    requestData.append('interaction_json', JSON.stringify(formData));
                    requestData.append('interaction_is_completed', 1);
                }
                else{
                    setInteractionStatus(0);

                    requestData.append('interaction_json', JSON.stringify(formData));
                    requestData.append('interaction_is_completed', 0);
                }

                const response = await editInteraction(interaction_unique_id, requestData);

                if (response.status) {
                    // Mark the current tab as completed before moving to the next tab
                    setCompletedTabs((prevCompletedTabs) => {
                        // Ensure the current tab is only added once
                        if (!prevCompletedTabs.includes(parseInt(currentTab))) {
                            console.log('tab => ',parseInt(currentTab));
                            return [...prevCompletedTabs, parseInt(currentTab)];
                        }
                        return prevCompletedTabs;
                    });
                    if (formData.tabs.length-1 > currentTab) {

                        // Mark the current tab as completed
                        // setCompletedTabs(prevCompletedTabs => [...prevCompletedTabs, parseInt(currentTab)]);

                        // Move to the next tab
                        setCurrentTab(parseInt(currentTab)+1);
                    }
                    else{
                        navigate(`/interaction-completed/${interaction_unique_id}`);
                    }
                }
                else{
                    toast.error(response.message);
                }
            } catch (error) {
                console.log(error);
                toast.error(error);
            }

            // Stop the loading state
            loader.style.display = 'none';
        }
        // else{
        //     console.log(isValid);
        // }
    }

    function slugify(str) {
        str = str.replace(/[`~!@#$%^&*()_\-+=\[\]{};:'"\\|\/,.<>?\s]/g, ' ').toLowerCase();
 
        // trim spaces at start and end of string
        str = str.replace(/^\s+|\s+$/gm,'');
        
        // replace space with dash/hyphen
        str = str.replace(/\s+/g, '_');

        return str;
    }
 
  return (
    <>
        <Helmet>
            <title>Form Links - Funnl Onboarding</title>

            <link rel="stylesheet" href={`${process.env.REACT_APP_BASE_URL}/assets/css/public/bootstrap.min.css`} />
            <link rel="stylesheet" href={`${process.env.REACT_APP_BASE_URL}/assets/css/public/jquery-ui.min.css`} />
            <link rel="stylesheet" href={`${process.env.REACT_APP_BASE_URL}/assets/css/public/jquery-ui.theme.min.css`} />
            <link rel="stylesheet" href={`${process.env.REACT_APP_BASE_URL}/assets/css/public/styles.css`} />
        
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-datetimepicker/4.17.47/css/bootstrap-datetimepicker.min.css" />
            <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" />

            {/* <!-- Google Fonts --> */}
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
            <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500&family=Poppins:wght@300;600&display=swap" rel="stylesheet" />
        </Helmet>
        
        {loading===true ? (
            <div className='d-flex justify-content-center my-3'>
                <span className="loader-new"></span>
            </div>
        ) : (  
            <section className='interaction-form p-lg-3'>
                <div className="container">
                    <div className="wrapper-outside-inner position-relative" style={{background:"#FCF7EE"}}>
                        <div className="row d-lg-block d-none">
                            <div className="col-12">
                                <div className="banner-img position-relative">
                                    <img src={`${process.env.REACT_APP_BASE_URL}/assets/images/banner-img.png`} className="w-100 object-fit-cover" alt="..." style={{maxHeight:"300px"}} />

                                    <div className='text-holder text-center'>
                                        <img src='https://ancile.services/files/logo/logo.svg' alt='..' style={{ height:"auto"}} />
                                        <hr className='my-3 border border-light' />
                                        <h4>{form_title}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-8 col-12">
                                <div className="right-form p-lg-0 p-4">
                                    <div className='only-mobile d-lg-none d-block text-center'>
                                        <img src={`${process.env.REACT_APP_BASE_URL}/assets/images/logo_dark.png`} alt='..' />
                                        <h4 className='font-poppins fw-bold mt-3'>{form_title}</h4>
                                    </div>
                                    {formData.tabs.length > 0 && (
                                    <div className="progress-steps my-5 w-100">
                                        <ul className="nav nav-pills gap-4">
                                            {formData.tabs.map((tab, tabIndex) => (
                                                <li className="nav-item flex-grow-1" key={tab.name + tabIndex}>
                                                    {/* <a className={`nav-link ${tabIndex==currentTab ? 'active' : ''}`} aria-current="page" href="javascript:void(0);">
                                                        <i className="bi bi-person"></i>{tab.name}
                                                    </a> */}
                                                    <div className="progress" role="progressbar" aria-label="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" 
                                                    style={{height:"5px"}}>
                                                        <div className={`progress-bar ${tabIndex===currentTab ? 'active ' : ''} ${(completedTabs.includes(tabIndex) || tabIndex === currentTab) ? 'bg-success' : 'bg-secondary'}`} style={{width: (tabIndex===currentTab || completedTabs.includes(tabIndex)) ? '100%':'0'}}></div>
                                                    </div>
                                                    <span className='tab-name'>{tabIndex===currentTab && tab.name}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    )}
                                    
                                    {formData?.tabs?.map((tab, tabIndex) => (
                                    <div style={{display: tabIndex===currentTab ? 'block' : 'none'}} key={'tab-' + tab.name + tabIndex}>
                                        
                                        <div className="modal-body">
                                            <div className="row g-4">
                                                {tab?.sections?.map((section, sectionIndex) => (
                                                    <div key={'section-'+section+'-'+sectionIndex}>
                                                        <div className="col-12 mb-3">
                                                            {section.heading && <p className='title-heading'>{section.heading}</p>}
                                                            <div className="row g-3">
                                                                {section?.fields?.map((field, fieldIndex) => {

                                                                    if (['text', 'email', 'number', 'mobile'].includes(field.type) && ! field.value && field.mapField) {
                                                                        let val = interaction?.hasOwnProperty('interaction_form_' + field.mapField.replace(/ /g, "_") ) ? interaction['interaction_form_' + field.mapField] : '';
                                                                        if (val) {
                                                                            formData.tabs[currentTab].sections[sectionIndex].fields[fieldIndex].value = val;
                                                                        }
                                                                    }

                                                                    if (! field) return;

                                                                    return <div className={`col-md-${field.width}`} key={field.name + '-' + fieldIndex}>
                                                                        <div className="form-floating">

                                                                        <Input 
                                                                            type={field.type} 
                                                                            name={field.name}
                                                                            value={field.value ? field.value : ''}
                                                                            placeholder={field.placeholder} 
                                                                            label={field.label}
                                                                            width={field.width}
                                                                            fontSize={field.fontSize}
                                                                            options={field.options}
                                                                            isSearchable={field.isSearchable}
                                                                            isMulti={field.isMulti}
                                                                            showLabel={true}
                                                                            setVal={true}
                                                                            formData={formData}
                                                                            setFormData={setFormData}
                                                                            tabIndex={currentTab}
                                                                            sectionIndex={sectionIndex}
                                                                            fieldIndex={fieldIndex}
                                                                            errors={errors}
                                                                            setErrors={setErrors}
                                                                            interaction={interaction}
                                                                            mapField={field.mapField}
                                                                            infoFile={field.infoFile}
                                                                            setIsFormProgress={setIsFormProgress} />

                                                                            {errors[currentTab+'-'+sectionIndex+'-'+fieldIndex]  && (
                                                                                <p className="text-danger">{errors[currentTab+'-'+sectionIndex+'-'+fieldIndex]}</p>
                                                                            )} 

                                                                        </div>
                                                                    </div>
                                                                })}
                                                            </div>

                                                            <div className="row g-3">
                                                                {/* widget show here */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        
                                        <div className={`pb-4 modal-footer align-items-center justify-content-${currentTab==0 ? 'end' : 'end'} flex-nowrap flex-lg-row flex-column gap-lg-3 mt-3`}>
                                            <button type="button" className={`btn bck-btn ${currentTab==0 ? 'd-none' : ''}`} onClick={preForm} disabled={isFormProgress}>
                                                <BsChevronLeft /> <span class="bn"> Back</span>
                                            </button>        
                                            
                                            {/* <button type="button" className={`btn btn-next-form px-4`} onClick={validateForm} disabled={isFormProgress}>
                                                <i className="bi bi-check-circle"></i> {currentTab==tabCount-1 ? 'Submit' : 'Proceed'}
                                            </button> */}
                                            <button type='button' class="btn theme-btn theme-btn-blue" onClick={validateForm} disabled={isFormProgress}>
                                                <span class="spinner"></span>
                                                <span class="bn"> {currentTab===tabCount-1 ? 'Submit' : 'Proceed'}</span>
                                            </button>
                                        </div>
                                    </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </section>
         )}
        <Helmet>
        
        </Helmet>

    </>
  )
}

export default FormLink
