import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import LoginLayout from '../layout/LoginLayout';
import { apiError } from '../../helpers/ErrorHelper';
import { useAuth } from '../../context/AuthContext';
import { saveUserDataToStorage, userLogin, rememberEmailPassword, saveUserCredentialToStorage, removeUserCredentialFromStorage, getUserCredentialsFromStorage } from '../../models/UserModel';
import config from '../../config/config';


function Login() {

    const { state, dispatch } = useAuth(); 

    const navigate = useNavigate();

    const location = useLocation();

    const [redirectURL, setRedirectURL] = useState('');

    useEffect(()=>{
        const searchParams = new URLSearchParams(location.search);

        // Getting a specific query parameter by name
        if (searchParams.get('redirect')) {
            setRedirectURL(searchParams.get('redirect'));
        }


        if (state.isAuthenticated) {
            navigate(`${config.adminDir}/dashboard`);
        }
           
    }, [state, navigate]);

    const loader = document.getElementById('loading');


    const [showPass, setShowPass] = useState('hide');

    const toggleShowPass = () => {
        if (showPass==='show') {
            setShowPass('hide');
        }
        else{
            setShowPass('show');
        }
    }

    //Login Start
    const [user_email, setUserEmail] = useState('');
    const [user_password, setUserPassword] = useState('');
    const [errors, setErrors] = useState({user_email:  '',  user_password: '',});
    const [loading, setLoading] = useState(false); 

    const [rememberMe, setRememberMe] = useState(localStorage.getItem('remember') ? true : false);

    const doRememberCredentials = () => {
        if (rememberMe===true) {
            setRememberMe(true);
        }
        else{
            setRememberMe(true);
        }
    }
    useEffect(() => {
        // const storedEmail = localStorage.getItem('rememberedEmail');
        // const storedPassword = localStorage.getItem('rememberedPassword');
        const storedRememberMe = localStorage.getItem('rememberMe');
    
        if (storedRememberMe) {
            // setUserEmail(storedEmail || '');
            // setUserPassword(storedPassword || '');
            setRememberMe(true);
        }
      }, []);

    const doLogin = async() => {
        // Start the loading state
        loader.style.display = 'block';
        setLoading(true);

        try {
             // Clear previous errors
          setErrors({});
    
          const formData = { user_email, user_password };
    
          const response = await userLogin(formData);

          if (response.status) {
            toast.success(response.message);
    
            const user_data = response.data.user_data;

            saveUserDataToStorage(user_data);

            if (rememberMe===true) {
                saveUserCredentialToStorage(user_email, user_password);
            }
            else{
                removeUserCredentialFromStorage();
            }

            dispatch({ type: 'LOGIN', payload: {user_data} });

            if (redirectURL) {
                navigate(redirectURL);
            }
            else{
                navigate(`${config.adminDir}/dashboard`);
            }

            

          }
          else{
            setErrors(response.error);
            toast.error(response.message);
          }
            
        } catch (error) {
            //console.log(error);
            apiError(error);
        }

        // Stop the loading state
        loader.style.display = 'none';
        setLoading(false);

        

    } 
    
    useEffect(()=>{
        const credentials = getUserCredentialsFromStorage();
        setUserEmail(credentials.user_email);
        setUserPassword(credentials.user_password);
    }, []);
    //Login End

    
  return (
    <>
    
        <LoginLayout>
        
            <Helmet>
                <title>Login - Funnl Onboarding</title>
            </Helmet>

            <div className="container">
                <div className="row justify-content-center align-items-center authentication authentication-basic h-100">
                    <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-6 col-sm-8 col-12">
                        <div className="my-5 d-flex justify-content-center">
                            <Link to={process.env.REACT_APP_WEBSITE_URL} target='_blank'>
                                <img src={`${process.env.REACT_APP_BASE_URL}/assets/images/logo-wide.png`} alt="logo" className="desktop-logo" />
                                <img src={`${process.env.REACT_APP_BASE_URL}/assets/images/logo-wide.png`} alt="logo" className="desktop-dark" />
                            </Link>
                        </div>
                        <div className="card custom-card">
                            <div className="card-body p-5">
                                <p className="h5 fw-semibold mb-2 text-center">Sign-In(Test)</p>
                                <div className="row gy-3">
                                    <div className="col-xl-12">
                                        <label htmlFor="signin-username" className="form-label text-default">Email <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-lg" id="user_email" placeholder="Registered Email Address" value={user_email} onChange={(e) => setUserEmail(e.target.value)} />

                                        <p id='user_email_error'>
                                            {errors.user_email && <p style={{ color: 'red' }}>{errors.user_email}</p>}
                                        </p>
                                    </div>
                                    <div className="col-xl-12 mb-2">
                                        <label htmlFor="signin-password" className="form-label text-default d-block">Password <span className="text-danger">*</span><Link to="/forgot-password" className="float-end text-danger">Forget password ?</Link></label>
                                        <div className="input-group">
                                            <input type={showPass==='show' ? 'text' : 'password'} className="form-control form-control-lg" id="user_password" placeholder="password" value={user_password} onChange={(e) => setUserPassword(e.target.value)} />
                                            <button className="btn btn-light" type="button" onClick={toggleShowPass} title={showPass==='show' ? 'Hide Password' : 'Show Password'}>
                                                <i className={`bi bi-${showPass==='show' ? 'eye-slash' : 'eye'} align-middle`}></i>
                                            </button>
                                        </div>

                                        <p id='user_password_error'>
                                            {errors.user_password && <p style={{ color: 'red' }}>{errors.user_password}</p>}
                                        </p>
                                        <div className="mt-2">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" checked={rememberMe} onChange={doRememberCredentials} />
                                                <label className="form-check-label text-muted fw-normal" htmlFor="defaultCheck1">
                                                    Remember Me
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 d-grid mt-2">
                                        <button type='button' className="btn btn-lg btn-primary" onClick={doLogin}>
                                            {loading ? (
                                                <>
                                                <span className="spinner-border spinner-border-sm align-middle"></span>
                                                Loading...
                                                </>
                                            ) : (     
                                                'Sign In'
                                            )}
                                        </button>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Helmet>

            </Helmet>
        
        </LoginLayout>
    </>
  )
}

export default Login
