import React  from 'react'

function TableLoader({noOfColumn}) {

    let columns = '<td><span className="placeholder col-1"></span></td>';

    if (! isNaN(noOfColumn)) {
        noOfColumn -= 2;
        for (let i = 0; i < noOfColumn; i++) {
            columns += '<td><span className="placeholder col-3"></span></td>';
        }
    }

    columns += `<td className="text-end">
        <div className="hstack gap-2 fs-15 text-right">
            <button className="btn btn-icon btn-sm rounded-pill">
                <span className="placeholder col-12"></span>
            </button>
            <button className="btn btn-icon btn-sm rounded-pill">
                <span className="placeholder col-12"></span>
            </button>
        </div>
    </td>`;

    return (
        <table className="table text-nowrap placeholder-glow">
            <tbody>
                {[1,2,3,4,5,6,7,8].map((n) => (
                    <tr key={n} dangerouslySetInnerHTML={{ __html: columns }}></tr>
                ))}
            </tbody>
        </table>
    );
}  

export default TableLoader