import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { generateRandomString } from '../helpers/GeneralHelper';
import ReactDatePicker from 'react-datepicker';
import MyDropzone from './MyDropzone';
import 'bootstrap/dist/css/bootstrap.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover'
import { Button } from 'react-bootstrap';
import { getFile } from '../models/FormModel';

const Input= ({
    type,
    // name,
    placeholder,
    label,
    // width,
    // required,
    fontSize,
    value,
    options,
    isSearchable,
    isMulti,
    rows,
    showLabel,
    disabled = false,
    setVal,
    formData,
    setFormData,
    tabIndex,
    sectionIndex,
    fieldIndex,
    isView = false,
    isWidgetField = false,
    widgetIndex,
    errors,
    setErrors = null,
    interaction,
    mapField,
    infoFile,
    setIsFormProgress
}) => {
    const [inputName, setInputName] = useState('');
    const [widget, setWidget] = useState(null);
    const [inputValue, setInputValue] = useState(value);
    const [isChecked, setIsChecked] = useState(true);

    const [infoContent, setInfoContent] = useState('');

    useEffect(()=>{
        setInputName(generateRandomString(12));

        if ((type == 'radio' || type == 'dropdown') && value instanceof Object) {
            let selectedOption = options.find(val => { if (value.label == val.label) return val; });
            if (selectedOption instanceof Object) {
                setIsChecked(true);
                setWidget(selectedOption?.widget?.value);
            }
        } else if (type == 'file' && infoFile) {
            async function fetchFile() {
                try {
                    let file = await getFile(infoFile);
                    setInfoContent(file?.data?.file?.path);
                } catch(e) {}
            } 
            fetchFile();
        }
    }, []);

    const handleInput = (val) => {
        setInputValue(val);

        if (['checkbox'].includes(type)) {
            setIsChecked(!isChecked);
        } 
        else if (['radio'].includes(type)) {
            setIsChecked(true);
        } 
        else if (['dropdown'].includes(type)) {
            setIsChecked(true);
        }   

        if (setVal) {
            if (['text', 'email', 'number', 'password',  'textarea', 'radio', 'checkbox', 'mobile', 'dropdown', 'file', 'datepicker'].includes(type)) {
                const updatedFormData = { ...formData };

                if ('checkbox' == type) {
                    let values = [];
                    if (isWidgetField) {
                        values = updatedFormData.widgets[widgetIndex].fields[fieldIndex].value;
                    } else {
                        values = updatedFormData.tabs[tabIndex].sections[sectionIndex].fields[fieldIndex].value;
                    }

                    if (values) {
                        let isExist = false;
                        let map = [];
                        values.map(element => {
                            if(element.id != val.id) {
                                map.push(element);
                            } else {
                                isExist = true;
                            }
                        });

                        if (! isExist) {
                            values.push(val);
                        } else {
                            values = map;
                        }
                    } else {
                        values = [];
                        values.push(val);
                    }

                    if (isWidgetField) {
                        updatedFormData.widgets[widgetIndex].fields[fieldIndex].value = values;
                    } else {
                        updatedFormData.tabs[tabIndex].sections[sectionIndex].fields[fieldIndex].value = values;
                    }
                } else {
                    if (isWidgetField) {
                        updatedFormData.widgets[widgetIndex].fields[fieldIndex].value = val;
                    } else {
                        updatedFormData.tabs[tabIndex].sections[sectionIndex].fields[fieldIndex].value = val;
                    }
                }

                setFormData(updatedFormData);
            }
            
            if (formData?.widgets && val?.widget && val.widget != '-1') {
                setWidget(val.widget.value);
            } else {
                setWidget(null);
            }
        }
    };

    if (type == 'datepicker' && value) {
        value = new Date(Date.parse(value));
    }

    const keys = ["Backspace",  "Delete", "ArrowLeft", "ArrowRight", "Tab", "-"];

  return (
    <>
        {['text', 'email', 'password'].includes(type) && (
            <>
                <input type={type} className="form-control" placeholder={placeholder} value={value} onChange={(e) => handleInput(e.target.value)} disabled={disabled} />
                <label style={{display: showLabel===true ? 'block' : 'none'}}>{label}</label> 
            </>
        )}
        {['number'].includes(type) && (
            <>
                <input type="text" className="form-control" placeholder={placeholder} value={value} onChange={(e) => handleInput(e.target.value)} disabled={disabled} onKeyDown ={(e) => ! keys.includes(e.key) && !/\d/.test(e.key) && e.preventDefault()} />
                <label style={{display: showLabel===true ? 'block' : 'none'}}>{label}</label> 
            </>
        )}
        {['mobile'].includes(type) && (
            <>
                <input type="tel" className="form-control" placeholder={placeholder} value={value} maxLength="10" onChange={(e) => handleInput(e.target.value)} disabled={disabled} />
                <label style={{display: showLabel===true ? 'block' : 'none'}}>{label}</label> 
            </>
        )}
        {['textarea'].includes(type) && (
            <>
                <textarea className="form-control" value={value} rows={rows} onChange={(e) => handleInput(e.target.value)} disabled={disabled}></textarea>
                <label style={{display: showLabel===true ? 'block' : 'none'}}>{label}</label> 
            </>
        )}
        {['dropdown'].includes(type) && (
            
            <Select
                value={value}
                options={options}
                placeholder={placeholder}
                isSearchable={isSearchable}
                isMulti={isMulti}
                onChange={handleInput}
                disabled={disabled} />
        )}  
        {['radio'].includes(type) && (  

            <div className="mb-3">
                <p style={{fontSize:"16px"}}>{label}</p>
                <div className="row">
                    {options?.map((option, index) => (
                        <div className="col-md-6" key={inputName + '-' + option.value + '-' + index}>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input bg-light mt-2" name={inputName} type="radio" id={option.id} value={option.value} checked={value instanceof Object && value.label == option.label} onChange={() => handleInput(option)} disabled={disabled} />
                                <label className="form-check-label font-poppins">{option.label}</label>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        )}
        {['checkbox'].includes(type) && ( 

            <div className="col-12 mb-3">
                <p style={{fontSize:"16px"}}>{label}</p>
                <div className="row">
                    {options?.map((option, index) => (
                        <div className="col-md-6" key={inputName + '-' + option.value + '-' + index}>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input me-1" style={{height:"15px", width:"15px"}} name={inputName} type="checkbox" id={option.id} value={option.value} checked={Array.isArray(value) && value?.find(val => { return val.label == option.label })} onChange={() => handleInput(option)} disabled={disabled}  />
                                <label className="form-check-label">{option.label}</label>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        )}

        {['file'].includes(type) && (
            <>
            { isView ?
                <>
                    <label>{label}</label> &nbsp; &nbsp;
                    <div className='form-control' style={{backgroundColor: 'rgb(243, 246, 248)'}}>
                        <a href={typeof value === 'object' ? value.fullPath : '' } target='_blank'>{typeof value === 'object' ? value.file_original_name : ''}</a>
                    </div>
                </>
                :
                <>
                    <label style={{display: showLabel===true ? 'inline-block' : 'none'}} className='dropzone-label'>{label} &nbsp;</label>

                    {infoContent &&
                        <OverlayTrigger trigger="click" rootClose placement="right" overlay={
                                <Popover id="popover-basic">
                                    <Popover.Header as="h3">Sample Image</Popover.Header>
                                    <Popover.Body>
                                        <a href={infoContent} target='_blank'><img src={infoContent} alt="Sample" style={{width: 'auto', height: '150px', maxWidth: '100%'}} /></a>
                                    </Popover.Body>
                                </Popover>
                            }>
                            <Button variant="primary" className='btn-sm' style={{marginTop: '-20px', padding: '3px 10px'}}><i className="fa fa-info"></i></Button>
                        </OverlayTrigger>
                    }
                    
                    <MyDropzone 
                        onChange={(val) => {
                            handleInput(val); setErrors((errors) => ({ ...errors, [tabIndex+'-'+sectionIndex+'-'+fieldIndex]: null }))
                        }} 
                        onError={(error) => setErrors((errors) => ({ ...errors, [tabIndex+'-'+sectionIndex+'-'+fieldIndex]: error })) }
                        onProgress={(progress) => setIsFormProgress(progress != 100)} 
                    />
                </>
            }
            </>
        )}

        {['datepicker'].includes(type) && (
            <>
                <ReactDatePicker className="form-control" selected={value} autoComplete="off" disabled={disabled} placeholderText={placeholder}
                    onSelect={(e) => handleInput(e)} onChange={(e) => handleInput(e)} dateFormat="MM-dd-yyyy" />
                
                {/* <label style={{display: showLabel===true ? 'block' : 'none'}}>{label}</label>  */}
            </>
        )}

        {['heading'].includes(type) && (
            <div style={{fontSize: `${fontSize}px`}}>{label} &nbsp;{(interaction?.hasOwnProperty('interaction_form_'+placeholder.replace(/ /g, "_") )) && interaction['interaction_form_'+placeholder]} &nbsp; </div>
        )}
        {/* {['hidden'].includes(type) && (
            <>
                <input type="hidden" value={value} />
            </>
        )} */}

         {/* Conditionally render the widget based on the selected option */ }
         {formData?.widgets[widget] && isChecked && (
            <div className="row g-3">
                {/* Render the widget here */}
                <div className="col-md-12">
                    {/* Add your widget component or logic here */}
                    {formData?.widgets[widget]?.isShowHeading && <p>{formData.widgets[widget].heading}</p>}
                    <div className="row g-3">
                        {formData?.widgets[widget]?.fields.map((field, index) => (
                            <div className={`col-md-${field.width}`} key={'widget-' + field.name + index}>
                                <div className="form-floating">
                                    <Input
                                        type={field.type}
                                        name={field.name}
                                        placeholder={field.placeholder}
                                        label={field.label}
                                        width={field.width}
                                        fontSize={field.fontSize}
                                        options={field.options}
                                        isSearchable={field.isSearchable}
                                        isMulti={field.isMulti}
                                        showLabel={true}
                                        value={field.value ? field.value : ''}
                                        setVal={true}
                                        formData={formData}
                                        setFormData={setFormData}
                                        isWidgetField={true}
                                        widgetIndex={widget}
                                        fieldIndex={index}
                                        errors={errors}
                                        mapField={field?.mapField} />

                                        {errors['widget-'+widget+'-'+index] && (
                                            <p className="text-danger">{errors['widget-'+widget+'-'+index]}</p>
                                        )}
                                </div>
                            </div>
                        ))}    
                    </div>
                </div>
            </div>
        )}
                
    </>
  )
}

export default Input
