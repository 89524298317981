import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dateFormat from "dateformat";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AdminLayout from '../../layout/AdminLayout';
import { useTheme } from '../../../context/ThemeContext';
import { useAuth } from '../../../context/AuthContext';
import { getMailLogs } from '../../../models/LogModel';
import MailLogTables from '../../tables/MailLogTables';
import DeleteAllMailLogsModal from '../../modals/Logs/DeleteAllMailLogsModal';
import { useNotificationCount } from '../../../context/NotificationCountContext';
import { checkPermission } from '../../../helpers/GeneralHelper';
import Error403 from '../Error403';
import config from '../../../config/config';


function MailLogs() {

  const { notificationCount, setNotificationCount } = useNotificationCount();

  const { theme, toggleTheme } = useTheme();

  const { state } = useAuth(); 

  let user_data = state.user_data;

  const navigate = useNavigate();  

  const currentRoute = useLocation().pathname; 

  const loader = document.getElementById('loading');


  //Flush Mail Logs Start
  const flushMailLogs = async() => {
    localStorage.removeItem("mail_logs");
    fetchLogs(limit, page); 
  }  
  //Flush Mail Logs End


  //Mail Logs Start
  const [data, setData] = useState([]);
  const [cacheTime, setCacheTime] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);


  const fetchLogs = async(newLimit, page) => {
    setLoading(true);

    try {        
        let apiParam = {page: page, limit: newLimit, user_token: user_data.user_token};

        const response = await getMailLogs(apiParam);

        if (response.status) {

          if (response.data.mail_logs) {
            setData(response.data.mail_logs);
          }

          if (response.data.pagination.total_rows) {
            setTotalRows(response.data.pagination.total_rows);
          }

          if (response.data.time) {
            setCacheTime(response.data.time);
          }

          setLimit(newLimit);
          setPage(page);

        }
        else{
          if (response.response.data.invalid_token=='1') {
              toast.error(response.response.data.message);
              navigate(`${config.adminDir}/re-login?redirect=${currentRoute}`);
          }
        } 
        
        setLoading(false);
    } catch (error) {
      navigate(`${config.adminDir}/re-login?redirect=${currentRoute}`);
    }

    
  };
  //Mail Logs End


  //Delete All Mail Logs Start
  const [showDeleteAllMailLogsModal, setShowDeleteAllMailLogsModal] = useState(false);
  //Delete All Mail Logs End

    
  return (
    <>
        {checkPermission('logs_mail')===true ? (
        <AdminLayout>
        
            <Helmet>
                <title>Mail Logs - Funnl Onboarding </title>
            </Helmet>

            <div className="main-content app-content">

                
                <div className="container-fluid">

                    {/* <!-- Page Header --> */}
                    <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                        <h1 className="page-title fw-semibold fs-18 mb-0">Mail Logs</h1>
                        <div className="ms-md-1 ms-0">
                            <nav>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><a href="javascript:void(0);">Dashboard</a></li>
                                    <li className="breadcrumb-item">Logs</li>
                                    <li className="breadcrumb-item active" aria-current="page">Mail</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    {/* <!-- Page Header Close --> */}

                    {/* <!-- Start::row-1 --> */}
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card custom-card">
                                <div className="card-header d-flex align-items-center justify-content-between flex-wrap gap-3">
                                    <div className="card-title">
                                      Last Synced On {cacheTime}
                                    </div>

                                    <div className="d-flex flex-wrap gap-2">
                                        <button className="btn btn-danger-light btn-sm" onClick={() => setShowDeleteAllMailLogsModal(true)}>
                                          Delete All Logs
                                        </button>

                                        <button className="btn btn-success-light btn-sm" onClick={() => flushMailLogs()}>
                                            <i className="ri-refresh-line me-1 fw-semibold align-middle"></i>Refresh
                                        </button>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                      <MailLogTables 
                                          data={data}
                                          setData={setData}
                                          loading={loading}
                                          setLoading={setLoading}
                                          totalRows={totalRows}
                                          setTotalRows={setTotalRows}
                                          page={page}
                                          setPage={setPage}
                                          limit={limit}
                                          setLimit={setLimit}
                                          fetchLogs={fetchLogs} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!--End::row-1 --> */}


                    {/* <!--Delete All Logs Modal Start--> */}
                    <DeleteAllMailLogsModal
                      showModal={showDeleteAllMailLogsModal}
                      setShowModal={setShowDeleteAllMailLogsModal}
                      fetchLogs={fetchLogs}
                      limit={limit}
                      page={page}  />
                    {/* <!--Delete All Logs Modal End--> */}


                </div>


            </div>

            <Helmet>
                
            </Helmet>
        
        </AdminLayout>
        ) : ( <Error403/>)}
    </>
  )
}

export default MailLogs
