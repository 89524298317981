import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as model from '../../../models/CompaniesModel';
import { useAuth } from '../../../context/AuthContext';
import { useNotificationCount } from '../../../context/NotificationCountContext';
import { Text } from '../../inputs/inputs';
import { formatDataBeforeDisplay, formatDataBeforeSend, validorSettings } from '../../../helpers/GeneralHelper';
import SimpleReactValidator from 'simple-react-validator';
import { apiError } from '../../../helpers/ErrorHelper';

let fieldIndex = 100;
export const CompanyForm = ({module, showModal, setShowModal, refreshData, data, modalKey}) => {

  const inputFields = {
    company_name: ''
  }
  const formatOptions = [];

  const { notificationCount, setNotificationCount } = useNotificationCount();

  const navigate = useNavigate(); 

  const { state } = useAuth();
  let user_data = state.user_data;
  
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  
  const [id, setId] = useState('');
  const [fields, setFields] = useState(inputFields);
  const [companyFields, setCompanyFields] = useState([{id: fieldIndex++, '0': ''}]);

  const closeModal = () =>{
    setId('');
    setFields(inputFields);
    setErrors({});

    setShowModal(false);
  }

  useEffect(() => {
    // This effect will be called whenever data changes
    if (data) {
      setId(data[module.primaryCol]);
      setFields(formatDataBeforeDisplay(data, formatOptions));
      setErrors({});

      const fetchFields = async() => {
        if (! data?._company_id) {
          return;
        }

        try {
          const response = await model.getFields(user_data.user_token, data._company_id);

          if (typeof response != typeof undefined) {
            let temp = [];
            response.data.fields.forEach(function(item) {
              item.id = item._cf_id;
              item[item._cf_id] = item.cf_field_name;
              temp.push(item);
            });

            setCompanyFields(temp);
          }
        } catch (error) {
          console.error(error);
        }
      };

      fetchFields();
    }
  }, [data, modalKey]);

  const validator = useRef(new SimpleReactValidator(validorSettings()));
  const submit = async(e) => {
    e.preventDefault();

    if (! validator.current.allValid()) {
      toast.error('Please check the form errors!');
      return;
    }
    validator.current.hideMessages();

    if (! companyFields || companyFields.length == 0) {
      toast.error('You need to add at least 1 Field Name!');
      return;
    }
    
    setLoading(true);

    try {
      // Clear previous errors
      setErrors({});

      let formData = formatDataBeforeSend(fields, inputFields, formatOptions);
      formData.append('fields', JSON.stringify(companyFields));

      let response;
      if (data) {
        response = await model.update(user_data.user_token, id, formData);
      } else {
        response = await model.create(user_data.user_token, formData);
      }

      if (response.status) {
        setNotificationCount(localStorage.getItem('unread_notification_count'));
        toast.success(response.message);
        closeModal();

        model.saveToStorage(response.data[module.key], response.data.pagination);
        refreshData();
      } else {
        setErrors(response.error);
        toast.error(response.message);
      }
    } catch (error) {
      apiError(error);

      if (error.response?.data.invalid_token == 1) {
        navigate('/re-login');
      }
    }

    setLoading(false);
  }

  const addField = () => {
    fieldIndex++;
    setCompanyFields([...companyFields, {id: 'i'+fieldIndex, ['i'+fieldIndex]: ''}]);
  }

  const removeField = (id) => {
    let temp = companyFields.filter(function(item) {
      return item.id != id;
    })
    
    setCompanyFields(temp);
  }

  return (
    <Modal show={showModal} onHide={closeModal} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{modalKey == null ? 'Add' : 'Edit'} {module.singularTitle}</Modal.Title>
      </Modal.Header>

      <form onSubmit={submit}>
        <Modal.Body>
          <div className="row gy-2 mb-3">
            <div className="col-sm-12">
              <Text column="company_name" label="Company Name" required={true} fields={fields} setFields={setFields} errors={errors} />
            </div>
            <div className="col-sm-12 mt-3">
              <label htmlFor="fields" className="form-label">Fields <span className="text-danger"> *</span></label>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <td>Field Name</td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  {companyFields.map((field) => (
                    <tr key={field.id}>
                      <td>
                        <input className='form-control input-sm' required value={field[field.id]} onChange={(e) => {setCompanyFields(
                            companyFields.map((item) => {
                              return item.id === field.id? { ...item, [field.id]: e.target.value }: item;
                            })
                          )}} />
                      </td>
                      <td className='text-end'>
                        <button type='button' className='btn btn-danger btn-sm' onClick={() => removeField(field.id)}>
                          <i className="bx bxs-trash fw-semibold align-middle"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td></td>
                    <td className='text-end'>
                      <button type='button' className='btn btn-primary btn-sm' onClick={addField}>
                        <i className="ri-add-line fw-semibold align-middle"></i>
                      </button>
                    </td>
                  </tr>
                </tfoot>
              </table>
              
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
            <button type="button" className="btn btn-light" onClick={closeModal}>Cancel</button>
            <button className="btn btn-primary" disabled={loading}>
              {loading ? <span><span className="spinner-border spinner-border-sm align-middle"></span> Loading...</span> : 'Submit'}
            </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
