import React, { useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import AdminLayout from '../../layout/AdminLayout';
import { useTheme } from '../../../context/ThemeContext';
import { useAuth } from '../../../context/AuthContext';
import { useNotificationCount } from '../../../context/NotificationCountContext';
import { getForms } from '../../../models/FormModel';
import FormTables from '../../tables/FormTables';
import config from '../../../config/config';
import DeleteFormModal from '../../modals/Forms/DeleteFormModal';
import ActivateFormModal from '../../modals/Forms/ActivateFormModal';
import InactivateFormModal from '../../modals/Forms/InactivateFormModal';
import AddFormModal from '../../modals/Forms/AddFormModal';


function Forms() {

  const { notificationCount, setNotificationCount } = useNotificationCount();

  const { theme, toggleTheme } = useTheme();

  const { state } = useAuth(); 

  let user_data = state.user_data;

  const navigate = useNavigate();  

  const currentRoute = useLocation().pathname;  

  const loader = document.getElementById('loading');

  const [modalKey, setModalKey] = useState(0);

  const [formData, setFormData] = useState({});


  //Flush Forms Start
  const flushForms = async() => {
    localStorage.removeItem("forms");
    fetchForms(limit, page); 
  }  
  //Flush Forms End


  //Delete Form Start
  const [showDeleteFormModal, setShowDeleteFormModal] = useState(false);

  const openDeleteFormModal = (form_data) =>{
    setFormData(form_data);
    setModalKey(prevKey => prevKey + 1); 
    setShowDeleteFormModal(true);
  } 
  //Delete Form End


  //Activate Form Start
  const [showActivateFormModal, setShowActivateFormModal] = useState(false);

  const openActivateFormModal = (form_data) =>{
    setFormData(form_data);
    setModalKey(prevKey => prevKey + 1); 
    setShowActivateFormModal(true);
  } 
  //Activate Form End


  //Inactivate Form Start
  const [showInactivateFormModal, setShowInactivateFormModal] = useState(false);

  const openInactivateFormModal = (form_data) =>{
    setFormData(form_data);
    setModalKey(prevKey => prevKey + 1); 
    setShowInactivateFormModal(true);
  } 
  //Inactivate Form End


  //Add Form Start
  const [showAddFormModal, setShowAddFormModal] = useState(false);
  const openAddFormModal = () =>{
    setModalKey(prevKey => prevKey + 1); 
    setShowAddFormModal(true);
  } 
  //Delete Form End



  //Forms Start
  const [data, setData] = useState([]);
  const [cacheTime, setCacheTime] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);


  const fetchForms = async(newLimit, page) => {
    setLoading(true);

    try {        
        let apiParam = {page: page, limit: newLimit, user_token: user_data.user_token};

        const response = await getForms(apiParam);

        //console.log(response);

        if (response.status) {

          if (response.data.forms) {
            setData(response.data.forms);
          }

          if (response.data.pagination.total_rows) {
            setTotalRows(response.data.pagination.total_rows);
          }

          if (response.data.time) {
            setCacheTime(response.data.time);
          }

          setLimit(newLimit);
          setPage(page);

        }
        else{
          if (response.response.data.invalid_token=='1') {
              toast.error(response.response.data.message);
              navigate(`${config.adminDir}/re-login?redirect=${currentRoute}`);
          }
        }   
        
        setLoading(false);
    } catch (error) {
      navigate(`${config.adminDir}/re-login?redirect=${currentRoute}`);
    }

    
  };
  //Forms End

    
  return (
    <AdminLayout>
    
        <Helmet>
            <title>Forms - Funnl Onboarding </title>
        </Helmet>

        <div className="main-content app-content">

            
            <div className="container-fluid">

                {/* <!-- Page Header --> */}
                <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                    <h1 className="page-title fw-semibold fs-18 mb-0">Forms</h1>
                    <div className="ms-md-1 ms-0">
                        <nav>
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><a href="javascript:void(0);">Dashboard</a></li>
                                <li className="breadcrumb-item">Logs</li>
                                <li className="breadcrumb-item active" aria-current="page">Forms</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                {/* <!-- Page Header Close --> */}

                {/* <!-- Start::row-1 --> */}
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card custom-card">
                            <div className="card-header d-flex align-items-center justify-content-between flex-wrap gap-3">
                                <div className="card-title">
                                  Last Synced On {cacheTime}
                                </div>

                                <div className="d-flex flex-wrap gap-2">
                                    {/* <Link to={`${config.adminDir}/forms/add-form`} className="btn btn-primary btn-sm" >
                                        <i className="ri-add-line me-1 fw-semibold align-middle"></i>Add Form
                                    </Link> */}

                                    <button className="btn btn-primary btn-sm" onClick={openAddFormModal}>
                                      <i className="ri-add-line me-1 fw-semibold align-middle"></i>Add Form
                                    </button>

                                    <button className="btn btn-success-light btn-sm" onClick={() => flushForms()}>
                                        <i className="ri-refresh-line me-1 fw-semibold align-middle"></i>Refresh
                                    </button>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                  <FormTables 
                                      data={data}
                                      setData={setData}
                                      loading={loading}
                                      setLoading={setLoading}
                                      totalRows={totalRows}
                                      setTotalRows={setTotalRows}
                                      page={page}
                                      setPage={setPage}
                                      limit={limit}
                                      setLimit={setLimit}
                                      fetchForms={fetchForms}
                                      openDeleteFormModal={openDeleteFormModal}
                                      openActivateFormModal={openActivateFormModal}
                                      openInactivateFormModal={openInactivateFormModal} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!--End::row-1 --> */}


                {/* <!--Delete Interaction Modal Start--> */}
                <DeleteFormModal
                showModal={showDeleteFormModal}
                setShowModal={setShowDeleteFormModal}
                formData={formData}
                modalKey={modalKey}
                fetchForms={fetchForms}
                limit={limit}
                page={page}  />
                {/* <!--Delete Interaction Modal End--> */}


                {/* <!--Activate Interaction Modal Start--> */}
                <ActivateFormModal
                showModal={showActivateFormModal}
                setShowModal={setShowActivateFormModal}
                formData={formData}
                modalKey={modalKey}
                fetchForms={fetchForms}
                limit={limit}
                page={page}  />
                {/* <!--Activate Interaction Modal End--> */}


                {/* <!--Inactivate Interaction Modal Start--> */}
                <InactivateFormModal
                showModal={showInactivateFormModal}
                setShowModal={setShowInactivateFormModal}
                formData={formData}
                modalKey={modalKey}
                fetchForms={fetchForms}
                limit={limit}
                page={page}  />
                {/* <!--Inactivate Interaction Modal End--> */}


                <AddFormModal
                  showModal={showAddFormModal}
                  setShowModal={setShowAddFormModal}
                  modalKey={modalKey} />
            </div>
        </div>
        <Helmet>
            
        </Helmet>
    
    </AdminLayout>
  )
}

export default Forms
