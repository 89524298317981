import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import { useTheme } from '../../../context/ThemeContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EditWidgetFieldModal = ({ showModal, setShowModal, formData, setFormData, modalKey, fieldData, fieldIndex, widgetIndex, companyFields }) => {
  const { theme, toggleTheme, selectBoxTheme } = useTheme();  

  const [label, setLabel] = useState('');
  const [type, setType] = useState('');
  const [placeholder, setPlaceholder] = useState('');
  const [width, setWidth] = useState({ label: '12', value: '12' });
  const [fontSize, setFontSize] = useState('');
  const [required, setRequired] = useState(false);
  const [mapField, setMapField] = useState('');
  const [selectedSection, setSelectedSection] = useState(null); // State to store selected section

  const [errors, setErrors] = useState({ label: '', placeholder: '', type: '', width: '', section: '', mapField: '', fontSize: '' });

  const [options, setOptions] = useState([{ id: 1, label: '', value: '', key: Date.now() }]);
  const [optionLabel, setOptionLabel] = useState('');
  const [optionValue, setOptionValue] = useState('');

  const closeModal = () => {
    setLabel('');
    setType('');
    setPlaceholder('');
    setWidth({ label: '12', value: '12' });
    setFontSize('');
    setRequired(true);
    setMapField('');
    setSelectedSection(null); // Reset selected section
    setOptions([{ id: 1, label: '', value: '', key: Date.now() }]); // Reset options

    setErrors({});
    setShowModal(false);
  };

  

  // Function to add a new option row
  const addOptionRow = () => {
    setOptions([...options, { id: options.length + 1, label: '', value: '', key: Date.now() }]);
  };

  // Function to delete an option row
  const deleteOptionRow = (id) => {
    const updatedOptions = options.filter(option => option.id !== id);
    setOptions(updatedOptions);
  };

  // Function to update an option's label and value
  const updateOption = (id, key, value) => {
    const updatedOptions = options.map(option => {
      if (option.id === id) {
        return { ...option, [key]: value };
      }
      return option;
    });
    setOptions(updatedOptions);
  };

  const [types, setTypes] = useState([]);
  const [widths, setWidths] = useState([]);
  useEffect(() => {

    const typeOptions = 
    [
        {label: 'Text', value: 'text'},
        {label: 'Email', value: 'email'},
        {label: 'Mobile', value: 'mobile'},
        {label: 'Number', value: 'number'},
        {label: 'Password', value: 'password'},
        {label: 'Text Area', value: 'textarea'},
        {label: 'Dropdown', value: 'dropdown'},
        {label: 'Radio', value: 'radio'},
        {label: 'Checkbox', value: 'checkbox'},
        {label: 'File', value: 'file'},
        {label: 'Date Picker', value: 'datepicker'},
        {label: 'Heading', value: 'heading'},
        {label: 'Hidden', value: 'hidden'},
    ];
    setTypes(typeOptions);

    const widthOptions = 
    [
        {label: '4', value: '4'},
        {label: '6', value: '6'},
        {label: '12', value: '12'}
    ];

    setWidths(widthOptions);
}, []); 


useEffect(() => {
    if (fieldData) {
      setLabel(fieldData.label);
      setType(fieldData.type ? types.find(option => option.value === fieldData.type) : []);
      setPlaceholder(fieldData.placeholder);
      setWidth(fieldData.width ? widths.find(option => option.value === fieldData.width): []);
      setFontSize(fieldData?.fontSize);
      setRequired(fieldData.required);
      setMapField(fieldData.mapField ? companyFields.find(option => option.label === fieldData.mapField): '');

      if (fieldData.options) {
        setOptions(fieldData.options.map((option, index) => ({
            id: index + 1,
            label: option.label,
            value: option.value,
            key: Date.now() + index // You can use a unique key for each option
        })));
      }
    }
}, [fieldData, modalKey]);


const updateField = () => {
    // Clear previous errors
    setErrors({});

    const validationErrors = {};

    if (!label) {
      validationErrors.label = 'Label is required';
    }
    
    if (!type) {
      validationErrors.type = 'Type is required';
    }

    if (!placeholder) {
      validationErrors.placeholder = 'Placeholder is required';
    }

    if (!width) {
      validationErrors.width = 'Width is required';
    }

    if (type?.value == 'heading') {
      if ( ! fontSize) {
        validationErrors.fontSize = 'Font Size is required';
      } else if (isNaN(fontSize)) {
        validationErrors.fontSize = 'Font Size must be a numeric digit';
      } else if (fontSize < 1) {
        validationErrors.fontSize = 'Font Size must be greater then 0';
      }
    } else if (type?.value != 'hidden') {
      if (! mapField) {
        validationErrors.mapField = 'Company Field is required';
      }
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }


    // Create a new field object
    const updatedField = {
      label: label,
      type: type.value,
      placeholder: placeholder,
      width: width.value,
      fontSize: fontSize,
      required: required,
      mapField: mapField?.value,
      options: options
    };


    // Update the form data by update the field to the section
    const updatedFormData = { ...formData };

    updatedFormData.widgets[widgetIndex].fields[fieldIndex] = updatedField;


    // Update the state with the modified form data
    setFormData(updatedFormData);

    // console.log(updatedFormData);

    closeModal();
  };

  const [placeholderLabel, setPlaceholderLabel] = useState('Placeholder');
  useEffect(() => {
    if (type?.value == 'hidden') {
      setPlaceholderLabel('Value');
      setRequired(false);
    } else {
      setPlaceholderLabel('Placeholder');
    }

    if (type?.value == 'heading' || type?.value == 'hidden') {
      setRequired(false);
    } else {
      setRequired(true);
    }
  }, [type]);

  return (
    <Modal show={showModal} onHide={closeModal} size="lg" style={{ marginTop: '100px' }}>
      <Modal.Header closeButton>
        <Modal.Title>Update Widget Field</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row gy-2">
        <div className="col-xl-6">
            <label className="form-label">Label : <span className="text-danger">*</span></label>
            <input type="text" className="form-control" placeholder="Label Name" value={label} onChange={(e) => setLabel(e.target.value)} />
            {errors.label && <p style={{ color: 'red' }}>{errors.label}</p>}
          </div>
          <div className="col-xl-6">
            <label className="form-label">{placeholderLabel} : <span className="text-danger">*</span></label>
            <input type="text" className="form-control" placeholder={placeholderLabel} value={placeholder} onChange={(e) => setPlaceholder(e.target.value)} />
            {errors.placeholder && <p style={{ color: 'red' }}>{errors.placeholder}</p>}
          </div>
          <div className="col-xl-4">
            <label className="form-label">Type : <span className="text-danger">*</span></label>
            <Select
              value={type}
              onChange={setType}
              options={types} // Add more options as needed
              placeholder="Select Type"
              isSearchable={true}
              styles={selectBoxTheme}
              theme={(selectBoxTheme) => ({
                ...selectBoxTheme,
                borderRadius: 0,
              })}
            />
            {errors.type && <p style={{ color: 'red' }}>{errors.type}</p>}
          </div>
          <div className="col-xl-4">
            <label className="form-label">Width : <span className="text-danger">*</span></label>
            <Select
              value={width}
              onChange={setWidth}
              options={widths} // Add more options as needed
              placeholder="Select Width"
              isSearchable={true}
              styles={selectBoxTheme}
              theme={(selectBoxTheme) => ({
                ...selectBoxTheme,
                borderRadius: 0,
              })}
            />
            {errors.width && <p style={{ color: 'red' }}>{errors.width}</p>}
          </div>
          
          {! ['heading', 'hidden'].includes(type.value) && (
            <div className="col-xl-4">
              <label className="form-label">Required : <span className="text-danger">*</span></label>
              <br />
              <label className="radio-inline">
                <input
                  type="radio"
                  value={true}
                  checked={required === true ? 'checked' : ''}
                  onChange={(e) => setRequired(e.target.value)}
                />&nbsp;Yes&nbsp;&nbsp;
              </label>
              <label className="radio-inline">
                <input
                  type="radio"
                  value={false}
                  checked={required === false ? 'checked' : ''}
                  onChange={(e) => setRequired(e.target.value)}
                />&nbsp;No&nbsp;&nbsp;
              </label>
            </div>
          )}

          {['heading'].includes(type.value) && (
            <div className="col-xl-4">
              <label className="form-label">Font Size: <span className="text-danger">*</span></label>
              <input type="text" className="form-control" placeholder="Font Size" value={fontSize} onChange={(e) => setFontSize(e.target.value.trim())} />
              {errors.fontSize && <p style={{ color: 'red' }}>{errors.fontSize}</p>}
            </div>
          )}

          {! ['heading', 'hidden'].includes(type.value) && (
            <div className="col-xl-4">
              <label className="form-label">Company Field : <span className="text-danger">*</span></label>
              <Select
                value={mapField}
                onChange={setMapField}
                options={companyFields} // Add more options as needed
                placeholder="Select Company Field"
                isSearchable={true}
                styles={selectBoxTheme}
                theme={(selectBoxTheme) => ({
                  ...selectBoxTheme,
                  borderRadius: 0,
                })}
              />
              {errors.mapField && <p style={{ color: 'red' }}>{errors.mapField}</p>}
            </div>
          )}

          {/* Options for dropdown, radio, and checkbox fields */}
          {['dropdown', 'radio', 'checkbox'].includes(type.value) && (
            <div className="col-xl-12">
              <label className="form-label">Options
              <a className="mt-2" style={{cursor: 'pointer', fontSize: '15px', marginLeft: '5px', marginTop: '5px'}} onClick={addOptionRow}>
                <i className="bi bi-plus-circle"></i>
              </a>
              </label>
              {options.map(option => (
                <div className="row gy-2" key={option.key}>
                  <div className="col-xl-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Label"
                      value={option.label}
                      onChange={(e) => updateOption(option.id, 'label', e.target.value)}
                    />
                  </div>
                  <div className="col-xl-4">

                    <input
                      type="text"
                      className="form-control"
                      placeholder="Value"
                      value={option.value}
                      onChange={(e) => updateOption(option.id, 'value', e.target.value)}
                    />
                    
                  </div>
                  <div className="col-xl-4">
                    <a style={{cursor: 'pointer', fontSize: '15px', color: '#ff0000'}} onClick={() => deleteOptionRow(option.id)}>
                      <i className="bi bi-trash"></i>
                    </a>
                  </div>
                </div>
              ))}
              
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-light" onClick={closeModal}>Cancel</button>
        <button type="button" className="btn btn-primary" onClick={updateField}>Update</button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditWidgetFieldModal;
