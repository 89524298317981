import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet';
import AdminLayout from '../layout/AdminLayout';
import { useAuth } from '../../context/AuthContext';
import { getUserRoles } from '../../models/UserModel';
import UserRoleTables from '../tables/UserRoleTables';
import AddUserRoleModal from '../modals/Settings/AddUserRoleModal';
import UpdateUserRoleModal from '../modals/Settings/UpdateUserRoleModal';
import DeleteUserRoleModal from '../modals/Settings/DeleteUserRoleModal';
import UserRoleMembersModal from '../modals/Settings/UserRoleMembersModal';
import { getApplicationsSettings, updateLinuxDesktopApplicationSettings, updateMacOsDesktopApplicationSettings, updateWindowsDesktopApplicationSettings } from '../../models/SettingsModel';
import { apiError } from '../../helpers/ErrorHelper';
import { toast } from 'react-toastify';
import { useNotificationCount } from '../../context/NotificationCountContext';
import Error403 from './Error403';
import { checkPermission } from '../../helpers/GeneralHelper';
import UpdateUserRolePermissionModal from '../modals/Settings/UpdateUserRolePermissionModal';

const Settings = () => {

  const { notificationCount, setNotificationCount } = useNotificationCount();

  const { state } = useAuth(); 

  let user_data = state.user_data;

  const navigate = useNavigate();

  const currentRoute = useLocation().pathname;

  const loader = document.getElementById('loading');

  const [modalKey, setModalKey] = useState(0);

  //Add Role Start
  const [showAddRoleModal, setShowAddRoleModal] = useState(false);
  //Add Role End


  //Update Role Start
  const [showUpdateRoleModal, setShowUpdateRoleModal] = useState(false);

  const [roleData, setRoleData] = useState({});

  const openUpdateRoleModal = (role_data) =>{
    setRoleData(role_data);
    setModalKey(prevKey => prevKey + 1); 
    setShowUpdateRoleModal(true);
  }  
  //Update Role End



  //Update Members Start
  const [showUpdateMembersModal, setShowUpdateMembersModal] = useState(false);

  const openUpdateMembersModal = (role_data) =>{
    setRoleData(role_data);
    setModalKey(prevKey => prevKey + 1); 
    setShowUpdateMembersModal(true);
  }
  //Update Members End


  //Update Permissions Start
  const [showUpdatePermissionsModal, setShowUpdatePermissionsModal] = useState(false);

  const openUpdatePermissionsModal = (role_data) =>{
    setRoleData(role_data);
    setModalKey(prevKey => prevKey + 1); 
    setShowUpdatePermissionsModal(true);
  }
  //Update Permissions End


  //Delete Role Start
  const [showDeleteRoleModal, setShowDeleteRoleModal] = useState(false);

  const openDeleteRoleModal = (role_data) =>{
    setRoleData(role_data);
    setModalKey(prevKey => prevKey + 1); 
    setShowDeleteRoleModal(true);
  } 
  //Delete Role End


  //Flush Role Start
  const flushRole = async() => {
    localStorage.removeItem("user_roles");
    fetchRoles(); 
  }  
  //Flush Role End


  //Role List Start
  const [data, setData] = useState([]);
  const [cacheTime, setCacheTime] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchRoles = async() => {
    setLoading(true);

    try {
        let apiParam = {user_token: user_data.user_token};

        const response = await getUserRoles(apiParam);

        if (response.status) {

          if (response.data.user_roles) {
            setData(response.data.user_roles);
          }

          if (response.data.time) {
            setCacheTime(response.data.time);
          }

          setNotificationCount(localStorage.getItem('unread_notification_count'));

          setLoading(false);
        }
        else{
          if (response.response.data.invalid_token=='1') {
              navigate(`/re-login?redirect=${currentRoute}`);
          }
        }               

    } catch (error) {
      //console.error(error);
    }
    
  };
  //Role List End


  return (
    <>
      {checkPermission('menu_settings')===true ? (
      <AdminLayout>
        
        <Helmet>
            <title>Settings - Funnl Onboarding</title>
        </Helmet>

        <div className="main-content app-content">

            
            <div className="container-fluid">
              <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                    <h1 className="page-title fw-semibold fs-18 mb-0">Settings</h1>
                    <div className="ms-md-1 ms-0">
                        <nav>
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><a href="javascript:void(0);">Pages</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Settings</li>
                            </ol>
                        </nav>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12">
                        <div className="card custom-card">
                            <div className="card-body">

                              <div className="card custom-card">
                                  <div className="card-header d-flex align-items-center justify-content-between flex-wrap gap-3">
                                      <div className="card-title">
                                        Last Synced On {cacheTime}
                                      </div>

                                      <div className="d-flex flex-wrap gap-2">
                                        <button className="btn btn-primary btn-sm"  onClick={() => setShowAddRoleModal(true)}>
                                            <i className="ri-add-line me-1 fw-semibold align-middle"></i>Add Role
                                        </button>

                                        <button className="btn btn-success-light btn-sm" onClick={() => flushRole()}>
                                            <i className="ri-refresh-line me-1 fw-semibold align-middle"></i>Refresh
                                        </button>
                                    </div>
                                  </div>

                                  <div className="card-body">

                                    {/* <!---User Role Table Start---> */}
                                    <UserRoleTables
                                      data={data}
                                      setData={setData}
                                      loading={loading}
                                      setLoading={setLoading}
                                      fetchRoles={fetchRoles} 
                                      openUpdateRoleModal={openUpdateRoleModal}
                                      openDeleteRoleModal={openDeleteRoleModal}
                                      openUpdateMembersModal={openUpdateMembersModal}
                                      openUpdatePermissionsModal={openUpdatePermissionsModal}/>
                                    {/* <!---User Role Table End---> */}

                                    {/* <!--Add User Role Modal Start--> */}
                                    <AddUserRoleModal
                                    showModal={showAddRoleModal}
                                    setShowModal={setShowAddRoleModal}
                                    fetchRoles={fetchRoles} />
                                    {/* <!--Add User Role Modal End--> */}


                                    {/* <!--Update User Role Modal Start--> */}
                                    <UpdateUserRoleModal
                                    showModal={showUpdateRoleModal}
                                    setShowModal={setShowUpdateRoleModal}
                                    roleData={roleData}
                                    modalKey={modalKey}
                                    fetchRoles={fetchRoles} />
                                    {/* <!--Update User Role Modal End--> */}


                                    {/* <!--Update Role Members Modal Start--> */}
                                    <UserRoleMembersModal
                                    showModal={showUpdateMembersModal}
                                    setShowModal={setShowUpdateMembersModal}
                                    roleData={roleData}
                                    modalKey={modalKey}
                                    fetchRoles={fetchRoles} />
                                    {/* <!--Update Role Members Modal End--> */}


                                    {/* <!--Update User Role Permission Modal Start--> */}
                                    <UpdateUserRolePermissionModal
                                    showModal={showUpdatePermissionsModal}
                                    setShowModal={setShowUpdatePermissionsModal}
                                    roleData={roleData}
                                    modalKey={modalKey}
                                    fetchRoles={fetchRoles} />
                                    {/* <!--Update User Role Permission Modal End--> */}


                                    {/* <!--Delete User Role Modal Start--> */}
                                    <DeleteUserRoleModal
                                    showModal={showDeleteRoleModal}
                                    setShowModal={setShowDeleteRoleModal}
                                    roleData={roleData}
                                    modalKey={modalKey}
                                    fetchRoles={fetchRoles} />
                                    {/* <!--Delete User Role Modal End--> */}


                                  </div>
                              </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>  

        <Helmet>
            
        </Helmet>
    
      </AdminLayout>
      ) : ( <Error403/>)}
    </>
  )
}

export default Settings