import React, {useEffect, useState} from 'react';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import Login from './views/pages/Login';
import ReLogin from './views/pages/ReLogin';
import ForgotPassword from './views/pages/ForgotPassword';
import Dashboard from './views/pages/Dashboard';
import Error404 from './views/pages/Error404';
import TeamMembers from './views/pages/TeamMembers';

import Profile from './views/pages/Profile';
import WsLogs from './views/pages/Logs/WsLogs';
import WsJson from './views/pages/Logs/WsJson';

import Notifications from './views/pages/Notifications';
import ResetPassword from './views/pages/ResetPassword';
import MailLogs from './views/pages/Logs/MailLogs';

import { useAuth } from './context/AuthContext';
import Settings from './views/pages/Settings';
import Forms from './views/pages/Forms/Forms';
import AddForm from './views/pages/Forms/AddForm';
import config from './config/config';
import FormLink from './views/pages/public/FormLink';
import FormLinkStatic from './views/pages/public/FormLinkStatic';
import EditForm from './views/pages/Forms/EditForm';
import Interactions from './views/pages/Interactions/Interactions';
import CreateInteraction from './views/pages/public/CreateInteraction';
import ViewInteraction from './views/pages/Interactions/ViewInteraction';
import InteractionCompleted from './views/pages/public/InteractionCompleted';
import Companies from './views/pages/Companies/Companies';

import SendEmail from './views/pages/SendEmail';
const basename = process.env.REACT_APP_BASENAME || '';

function App() {  

    const { state, dispatch } = useAuth();
       
    return ( 
    <>
        <BrowserRouter basename={basename}>
        
        <Routes>
            <Route exact index element={<Login  />} />
            <Route exact path={`${config.adminDir}/login`} element={<Login  />} />
            <Route exact path={`${config.adminDir}/re-login`} element={<ReLogin  />} />
            <Route exact path={`${config.adminDir}/forgot-password`} element={<ForgotPassword  />} />
            <Route exact path={`${config.adminDir}/reset-password/:token`} element={<ResetPassword  />} />

            <Route exact path={`${config.adminDir}/notifications`} element={<Notifications  />} />

            <Route exact path={`${config.adminDir}/dashboard`} element={<Dashboard  />} />
            <Route exact path={`${config.adminDir}/profile`} element={<Profile  />} />
            <Route path={`${config.adminDir}/profile/:user_unique_id`} element={<Profile  />} />

            <Route exact path={`${config.adminDir}/team-members`} element={<TeamMembers  />} />

            <Route exact path={`${config.adminDir}/forms`} element={<Forms />} />
            <Route exact path={`${config.adminDir}/forms/add-form`} element={<AddForm />} />
            <Route exact path={`${config.adminDir}/forms/edit-form/:form_unique_id`} element={<EditForm />} />

            <Route exact path={`${config.adminDir}/interactions`} element={<Interactions />} />
            <Route exact path={`${config.adminDir}/interactions/view-interaction/:interaction_unique_id`} element={<ViewInteraction />} />
 
            <Route exact path={`${config.adminDir}/settings`} element={<Settings />} />

            <Route exact path={`${config.adminDir}/ws-logs`} element={<WsLogs />} />
            <Route path={`${config.adminDir}/view-ws-json/:id`} element={<WsJson  />} />

            <Route exact path={`${config.adminDir}/mail-logs`} element={<MailLogs />} />


            <Route exact path={`/form/:form_unique_id`} element={<CreateInteraction />} />
            <Route exact path={`/interaction/:interaction_unique_id`} element={<FormLink />} />
            <Route exact path={`/interaction-completed/:interaction_unique_id`} element={<InteractionCompleted />} />
            <Route exact path={`/form-static/:form_unique_id`} element={<FormLinkStatic />} />

            <Route exact path={`${config.adminDir}/companies`} element={<Companies />} />

            <Route exact path={`/send-email`} element={<SendEmail />} />

            <Route path="*" element={<Error404  />} />

            <Route path="/error404" element={<Error404  />} />
        </Routes>
        
        </BrowserRouter>

        <ToastContainer
            position="bottom-right"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            
        />
    
    </>
        
    );
}

export default App;