import { POST } from "../config/ApiConfig";
import 'react-toastify/dist/ReactToastify.css';

export const sendEmail = async(formData) => {

    const endPoint = "/send_form_email/send_form_email";

    const response = await POST(endPoint, formData);

    return response;
}