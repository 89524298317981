import React, { useEffect } from 'react';
import dateFormat from "dateformat";
import DataTable, { createTheme } from 'react-data-table-component';
import { useTheme } from '../../context/ThemeContext';
import { Link } from 'react-router-dom';
import config from '../../config/config';
import InteractionList from '../skeletons/InteractionList';


const InteractionTables = ({
    data,
    setData,
    loading,
    setLoading,
    totalRows,
    setTotalRows,
    page,
    setPage,
    limit,
    setLimit,
    fetchInteractions,
    openDeleteInteractionModal,
    _form_id,
    interaction_is_completed,
    search
}) => {

    const { theme } = useTheme();


    const CustomLoader = () => (
        <InteractionList/>
    );


    const handlePageChange = async (page) => {
        fetchInteractions(limit, page);
    };
    
    const handleLimitChange = async (newLimit, page) => {

        if (newLimit!==10 && page!==1) {
            fetchInteractions(newLimit, page); 
        }
        else if (localStorage.getItem('interactions')) {
            fetchInteractions(newLimit, page); 
        }
    };

    useEffect(()=>{
        fetchInteractions(limit, page);  
    }, [_form_id, interaction_is_completed]);


    const columns = [
        {
            name: "#",
            selector: (row, index) => <div>{((page-1)*limit)+index+1}.</div>,
            width: '50px'
        },
        {
            name: "Form",
            selector: (row, index) => <Link to={`/form/${row.form_unique_id}`} target="_blank">{row.form_title}</Link>
        },
        {
            name: "Name",
            selector: (row, index) => row.interaction_form_name
        },
        {
            name: "Email",
            selector: (row, index) => row.interaction_form_email
        },
        {
            name: "Mobile",
            selector: (row, index) => row.interaction_form_mobile
        },
        {
            name: "Status",
            selector: (row) =>
            {
                if (row.interaction_is_completed==='1') {
                    return (
                        <>
                          <span className="badge bg-success-transparent">Completed</span>
                        </>
                      );
                }
                else{
                    return (
                        <>
                          <span className="badge bg-danger-transparent">Not Completed</span>
                        </>
                      );
                }    
            }
        },
        {
            name: "Created On",
            selector: (row) => dateFormat(row.interaction_create_time, "dd mmm yyyy HH:MM")
        },
        {
            name: "Last Updated",
            selector: (row) => row.form_last_update ? dateFormat(row.interaction_last_update, "dd mmm yyyy HH:MM") : ''
        },
        {
            name: "Action",
            cell: row => <div className="hstack gap-2 fs-15">
                            <Link to={`${config.adminDir}/interactions/view-interaction/${row.interaction_unique_id}`} className="btn btn-icon btn-sm btn-info" title="View" >
                                <i className='bi bi-eye'></i>
                            </Link>

                            <Link to={`/interaction/${row.interaction_unique_id}`} target="_blank" className="btn btn-icon btn-sm btn-dark" title="Interaction Link" >
                                <i className='bi bi-box-arrow-up-right'></i>
                            </Link>

                            <button type="button" className="btn btn-icon btn-sm btn-danger" title="Delete" onClick={() => openDeleteInteractionModal(row)}>
                                <i className='bx bxs-trash'></i>
                            </button>
                        </div>
        }
    ];

    createTheme('dark', {
        text: {
          primary: '#a2a3a4',
          secondary: '#2aa198',
        },
        background: {
          default: '#252729',
        },
        context: {
          background: '#cb4b16',
          text: '#a2a3a4',
        },
        divider: {
          default: '#073642',
        },
        action: {
          button: 'rgba(0,0,0,.54)',
          hover: 'rgba(0,0,0,.08)',
          disabled: 'rgba(0,0,0,.12)',
        },
    });


    return (
        <DataTable 
            title={`Manage Interactions`} 
            columns={columns} 
            data={data} 
            pagination 
            paginationServer 
            paginationTotalRows={totalRows} 
            onChangeRowsPerPage={handleLimitChange} 
            onChangePage={handlePageChange} 
            progressPending={loading} 
            progressComponent={<CustomLoader />}
            highlightOnHover
            theme={`${theme==='light' ? 'light' : 'dark'}`} />
    );
};    

export default InteractionTables;    