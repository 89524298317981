import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const apiError = (error) => {

    if (error.response && error.response.data.error) {
        // Server responded with field-specific errors
        toast.error (error.response.data.message || 'An error occurred');
    } else if (error.request) {
        // The request was made but no response was received
        toast.error ('No response from server ! Please try again later');
    } else {
        // Something happened in setting up the request that triggered an Error
        toast.error ('Error:', error.message);
    }

    return error;
}; 