import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import LoginLayout from '../layout/LoginLayout';
import { useAuth } from '../../context/AuthContext';
import { userForgotPassword } from '../../models/UserModel';
import { apiError } from '../../helpers/ErrorHelper';



function ForgotPassword() {

    const { state, dispatch } = useAuth(); 

    const navigate = useNavigate();

    useEffect(()=>{
        if (state.isAuthenticated) {
            navigate(`dashboard`);
        }
           
    }, [state, navigate]);

    const loader = document.getElementById('loading'); 
    
    
    //Forgot Password Start  
  const [user_email, setUserEmail] = useState('');
  const [errors, setErrors] = useState({user_email:  ''});
  const [loading, setLoading] = useState(false); 

  const doForgotPassword = async() => {
    // Start the loading state
    loader.style.display = 'block';
    setLoading(true);

    try {
        // Clear previous errors
        setErrors({});

        const formData = { user_email, env: process.env.REACT_APP_ENVIRONMENT};
    
        const response = await userForgotPassword(formData);

        if (response.status) {
            toast.success(response.message);

            setUserEmail('');
        }
        else{
            setErrors(response.error);
            toast.error(response.message);
        }
        
    } catch (error) {
        apiError(error);
    }

    // Stop the loading state
    loader.style.display = 'none';
    setLoading(false);

  }  
  //Forgot Password End  
    
  return (
    <>
    
        <LoginLayout>
        
            <Helmet>
                <title>Forgot Password - Funnl Onboarding</title>
            </Helmet>

            <div className="container">
                <div className="row justify-content-center align-items-center authentication authentication-basic h-100">
                    <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-6 col-sm-8 col-12">
                        <div className="my-5 d-flex justify-content-center">
                            <Link to={process.env.REACT_APP_WEBSITE_URL} target='_blank'>
                                <img src={`${process.env.REACT_APP_BASE_URL}/assets/images/logo-header.png`} alt="logo" className="desktop-logo" />
                                <img src={`${process.env.REACT_APP_BASE_URL}/assets/images/logo-header.png`} alt="logo" className="desktop-dark" />
                            </Link>
                        </div>
                        <div className="card custom-card">
                            <div className="card-body p-5">
                                <p className="h5 fw-semibold mb-2 text-center">Forgot Password</p>
                                <div className="row gy-3">
                                    <div className="col-xl-12">
                                        <label className="form-label text-default">Email</label>
                                        <input type="text" className="form-control form-control-lg" placeholder="Registered Email Address" 
                                        value={user_email} onChange={(e) => setUserEmail(e.target.value)}/>

                                        <p id='user_email_error'>
                                            {errors.user_email && <p style={{ color: 'red' }}>{errors.user_email}</p>}
                                        </p>
                                    </div>
                                    <div className="col-xl-12 mb-2">
                                        <label htmlFor="signin-password" className="form-label text-default d-block">
                                            <Link to="/login" className="float-end text-danger">Back to Login</Link>
                                        </label>
                                        
                                    </div>
                                    <div className="col-xl-12 d-grid mt-2">
                                        <button type='button' className="btn btn-lg btn-primary" onClick={doForgotPassword}>
                                            {loading ? (
                                                <>
                                                <span className="spinner-border spinner-border-sm align-middle"></span>
                                                Loading...
                                                </>
                                            ) : (     
                                                'Submit'
                                            )}
                                        </button>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Helmet>
                
            </Helmet>
        
        </LoginLayout>
    </>
  )
}

export default ForgotPassword
