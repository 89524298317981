import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const DeleteWidgetModal = ({showModal, setShowModal, formData, setFormData, widgetIndex}) => {


    const closeModal = () =>{
        setShowModal(false);
    }

    const deleteWidget = () => {

        // Remove the field from the fields array of the selected section
        const updatedFormData = { ...formData };

        updatedFormData.widgets.splice(widgetIndex, 1);
      
        // Update the state with the modified form data
        setFormData(updatedFormData);
      
        // console.log(updatedFormData);
      
        closeModal();
    };


    return (
        <Modal show={showModal} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Widget</Modal.Title>
            </Modal.Header>
    
            <Modal.Body>
                <p>Do you want to delete this widget ? This process can't be undone</p>
            </Modal.Body>
    
            <Modal.Footer>
                <button type="button" className="btn btn-light" onClick={closeModal}>Cancel</button>
                <button type="button" className="btn btn-danger" onClick={deleteWidget}>Delete</button>
            </Modal.Footer>
        </Modal>
    ); 

}; 


export default DeleteWidgetModal;    