import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';

const EditTabModal = ({ showModal, setShowModal, formData, setFormData, tabData, modalKey, tabIndex }) => {
  const [tabName, setTabName] = useState('');
  const [position, setPosition] = useState('after'); // Default position is 'after'
  const [errors, setErrors] = useState({ tabName: '' });

  const closeModal = () => {
    setTabName('');
    setPosition('after'); // Reset position to default
    setErrors({});
    setShowModal(false);
  };

  const updateTab = () => {
    // Clear previous errors
    setErrors({});

    const validationErrors = {};

    if (!tabName) {
      validationErrors.tabName = 'Tab name is required';
    } else if (formData.tabs.some(tab => tab.name === tabName)) {
      validationErrors.tabName = 'Tab name already exists';
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const updatedTab = {
        name: tabName,
        sections: tabData.sections // Initialize fields as an empty array
    };

    formData.tabs[tabIndex] = updatedTab;


    setFormData(prevFormData => ({
        ...prevFormData,
        tabs: [...prevFormData.tabs] // Make a shallow copy of tabs array
      }));

    closeModal();
  };

  useEffect(() => {
    if (tabData) {
        setTabName(tabData.name);
    }
}, [tabData, modalKey]);

  return (
    <Modal show={showModal} onHide={closeModal} size="sm" style={{marginTop: '100px'}}>
      <Modal.Header closeButton>
        <Modal.Title>Update Tab</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row gy-2">
          <div className="col-xl-12">
            <label className="form-label">Tab Name : <span className="text-danger">*</span></label>
            <input type="text" className="form-control" placeholder="Tab Name" value={tabName} onChange={(e) => setTabName(e.target.value)} />
            {errors.tabName && <p style={{ color: 'red' }}>{errors.tabName}</p>}
          </div>
          
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-light" onClick={closeModal}>Cancel</button>
        <button type="button" className="btn btn-primary" onClick={updateTab}>Update</button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditTabModal;
