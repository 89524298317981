import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';

const EditWidgetModal = ({ showModal, setShowModal, formData, setFormData, modalKey, widgetIndex, widgetData }) => {
  const [widgetHeading, setWidgetHeading] = useState('');
  const [isShowHeading, setIsShowHeading] = useState(true);
  
  const [errors, setErrors] = useState({ widgetHeading: '' });

  const closeModal = () => {
    setWidgetHeading('');
    setIsShowHeading(true);
    
    setErrors({});
    setShowModal(false);
  };

  const updateWidget = () => {
    // Clear previous errors
    setErrors({});

    const validationErrors = {};

    if (!widgetHeading) {
      validationErrors.widgetHeading = 'Widget heading is required';
    } 
    else if (formData.widgets.some((widget, index) => index != widgetIndex && widget.heading === widgetHeading)) {
        validationErrors.widgetHeading = 'Widget name already exists';
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const updatedWidget = {
        heading: widgetHeading,
        isShowHeading: isShowHeading,
        fields: widgetData.fields // Initialize fields as an empty array
    };


    formData.widgets[widgetIndex] = updatedWidget;

    // Update the formData state with the updated tabs
    setFormData(prevFormData => ({
      ...prevFormData,
      tabs: [...prevFormData.tabs] // Make a shallow copy of tabs array
    }));

    closeModal();
  };


  useEffect(() => {
    if (widgetData) {
        setWidgetHeading(widgetData.heading);
        setIsShowHeading(widgetData.isShowHeading);
    }
}, [widgetData, modalKey]);

  return (
    <Modal show={showModal} onHide={closeModal} size="sm" style={{marginTop: '100px'}}>
      <Modal.Header closeButton>
        <Modal.Title>Update Widget</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row gy-2">
          <div className="col-xl-12">
            <label className="form-label">Heading : <span className="text-danger">*</span></label>
            <input type="text" className="form-control" placeholder="Widget Heading" value={widgetHeading} onChange={(e) => setWidgetHeading(e.target.value)} />
            {errors.widgetHeading && <p style={{ color: 'red' }}>{errors.widgetHeading}</p>}
          </div>
          
          <div className="col-xl-12">
            <label className="form-label">Show Heading:</label>
            <input type="checkbox" checked={isShowHeading} onChange={(e) => setIsShowHeading(e.target.checked)} />
          </div>
          
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-light" onClick={closeModal}>Cancel</button>
        <button type="button" className="btn btn-primary" onClick={updateWidget}>Update</button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditWidgetModal;
