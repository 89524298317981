import React, { useEffect, useState } from 'react';
import Submodules from './Submodules';

const Module = ({ value, label, submodules, selectedModules, setSelectedModules }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleChange = (e) => {
    const isChecked = e.target.checked;
    setIsChecked(isChecked);

    // Add or remove the module from the selectedModules array
    if (isChecked) {
      setSelectedModules([...selectedModules, value]);
    } else {
      setSelectedModules(selectedModules.filter(module => module !== value));
    }
  };

  useEffect(() => {
    setIsChecked(selectedModules.includes(value));
  }, [value]);  

  return (
    <>
    <div className="card custom-card">

        <div className="card-header justify-content-between">
            <div className="card-title">
                <div className="form-check form-check-lg d-flex align-items-center">
                <input
                    type="checkbox"
                    className="form-check-input"
                    id={value}
                    checked={isChecked}
                    onChange={handleChange}
                />
                <label className="form-check-label" htmlFor={value}>{label}</label>
                </div>
            </div>
        </div> 

        <div className="card-body d-sm-flex align-items-center justify-content-between">
            {isChecked && (
                
                    <Submodules
                        submodules={submodules}
                        selectedModules={selectedModules}
                        setSelectedModules={setSelectedModules}
                        />
                
            )}    
        </div>

    </div>
    </>
  );
};

export default Module;