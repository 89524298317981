import React, { useEffect, useState} from 'react';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { getFormDetails } from '../../../models/FormModel';


function FormLinkStatic() {

    const currentRoute =  useLocation().pathname;  

    const navigate = useNavigate(); 

    const { form_unique_id } = useParams();

    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(true);

    const fetchFormDetails = async() => {
        setLoading(true);

        try {
            const response = await getFormDetails(form_unique_id);

            if (response.status) {

                if (response.data.form_data) {
                    setFormData(response.data.form_data);
                    setLoading(false);
                }
            }
            else{
                console.error(response.message);
            }    
            
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(()=>{
        fetchFormDetails();  
    }, [form_unique_id]);
 
  return (
    <>
        <Helmet>

            <title>Form Links - Funnl Onboarding</title>

            <link rel="stylesheet" href={`${process.env.REACT_APP_BASE_URL}/assets/css/public/bootstrap.min.css`} />
            <link rel="stylesheet" href={`${process.env.REACT_APP_BASE_URL}/assets/css/public/jquery-ui.min.css`} />
            <link rel="stylesheet" href={`${process.env.REACT_APP_BASE_URL}/assets/css/public/jquery-ui.theme.min.css`} />
            <link rel="stylesheet" href={`${process.env.REACT_APP_BASE_URL}/assets/css/public/styles.css`} />
        
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-datetimepicker/4.17.47/css/bootstrap-datetimepicker.min.css" />
            <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" />

        
            {/* <!-- Google Fonts --> */}
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
            <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500&family=Poppins:wght@300;600&display=swap" rel="stylesheet" />
        </Helmet>
        
        {loading===true ? (
            <>Loading...</>
        ) : (  
        <div className="modal-page shadow" id="recruit_form_modal">
            <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header d-block">
                            <div className="text-center mb-3">
                                <img src="images/logo-dark.svg" className="img-fluid" alt="" />
                            </div>

                            <h4 className="modal-title text-dark text-center" id="staticBackdropLabel">{formData.form_title}</h4>
                        </div>
                        <div className="modal-tabs">
                            <ul className="nav nav-pills">
                                <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="#"><i className="bi bi-person"></i> General Info</a>
                                </li>
                                <li className="nav-item">
                                <a className="nav-link" href="#"><i className="bi bi-folder"></i> Job Details</a>
                                </li>
                                <li className="nav-item">
                                <a className="nav-link" href="#"><i className="bi bi-card-list"></i> Agreement</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#"><i className="bi bi-cash"></i> Payment</a>
                                </li>
                            </ul>
                        </div>
                        
                        <hr className="border-secondary my-0" />
                        <div className="modal-body">
                            <form className="row g-3">
                                <div className="col-12 mb-3">
                                    <p>Entity Name*</p>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-floating">
                                                <input type="text" className="form-control" placeholder="name@example.com" />
                                                <label>Name</label>
                                            </div>
                                        </div>
                                    
                                    </div>
                                </div>
                                
                                <div className="col-12 mb-3">
                                    <p>Billing Details*</p>
                                    <div className="row g-3">
                                        <div className="col-md-6">
                                            <div className="form-floating">
                                                <input type="text" className="form-control" placeholder="First Name" />
                                                <label>First Name</label> 
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-floating">
                                                <input type="text" className="form-control" placeholder="First Name" />
                                                <label>Last Name</label> 
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-floating">
                                                <input type="email" className="form-control" placeholder="Email Address" />
                                                <label>Email Address</label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-floating">
                                                <input type="email" className="form-control"  placeholder="Number" />
                                                <label>Phone Number</label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-floating">
                                                <input type="text" className="form-control" placeholder="Line 1" />
                                                <label>Street Address</label> 
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-floating">
                                                <input type="text" className="form-control" placeholder="Line 2" />
                                                <label>Street Address 2</label> 
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-floating">
                                                <input type="text" className="form-control" placeholder="City" />
                                                <label>City</label> 
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-floating">
                                                <input type="text" className="form-control" placeholder="Province" />
                                                <label>Province</label> 
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-floating">
                                                <input type="text" className="form-control" placeholder="Zip" />
                                                <label>ZipCode</label> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-12 mb-3">
                                    <p>Entity ID*</p>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-floating">
                                                <input type="text" className="form-control" placeholder="Number" />
                                                <label>Entity Id</label> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                
                                <div className="col-12 mb-3">
                                    <p>Terminal Address*</p>
                                    <div className="row g-3">
                                        <div className="col-md-12">
                                            <div className="form-floating">
                                                <input type="text" className="form-control" placeholder="Line 1" />
                                                <label >Street Address</label> 
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-floating">
                                                <input type="text" className="form-control" placeholder="Line 2" />
                                                <label >Street Address 2</label> 
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-floating">
                                                <input type="text" className="form-control" placeholder="City" />
                                                <label>City</label> 
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-floating">
                                                <input type="text" className="form-control" placeholder="Province" />
                                                <label>Province</label> 
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-floating">
                                                <input type="text" className="form-control" placeholder="Zip" />
                                                <label>ZipCode</label> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                

                                <div className="col-12 mb-3">
                                    <div className="form-check form-switch">
                                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked"><p>Is the Billing Contact and Recruiting Contact the same ?*</p></label>
                                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked />
                                    </div>
                                </div>                

                                <div className="col-12 mb-3">
                                    <p>Are you looking for P&D Recruiting or LineHaul Recruiting ?*</p>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="option3" />
                                                <label className="form-check-label" htmlFor="inlineRadio3">P&D</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio4" value="option4" />
                                                <label className="form-check-label" htmlFor="inlineRadio4">LineHaul</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 mb-3">
                                    <p>File Upload</p>
                                    <div className="upload-card">        
                                        <div className="file-upload">
                                            <div className="file-select">
                                                <div className="imagePreview"></div>
                                                <button className="btn btn-secondary">Choose File</button>
                                                <div className="file-select-name">No File Chosen</div>
                                                <input type="file" name="files[]" className="profileimg" />
                                            </div>
                                        </div>
                                    </div>
                                </div>  

                                <div className="col-6 mb-3">
                                    <div className="input-field">
                                        <input type="text" className="form-control" id="datepicker" /> 
                                        <label>Date</label>
                                    </div>
                                </div>  
                                

                                <div className="col-6 mb-3">
                                    <div className="input-field">
                                        <input type="text" className="form-control time-pickable" id="timepicker" /> 
                                        
                                    </div>
                                </div> 

                                <div className="col-12 mb-3">
                                    <fieldset>
                                        <label htmlFor="selectcustom"><p>Demo Dropdown</p></label>
                                        <select name="selectcustom" className="form-select" id="selectcustom">
                                        <option>Slower</option>
                                        <option>Slow</option>
                                        <option selected="selected">Medium</option>
                                        <option>Fast</option>
                                        <option>Faster</option>
                                        </select>
                                    </fieldset>
                                </div> 

                                <div className="col-12 mb-3">
                                    <p>Checkbox</p>
                                    <div className="dropdown">
                                        <label className="dropdown-label">Select Options</label>
                                        
                                        <div className="dropdown-list">
                                        <div className="checkbox">
                                            <input type="checkbox" name="dropdown-group-all" className="check-all checkbox-custom" id="checkbox-main"/>
                                            <label htmlFor="checkbox-main" className="checkbox-custom-label">Selection All</label>
                                        </div>
                                        
                                        <div className="checkbox">
                                            <input type="checkbox" name="dropdown-group" className="check checkbox-custom" id="checkbox-custom_01"/>
                                            <label htmlFor="checkbox-custom_01" className="checkbox-custom-label">Selection 1</label>
                                        </div>
                                        
                                        <div className="checkbox">
                                            <input type="checkbox" name="dropdown-group" className="check checkbox-custom" id="checkbox-custom_02"/>
                                            <label htmlFor="checkbox-custom_02" className="checkbox-custom-label">Selection 2</label>
                                        </div>
                                        
                                        <div className="checkbox">
                                            <input type="checkbox" name="dropdown-group" className="check checkbox-custom" id="checkbox-custom_03"/>
                                            <label htmlFor="checkbox-custom_03" className="checkbox-custom-label">Selection 3</label>
                                        </div>
                                        
                                        <div className="checkbox">
                                            <input type="checkbox" name="dropdown-group" className="check checkbox-custom" id="checkbox-custom_04"/>
                                            <label htmlFor="checkbox-custom_04" className="checkbox-custom-label">Selection 4</label>
                                        </div>
                                        </div>
                                    </div>
                                </div> 
                            </form>
                        </div>
                        <hr className="border-secondary my-0" />
                        <div className="modal-footer align-items-center justify-content-end flex-nowrap flex-lg-row flex-column gap-lg-3">
                            
                            <div className="d-flex justify-content-end">            
                                
                                <button type="button" className="btn btn-next-form px-4"><i className="bi bi-check-circle"></i> Proceed</button>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
         )}
        <Helmet>
        
        </Helmet>

    </>
  )
}

export default FormLinkStatic
