import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import AdminLayout from '../../layout/AdminLayout';
import { useTheme } from '../../../context/ThemeContext';
import { useAuth } from '../../../context/AuthContext';
import { useNotificationCount } from '../../../context/NotificationCountContext';
import AddTabModal from '../../modals/Forms/AddTabModal';
import AddSectionModal from '../../modals/Forms/AddSectionModal';
import AddFieldModal from '../../modals/Forms/AddFieldModal';
import Input from '../../../components/Input';
import config from '../../../config/config';
import { getInteractionDetails } from '../../../models/InteractionModel';



function ViewInteraction() {

  const { notificationCount, setNotificationCount } = useNotificationCount();

  const { theme, toggleTheme } = useTheme();

  const { state } = useAuth(); 

  let user_data = state.user_data;

  const navigate = useNavigate();  

  const { interaction_unique_id } = useParams();

  const currentRoute = useLocation().pathname;  

  const loader = document.getElementById('loading');


  const [modalKey, setModalKey] = useState(0);

  const [interaction_form_name, setInteractionFormName] = useState('');
  const [interaction_form_email, setInteractionFormEmail] = useState('');
  const [interaction_form_mobile, setInteractionFormMobile] = useState('');

  const [interaction, setInteraction] = useState(null);
  const [form_title, setFormTitle] = useState('');
  const [formData, setFormData] = useState({
    tabs: [],
    widgets: []
  });
  const [loading, setLoading] = useState(true);




  const fetchInteractionDetails = async() => {
    setLoading(true);

    try {
        const response = await getInteractionDetails(interaction_unique_id);

        if (response.status) {

            if (response.data.interaction_data) {

                setInteraction(response.data.interaction_data);
                console.log(JSON.parse(response.data.interaction_data.interaction_json));

                if (response.data.interaction_data.form_title) {
                    setFormTitle(response.data.interaction_data.form_title);
                }

                if (response.data.interaction_data.interaction_json) {
                    setFormData(JSON.parse(response.data.interaction_data.interaction_json));
                }

                if (response.data.interaction_data.interaction_form_name) {
                    setInteractionFormName(response.data.interaction_data.interaction_form_name);
                }

                if (response.data.interaction_data.interaction_form_email) {
                    setInteractionFormEmail(response.data.interaction_data.interaction_form_email);
                }

                if (response.data.interaction_data.interaction_form_mobile) {
                    setInteractionFormMobile(response.data.interaction_data.interaction_form_mobile);
                }

                setLoading(false);
            }
        }
        else{
            console.error(response.message);
        }    
        
    } catch (error) {
        if (error.response.data.invalid_token==1) {
            toast.error(error.response.data.message);
            navigate(`${config.adminDir}/re-login?redirect=${currentRoute}`);
        }
    }
}

useEffect(()=>{
    fetchInteractionDetails();  
}, [interaction_unique_id]);



  return (
        <AdminLayout>
        
            <Helmet>
                <title>View Interaction - Funnl Onboarding </title>
            </Helmet>

            <div className="main-content app-content">

                
                <div className="container-fluid">

                    {/* <!-- Page Header --> */}
                    <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                        <h1 className="page-title fw-semibold fs-18 mb-0">
                            View Interaction
                        </h1>
                        <div className="ms-md-1 ms-0">
                            <nav>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><a href="javascript:void(0);">Dashboard</a></li>
                                    <li className="breadcrumb-item">View Interactions</li>
                                    <li className="breadcrumb-item active" aria-current="page">View Interaction</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    {/* <!-- Page Header Close --> */}

                   
                    
                   {/* <!-- Start::row-1 --> */}
                   {loading===true ? (
                    <div className="row">
                        <div className="col-xl-12">
                            <div className='d-flex justify-content-center my-3'>
                                <span className="loader-new"></span>
                            </div>
                        </div>
                    </div>
                    ) : (
                        <div className="row">
                            

                            <div className="col-xl-12">
                                <div className="card custom-card">
                                    <div className="card-header justify-content-between">
                                        <div className="card-title me-auto">
                                            Intraction Details
                                        </div>
                                    </div>
                                    <div className="card-body" style={{minHeight: '200px'}}>
                                        <div className='row'>

                                        <div className="col-xl-4">
                                            <label className="form-label">Name </label>
                                            <input type='text' className='form-control' value={interaction_form_name} disabled={true} />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="form-label">Email </label>
                                            <input type='text' className='form-control' value={interaction_form_email} disabled={true} />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="form-label">Mobile </label>
                                            <input type='text' className='form-control' value={interaction_form_mobile} disabled={true} />
                                        </div>


                                            <div className='col-xl-12'>
                                                <label className="form-label">Title</label>
                                                <input type='text' className='form-control' value={form_title} disabled={true} />
                                            </div>
                                        </div>  
                                        
                                        
                                        <div className='row mt-4'>
                                            <div className='col-xl-12'>
                                            
                                            {formData.tabs.length > 0 && (
                                            <ul className="nav nav-pills justify-content-center nav-style-2 mb-3" role="tablist">
                                                {formData.tabs.map((tab, tabIndex) => (
                                                <li key={tabIndex} className="nav-item" role="presentation" >

                                                    <div className="btn-group my-1">
                                                        <a className={`nav-link ${tabIndex==0 ? 'active' : ''}`} data-bs-toggle="tab" role="tab" 
                                                            aria-current="page" 
                                                            href={`#tab-${tabIndex}`} 
                                                            aria-selected="true">{tab.name}
                                                        </a>
                                                        
                                                    </div>
                                                </li>
                                                ))}
                                            </ul>
                                            )}

                                            {formData.tabs.length > 0 && (
                                            <div className="tab-content">
                                                {formData.tabs.map((tab, tabIndex) => (
                                                    <div className={`tab-pane show ${tabIndex==0 ? 'active' : ''} text-muted`} id={`tab-${tabIndex}`} role="tabpanel" key={tabIndex}>
                                                        {tab?.sections.map((section, sectionIndex) => (
                                                            <>
                                                            <div className='row mb-3' key={sectionIndex}>

                                                                <div className='col-md-12'>
                                                                    <h4>{section.heading}</h4>
                                                                </div>

                                                            </div>
                                                                
                                                                
                                                            <div className='row g-3 mb-4'>
                                                                {section?.fields.map((field, fieldIndex) => {
                                                                    if (! field) return <></>;

                                                                    return <div className={`col-md-${field.width}`} key={fieldIndex} >
                                                                        <div className="form-group">

                                                                            <Input 
                                                                                type={field.type} 
                                                                                name={field.name}
                                                                                value={field.value ? field.value : ''}
                                                                                placeholder={field.placeholder} 
                                                                                label={field.label}
                                                                                width={field.width}
                                                                                fontSize={field.fontSize}
                                                                                options={field.options}
                                                                                isSearchable={field.isSearchable}
                                                                                isMulti={field.isMulti}
                                                                                showLabel={false}
                                                                                formData={formData}
                                                                                tabIndex={tabIndex}
                                                                                sectionIndex={sectionIndex}
                                                                                fieldIndex={fieldIndex}
                                                                                errors={{}}
                                                                                interaction={interaction}
                                                                                disabled={true}
                                                                                isView={true} />
                                                                        </div>
                                                                    </div>
                                                                })}

                                                                        
                                                            </div>
                                                            </>
                                                        ))}    
                                                    </div>
                                                ))}   
                                            </div>   
                                            )} 

                                            </div>
                                        </div>
                                        
                                    </div>

                                    <div className="card-footer">
                                        <Link to={`${config.adminDir}/interactions`} className="btn btn-primary-light btn-wave ms-auto float-end">Close</Link>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    )}
                   {/* <!-- End::row-1 --> */}

                </div>


            </div>

            <Helmet>
                
            </Helmet>
        
        </AdminLayout>
        
  )
}

export default ViewInteraction
