import React from 'react'
import { Link } from 'react-router-dom';
import { useSubMenu } from '../../context/SubMenuContext';
// import { useSideMenu } from '../../context/SideMenuContext';
import { checkPermission } from '../../helpers/GeneralHelper';
import config from '../../config/config';

function LeftSideBar() {
 
  const { subMenuOpen, setSubMenuOpen } = useSubMenu();

//   const { sideMenu, toggleSideMenu} = useSideMenu();

  const handleCurrentMenu = (menuName)=>{
    setSubMenuOpen(menuName);
    
    //console.log(menuName);
  }


  return (
      <aside className="app-sidebar sticky" id="sidebar">

        {/* <!-- Start::main-sidebar-header --> */}
        <div className="main-sidebar-header">
            <Link to={`${config.adminDir}/dashboard`} className="header-logo">
                <img src={`${process.env.REACT_APP_BASE_URL}/assets/images/logo-wide.png`} alt="logo" className="desktop-logo" />
                <img src={`${process.env.REACT_APP_BASE_URL}/assets/images/logo-small.png`} alt="logo" className="toggle-logo" />
                <img src={`${process.env.REACT_APP_BASE_URL}/assets/images/logo-wide.png`} alt="logo" className="desktop-dark" />
                <img src={`${process.env.REACT_APP_BASE_URL}/assets/images/logo-small.png`} alt="logo" className="toggle-dark" />
                <img src={`${process.env.REACT_APP_BASE_URL}/assets/images/logo-wide.png`} alt="logo" className="desktop-white" />
                <img src={`${process.env.REACT_APP_BASE_URL}/assets/images/logo-small.png`} alt="logo" className="toggle-white" />
            </Link>
        </div>
        {/*<!-- End::main-sidebar-header --> */}

        {/* <!-- Start::main-sidebar --> */}
        <div className="main-sidebar" id="sidebar-scroll">

            {/* <!-- Start::nav --> */}
            <nav className="main-menu-container nav nav-pills flex-column sub-open">
                <div className="slide-left" id="slide-left">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width="24" height="24" viewBox="0 0 24 24"> <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z"></path> </svg>
                </div>
                <ul className="main-menu">
                    {/* <!-- Start::slide__category --> */}
                    <li className="slide__category"><span className="category-name">Main</span></li>
                    {/* <!-- End::slide__category --> */}

                    <li className="slide" onClick={() => handleCurrentMenu('dashboard')}>
                        <Link to={`${config.adminDir}/dashboard`} className="side-menu__item">
                            <i className="bx bx-home side-menu__icon"></i>
                            <span className="side-menu__label">Dashboard</span>
                        </Link>
                    </li>

                    <li className="slide">
                        <Link to={`${config.adminDir}/companies`} className="side-menu__item" onClick={() => handleCurrentMenu('companies')}>
                            <i className="bx bx-home side-menu__icon"></i>
                            <span className="side-menu__label">Companies</span>
                        </Link>
                    </li>

                    <li className="slide" onClick={() => handleCurrentMenu('team-members')}>
                        <Link to={`${config.adminDir}/team-members`} className="side-menu__item d-none">
                            <i className="bx bxs-group side-menu__icon"></i>
                            <span className="side-menu__label">Team Members</span>
                        </Link>
                    </li>

                    <li className="slide" onClick={() => handleCurrentMenu('forms')}>
                        <Link to={`${config.adminDir}/forms`} className="side-menu__item">
                            <i className="bx bi-list-columns side-menu__icon"></i>
                            <span className="side-menu__label">Forms</span>
                        </Link>
                    </li>


                    <li className="slide" onClick={() => handleCurrentMenu('interactions')}>
                        <Link to={`${config.adminDir}/interactions`} className="side-menu__item">
                            <i className="bi bi-person-lines-fill side-menu__icon"></i>
                            <span className="side-menu__label">Interactions</span>
                        </Link>
                    </li>


                    {/* <!-- Start::slide__category --> */}
                    <li className="slide__category"><span className="category-name">Logs & Settings</span></li>
                    {/* <!-- End::slide__category --> */}

                    <li className={`slide has-sub ${subMenuOpen==='logs' ? 'open' : subMenuOpen} ${checkPermission('menu_logs')===false ? 'd-none' : ''}`} onClick={() => handleCurrentMenu('logs')}>
                        <a href="javascript:void(0);" className="side-menu__item">
                            <i className="bx bi-list-columns side-menu__icon"></i>
                            <span className="side-menu__label">Logs</span>
                            <i className="fe fe-chevron-right side-menu__angle"></i>
                        </a>
                        <ul className="slide-menu child1" style={{ display: `${subMenuOpen === 'logs' ? 'block' : 'none'}` }}>
                            <li className="slide side-menu__label1">
                                <a href="javascript:void(0);">Logs</a>
                            </li>
                            <li className={`slide ${checkPermission('logs_ws')===false ? 'd-none' : ''}`}>
                                <Link to={`${config.adminDir}/ws-logs`} className="side-menu__item">Web Service</Link>
                            </li>
                            <li className={`slide ${checkPermission('logs_mail')===false ? 'd-none' : ''}`}>
                                <Link to={`${config.adminDir}/mail-logs`} className="side-menu__item">Mail</Link>
                            </li>
                        </ul>
                    </li>

                    <li className={`slide ${checkPermission('menu_settings')===false ? 'd-none' : ''}`} onClick={() => handleCurrentMenu('settings')}>
                        <Link to={`${config.adminDir}/settings`} className="side-menu__item">
                            <i className="bx bx-cog side-menu__icon"></i>
                            <span className="side-menu__label">Settings</span>
                        </Link>
                    </li>

                </ul>
                <div className="slide-right" id="slide-right"><svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width="24" height="24" viewBox="0 0 24 24"> <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"></path> </svg></div>
            </nav>
            {/* <!-- End::nav --> */}

        </div>
        {/* <!-- End::main-sidebar --> */}

     </aside>

  )
}

export default LeftSideBar
