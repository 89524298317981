import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import { useTheme } from '../../../context/ThemeContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyDropzone from '../../../components/MyDropzone';

const AddFieldModal = ({ showModal, setShowModal, selectedTab, formData, setFormData, sectionIndex, companyFields }) => {
  const { theme, toggleTheme, selectBoxTheme } = useTheme();  

  const [label, setLabel] = useState('');
  const [type, setType] = useState('');
  const [placeholder, setPlaceholder] = useState('');
  const [width, setWidth] = useState({ label: '12', value: '12' });
  const [fontSize, setFontSize] = useState('');
  const [required, setRequired] = useState(false);
  const [mapField, setMapField] = useState('');
  const [dependancy, setDependancy] = useState(false); 
  const [infoFile, setInfoFile] = useState('');
  const [infoText, setInfoText] = useState('');
  const [selectedSection, setSelectedSection] = useState(null); // State to store selected section

  const [errors, setErrors] = useState({ label: '', placeholder: '', type: '', width: '', fontSize: '', section: '', mapField: '', infoFile: '', infoText: '' });

  const [options, setOptions] = useState([]);
  // const [optionLabel, setOptionLabel] = useState('');
  // const [optionValue, setOptionValue] = useState('');

  const closeModal = () => {
    setLabel('');
    setType('');
    setPlaceholder('');
    setWidth({ label: '12', value: '12' });
    setFontSize('');
    setRequired(true);
    setMapField('');
    setInfoFile('');
    setInfoText('');
    setDependancy(false);
    setSelectedSection(null); // Reset selected section
    setOptions([]); // Reset options

    setErrors({});
    setShowModal(false);
  };


  const validateLabel = (label) => {
    if (!label) {
      return 'Label is required';
    }
    return '';
  };
  
  const validateValue = (value) => {
    if (!value) {
      return 'Value is required';
    }
    return '';
  };
  
  const validateWidget = (widget) => {
    if (dependancy && !widget) {
      return 'Widget is required when dependency is enabled';
    }
    return '';
  };

  const addField = () => {
    // Clear previous errors
    setErrors({});

    const validationErrors = {};

    if (!label) {
      validationErrors.label = 'Label is required';
    }
    
    if (!type) {
      validationErrors.type = 'Type is required';
    }

    if (!placeholder) {
      validationErrors.placeholder = 'Placeholder is required';
    }

    if (!width) {
      validationErrors.width = 'Width is required';
    }

    if (type?.value == 'heading') {
      if ( ! fontSize) {
        validationErrors.fontSize = 'Font Size is required';
      } else if (isNaN(fontSize)) {
        validationErrors.fontSize = 'Font Size must be a numeric digit';
      } else if (fontSize < 1) {
        validationErrors.fontSize = 'Font Size must be greater then 0';
      }
    } else if (type?.value != 'hidden') {
      if (! mapField?.value) {
        validationErrors.mapField = 'Company Field is required';
      }
    }

    // Validate label, value, and widget for each option

    let widgetCount = 0;

    options.forEach((option, index) => {
      const labelError = validateLabel(option.label);
      const valueError = validateValue(option.value);
      const widgetError = validateWidget(option.widget.value);
      if (labelError || valueError) {
        validationErrors[`option-${index}`] = { label: labelError, value: valueError, widget: widgetError };
      }

      if (option.widget.value!='') {
        widgetCount = parseInt(widgetCount)+1;
      }
      else{
        widgetCount = parseInt(widgetCount)+0;
      }
    });

    if (Object.keys(validationErrors).length > 0) {
      console.log(validationErrors);
      setErrors(validationErrors);
      return;
    }
    else if (dependancy && widgetCount==0) {
      toast.error('Atleast a widget is required to select');
      return;
    }

    // Find the index of the selected tab
    const tabIndex = selectedTab;

    // Create a new field object
    const newField = {
      label: label,
      type: type.value,
      placeholder: placeholder,
      width: width.value,
      fontSize: fontSize,
      required: required,
      dependancy:dependancy,
      options: options,
      mapField: mapField?.value,
      infoFile: infoFile,
      infoText: infoText,
    };

    // Update the form data by adding the new field to the selected section
    const updatedFormData = { ...formData };
    updatedFormData.tabs[tabIndex].sections[sectionIndex].fields.push(newField);

    // Update the state with the modified form data
    setFormData(updatedFormData);

    closeModal();
  };

  // Function to add a new option row
  const addOptionRow = () => {
    setOptions([...options, { id: options.length + 1, label: '', value: '', widget: '', key: Date.now() }]);
  };

  // Function to delete an option row
  const deleteOptionRow = (id) => {
    const updatedOptions = options.filter(option => option.id !== id);
    setOptions(updatedOptions);
  };

  // Function to update an option's label and value
  const updateOption = (id, key, value) => {
    const updatedOptions = options.map(option => {
      if (option.id === id) {
        return { ...option, [key]: value };
      }
      return option;
    });
    setOptions(updatedOptions);
  };

  const sectionOptions = formData.tabs[selectedTab]?.sections?.map(section => ({ label: section.heading, value: section.heading })) || [];

  const [types, setTypes] = useState([]);
  const [widths, setWidths] = useState([]);
  const [widgets, setWidgets] = useState([]);
  useEffect(() => {
    const typeOptions = 
    [
        {label: 'Text', value: 'text'},
        {label: 'Email', value: 'email'},
        {label: 'Mobile', value: 'mobile'},
        {label: 'Number', value: 'number'},
        {label: 'Password', value: 'password'},
        {label: 'Text Area', value: 'textarea'},
        {label: 'Dropdown', value: 'dropdown'},
        {label: 'Radio', value: 'radio'},
        {label: 'Checkbox', value: 'checkbox'},
        {label: 'File', value: 'file'},
        {label: 'Date Picker', value: 'datepicker'},
        {label: 'Heading', value: 'heading'},
        {label: 'Hidden', value: 'hidden'},
    ];
    setTypes(typeOptions);

    const widthOptions = 
    [
        {label: '4', value: '4'},
        {label: '6', value: '6'},
        {label: '12', value: '12'}
    ];

    setWidths(widthOptions);

    if (formData.widgets) {
      
      const widgetOptions = formData.widgets.map((widget, widgetIndex) => ({
        label: widget.heading,
        value: widgetIndex
      }));

      setWidgets(widgetOptions);
    }

}, [showModal, formData]); 

  const [placeholderLabel, setPlaceholderLabel] = useState('Placeholder');
  useEffect(() => {
    if (type?.value == 'hidden') {
      setPlaceholderLabel('Value');
      setRequired(false);
    } else {
      setPlaceholderLabel('Placeholder');
    }

    if (type?.value == 'heading' || type?.value == 'hidden') {
      setRequired(false);
    } else {
      setRequired(true);
    }
  }, [type]);

  return (
    <Modal show={showModal} onHide={closeModal} size="lg" style={{ marginTop: '100px' }}>
      <Modal.Header closeButton>
        <Modal.Title>Add Field</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row gy-2">
        <div className="col-xl-6">
            <label className="form-label">Label : <span className="text-danger">*</span></label>
            <input type="text" className="form-control" placeholder="Label Name" value={label} onChange={(e) => setLabel(e.target.value)} />
            {errors.label && <p style={{ color: 'red' }}>{errors.label}</p>}
          </div>
          <div className="col-xl-6">
            <label className="form-label">{placeholderLabel} : <span className="text-danger">*</span></label>
            <input type="text" className="form-control" placeholder={placeholderLabel} value={placeholder} onChange={(e) => setPlaceholder(e.target.value)} />
            {errors.placeholder && <p style={{ color: 'red' }}>{errors.placeholder}</p>}
          </div>
          <div className="col-xl-3">
            <label className="form-label">Type : <span className="text-danger">*</span></label>
            <Select
              value={type}
              onChange={setType}
              options={types}
              placeholder="Select Type"
              isSearchable={true}
              styles={selectBoxTheme}
              theme={(selectBoxTheme) => ({
                ...selectBoxTheme,
                borderRadius: 0,
              })}
            />
            {errors.type && <p style={{ color: 'red' }}>{errors.type}</p>}
          </div>
          <div className="col-xl-3">
            <label className="form-label">Width : <span className="text-danger">*</span></label>
            <Select
              value={width}
              onChange={setWidth}
              options={widths} // Add more options as needed
              placeholder="Select Width"
              isSearchable={true}
              styles={selectBoxTheme}
              theme={(selectBoxTheme) => ({
                ...selectBoxTheme,
                borderRadius: 0,
              })}
            />
            {errors.width && <p style={{ color: 'red' }}>{errors.width}</p>}
          </div>
          
          {! ['heading', 'hidden'].includes(type.value) && (
            <div className="col-xl-3">
              <label className="form-label">Required : <span className="text-danger">*</span></label>
              <br />
              <label className="radio-inline">
                <input
                  type="radio"
                  checked={required === true ? 'checked' : ''}
                  onChange={(e) => setRequired(true)}
                />&nbsp;Yes&nbsp;&nbsp;
              </label>
              <label className="radio-inline">
                <input
                  type="radio"
                  checked={required === false ? 'checked' : ''}
                  onChange={(e) => setRequired(false)}
                />&nbsp;No&nbsp;&nbsp;
              </label>
            </div>
          )}

          {['heading'].includes(type.value) && (
            <div className="col-xl-4">
              <label className="form-label">Font Size: <span className="text-danger">*</span></label>
              <input type="text" className="form-control" placeholder="Font Size" value={fontSize} onChange={(e) => setFontSize(e.target.value.trim())} />
              {errors.fontSize && <p style={{ color: 'red' }}>{errors.fontSize}</p>}
            </div>
          )}
          
          {! ['heading', 'hidden'].includes(type.value) && (
            <div className="col-xl-4">
              <label className="form-label">Company Field : <span className="text-danger">*</span></label>
              <Select
                value={mapField}
                onChange={setMapField}
                options={companyFields} // Add more options as needed
                placeholder="Select Company Field"
                isSearchable={true}
                styles={selectBoxTheme}
                theme={(selectBoxTheme) => ({
                  ...selectBoxTheme,
                  borderRadius: 0,
                })}
              />
              {errors.mapField && <p style={{ color: 'red' }}>{errors.mapField}</p>}
            </div>
          )}
          
          {['dropdown', 'radio', 'checkbox'].includes(type.value) && (
          <div className="col-xl-3">
            <label className="form-label">Dependancy : <span className="text-danger">*</span></label>
            <br />
            <label className="radio-inline">
              <input
                type="radio"
                checked={dependancy === true ? 'checked' : ''}
                onChange={(e) => setDependancy(true)}
              />&nbsp;Yes&nbsp;&nbsp;
            </label>
            <label className="radio-inline">
              <input
                type="radio"
                checked={dependancy === false ? 'checked' : ''}
                onChange={(e) => setDependancy(false)}
              />&nbsp;No&nbsp;&nbsp;
            </label>
          </div>
          )}

          {/* Options for dropdown, radio, and checkbox fields */}
          {['dropdown', 'radio', 'checkbox'].includes(type.value) && (
            <div className="col-xl-12">
              <label className="form-label">Options
              <a className="mt-2" style={{cursor: 'pointer', fontSize: '15px', marginLeft: '5px', marginTop: '5px'}} onClick={addOptionRow}>
                <i className="bi bi-plus-circle"></i>
              </a>
              </label>
              {options.map((option, optionIndex) => (
                <div className="row gy-2 mb-2" key={option.key}>
                  <div className='col-xl-1'>{optionIndex+1}</div>
                  <div className="col-xl-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Label"
                      value={option.label}
                      onChange={(e) => updateOption(option.id, 'label', e.target.value)}
                    />

                    {errors[`option-${optionIndex}`]?.label && <p style={{ color: 'red' }}>{errors[`option-${optionIndex}`].label}</p>}
                  </div>
                  <div className="col-xl-3">

                    <input
                      type="text"
                      className="form-control"
                      placeholder="Value"
                      value={option.value}
                      onChange={(e) => updateOption(option.id, 'value', e.target.value)}
                    />
                    
                    {errors[`option-${optionIndex}`]?.value && <p style={{ color: 'red' }}>{errors[`option-${optionIndex}`].value}</p>}
                  </div>

                  <div className={`col-xl-4 ${dependancy === true ? '' : 'd-none'}`}>

                    <div className="input-group" style={{flexWrap: 'nowrap'}}>
                      <Select
                        value={option.widget}
                        onChange={(selectedOption) => updateOption(option.id, 'widget', selectedOption)}
                        options={widgets} // Add more options as needed
                        placeholder="- Widget -"
                        isSearchable={true}
                        styles={selectBoxTheme}
                        theme={(selectBoxTheme) => ({
                          ...selectBoxTheme,
                          borderRadius: 0,
                        })}
                      />

                      <button className="btn btn-light ms-1" type="button" onClick={() => updateOption(option.id, 'widget', '-1')}
                          style={{height: '38px', background: '#ccc', borderRadius: '0 4px 4px 0', color: '#333' , display: option.widget !== '-1' ? 'block' : 'none'}}>
                          x
                      </button>

                    </div>
                      

                      {errors[`option-${optionIndex}`]?.widget && <p style={{ color: 'red' }}>{errors[`option-${optionIndex}`].widget}</p>}
                  </div>

                  <div className="col-xl-1">
                    <a style={{cursor: 'pointer', fontSize: '15px', color: '#ff0000'}} onClick={() => deleteOptionRow(option.id)}>
                      <i className="bi bi-trash"></i>
                    </a>
                  </div>
                </div>
              ))}
              
            </div>
          )}

          {['file'].includes(type.value) && (
            <div className="col-xl-4">
              <label className="form-label">Info:</label>
              <MyDropzone
                onChange={(val) => {
                  setInfoFile(val); setErrors((errors) => ({ ...errors, infoFile: null }));
                }} 
                onError={(error) => setErrors((errors) => ({ ...errors, infoFile: error }))}
              />

              {errors.infoFile && <p style={{ color: 'red' }}>{errors.infoFile}</p>}
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-light" onClick={closeModal}>Cancel</button>
        <button type="button" className="btn btn-primary" onClick={addField}>Submit</button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddFieldModal;
