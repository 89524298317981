import React, { useEffect,  useRef,  useState }  from 'react'



function TeamMemberCards() { 

    return (

        <>
            <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-sm-12"> 
                <div className="card custom-card team-member-card placeholder-glow">
                <div className="teammember-cover-image placeholder-glow">
                    <div className="image placeholder col-12 py-5"></div>
                    <span className="avatar avatar-xl avatar-rounded placeholder col-2"></span>
                    <a href="javascript:void(0);" className="team-member-star text-warning placeholder col-2">
                        
                    </a>
                </div>
                <div className="card-body p-0 placeholder-glow"> 
                    <div className="d-flex flex-wrap align-item-center mt-sm-0 mt-5 justify-content-between border-bottom border-block-end-dashed p-3">
                        <div className="team-member-details flex-fill placeholder-glow">
                            <p className="mb-0 fw-semibold fs-16 text-truncate placeholder col-8">
                                <a href="javascript:void(0);"></a>
                            </p>
                            <p className="mb-0 fs-12 text-muted text-break placeholder"></p>
                        </div>
                        <div className="dropdown">
                            <button className="btn btn-sm btn-icon btn-light btn-wave placeholder col-2" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="javascript:void(0);"></a></li>
                                <li><a className="dropdown-item" href="javascript:void(0);"></a></li>
                                <li><a className="dropdown-item" href="javascript:void(0);"></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="team-member-stats d-sm-flex justify-content-evenly placeholder-glow">
                        <div className="text-center p-3 my-auto">
                            <p className="fw-semibold mb-0 placeholder col-12"></p>
                            <span className="text-muted fs-12 placeholder col-12"></span>
                        </div>
                        <div className="text-center p-3 my-auto">
                            <p className="fw-semibold mb-0 placeholder col-12"></p>
                            <span className="text-muted fs-12 placeholder col-12"></span>
                        </div>
                        <div className="text-center p-3 my-auto">
                            <p className="fw-semibold mb-0 placeholder col-12"></p>
                            <span className="text-muted fs-12 placeholder col-12"></span>
                        </div>
                    </div>
                </div>
                <div className="card-footer border-block-start-dashed text-center">
                    <div className="btn-list">
                        <div className="btn-list">
                            <button className="btn btn-sm btn-icon btn-light btn-wave waves-effect waves-light placeholder col-2"></button>
                            <button className="btn btn-sm btn-icon btn-light btn-wave waves-effect waves-light placeholder col-2"></button>
                            <button className="btn btn-sm btn-icon btn-light btn-wave waves-effect waves-light placeholder col-2"></button>
                            <button className="btn btn-sm btn-icon btn-light btn-wave waves-effect waves-light placeholder col-2"></button>
                            <button className="btn btn-sm btn-icon btn-light btn-wave waves-effect waves-light placeholder col-2"></button>     
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </>
    );    

}    

export default TeamMemberCards