import { DEL, GET, POST, PUT, setAuthToken } from "../config/ApiConfig";
import { decryptData, encryptData } from "../helpers/EncryptHelper";
import { apiError } from "../helpers/ErrorHelper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dateFormat from "dateformat";
import { createFakeResponse } from "../helpers/ApiHelper";


export const createForm = async(user_token, formData) => {

    const endPoint = "/forms/add-form";
    setAuthToken(user_token);

    const response = await POST(endPoint, formData);

    return response;
}


export const editForm = async(user_token, formId, formData) => {

    const endPoint = `/forms/update-form/${formId}`;

    setAuthToken(user_token);

    const response = await PUT(endPoint, formData);

    return response;
}


export const removeForm = async(user_token, formId) => {

    const endPoint = `/forms/delete-form/${formId}`;

    setAuthToken(user_token);

    const response = await DEL(endPoint);

    return response;
}


export const activateForm = async(user_token, formId) => {

    const endPoint = `/forms/activate-form/${formId}`;

    setAuthToken(user_token);

    const response = await GET(endPoint);

    return response;
}


export const inActivateForm = async(user_token, formId) => {

    const endPoint = `/forms/inactivate-form/${formId}`;

    setAuthToken(user_token);

    const response = await GET(endPoint);

    return response;
}


export const saveFormsToStorage = (forms, pagination) => {

    let time = dateFormat('', "dd mmm yyyy HH:MM:ss");

    const dataToStore = { forms, pagination, time };

    localStorage.setItem("forms", encryptData(dataToStore));
}


export const getForms = async(param = {}) => {

    let user_token = param.user_token;

    if (!user_token) {
        return [];
    }

    let page = param.page ? param.page : 1;
    let limit = param.limit ? param.limit : 10;


    if (page === 1 && limit === 10 && localStorage.getItem('forms')) {
        const saved_forms_obj = decryptData(localStorage.getItem('forms'));

        return createFakeResponse({ status: true, data: saved_forms_obj });
    } else {
        try {
            const endPoint = '/forms/form-list';
            const url = `${endPoint}?page=${page}&limit=${limit}`;

            setAuthToken(user_token);

            const response = await GET(url);

            if (response.status) {
                if (page === 1 && limit === 10) {
                    saveFormsToStorage(response.data.forms, response.data.pagination);

                    const saved_forms_obj = decryptData(localStorage.getItem('forms'));

                    return createFakeResponse({ status: true, data: saved_forms_obj });
                }
            }

            return response;

        } catch (error) {
            return error;
        }
    }
};


export const getFormDetails = async(form_unique_id) => {

    const endPoint = '/public-fe/form-details';
    const url = `${endPoint}/${form_unique_id}`;

    const response = await GET(url);

    return response;
}

export const getFile = async(fileId) => {
    const url = `/public-fe/file/${fileId}`;

    return await GET(url);
}