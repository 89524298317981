import { checkPermission } from "../../helpers/GeneralHelper";

export default function PageHeader({module, isTrash = false, toggleTrash = null}) {

  const handleTrash = () => {
    toggleTrash(! isTrash);
  }

    return <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
      <h1 className="page-title fw-semibold fs-18 mb-0">
        {module.title} {isTrash && ' Trash'}
      </h1>
      <div className="ms-md-1 ms-0">
        {/* <nav>
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">{module.title}</li>
          </ol>
        </nav> */}

        {/* {toggleTrash && checkPermission(module.route, 'trash') && ! isTrash &&
          <button className="btn btn-outline-dark btn-sm" onClick={handleTrash}><i className="bx bx-trash"></i> Trash</button>}

        {toggleTrash && checkPermission(module.route, 'trash') && isTrash &&
          <button className="btn btn-outline-dark btn-sm" onClick={handleTrash}><i className="bx bx-reply"></i> Back</button>} */}

      </div>
    </div>
}