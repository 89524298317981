import React, { useEffect } from 'react';
import dateFormat from "dateformat";
import DataTable, { createTheme } from 'react-data-table-component';
import { useTheme } from '../../context/ThemeContext';
import { Link } from 'react-router-dom';
import WsLogList from '../skeletons/WsLogList';


const WsLogTables = ({
    data,
    setData,
    loading,
    setLoading,
    totalRows,
    setTotalRows,
    page,
    setPage,
    limit,
    setLimit,
    fetchLogs
}) => {

    const { theme } = useTheme();


    const CustomLoader = () => (
        <WsLogList/>
    );


    const handlePageChange = async (page) => {
        fetchLogs(limit, page);
    };
    
    const handleLimitChange = async (newLimit, page) => {

        if (newLimit!==10 && page!==1) {
            fetchLogs(newLimit, page); 
        }
        else if (localStorage.getItem('ws_logs')) {
            fetchLogs(newLimit, page); 
        }
    };

    useEffect(()=>{
        fetchLogs(limit, page);  
    }, []);


    const columns = [
        {
            name: "ID",
            selector: (row, index) => row.id,
            width: '100px'
        },
        {
            name: "Type",
            selector: (row, index) => row.type,
            width: '80px'
        },
        {
            name: "End Point",
            selector: (row) => row.uri
        },
        {
            name: "Method",
            selector: (row) => row.method,
            width: '90px'
        },
        {
            name: "System",
            selector: (row) =>
            {
                if (row.is_browser===0) {
                  return (
                    <>
                      {row.ip_address}<br />
                      {row.user_agent}
                    </>
                  );
                } else {
                  return (
                    <>
                      {row.ip_address}<br />
                      {row.browser} {row.browser_version}<br />
                      {row.operating_system}
                    </>
                  );
                }
              }
                                 ,
            width: '200px'                           
        },
        {
            name: "Authorized",
            selector: (row) => {
                if (row.authorized===1) {
                    return <span className="badge bg-success-transparent">Yes</span>;
                } else {
                    return <span className="badge bg-danger-transparent">No</span>;
                }
            },
            width: '120px'
        },
        {
            name: "Response Code",
            selector: (row) => row.response_code
        },
        {
            name: "Access Time",
            selector: (row) => dateFormat(row.time, "dd mmm yyyy HH:MM")
        },
        {
            name: "Response Time",
            selector: (row) => row.rtime
        },
        {
            name: "Action",
            cell: row => <div className="hstack gap-2 fs-15">
                            <Link to={`/view-ws-json/${row.unique_id}`} target='_blank' className="btn btn-icon btn-sm btn-info" title="View JSON">
                                <i className='bi bi-eye'></i>
                            </Link>
                        </div>
        }
    ];

    createTheme('dark', {
        text: {
          primary: '#a2a3a4',
          secondary: '#2aa198',
        },
        background: {
          default: '#252729',
        },
        context: {
          background: '#cb4b16',
          text: '#a2a3a4',
        },
        divider: {
          default: '#073642',
        },
        action: {
          button: 'rgba(0,0,0,.54)',
          hover: 'rgba(0,0,0,.08)',
          disabled: 'rgba(0,0,0,.12)',
        },
    });


    return (
        <DataTable 
            title={`Manage Web Service Logs`} 
            columns={columns} 
            data={data} 
            pagination 
            paginationServer 
            paginationTotalRows={totalRows} 
            onChangeRowsPerPage={handleLimitChange} 
            onChangePage={handlePageChange} 
            progressPending={loading} 
            progressComponent={<CustomLoader />}
            highlightOnHover
            theme={`${theme==='light' ? 'light' : 'dark'}`} />
    );
};    

export default WsLogTables;    