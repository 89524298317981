import React, { useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiError } from '../../../helpers/ErrorHelper';
import { useAuth } from '../../../context/AuthContext';
import { createRole, saveRolesToStorage } from '../../../models/UserModel';
import { useNotificationCount } from '../../../context/NotificationCountContext';

const AddUserRoleModal = ({showModal, setShowModal, fetchRoles}) => {

  const { notificationCount, setNotificationCount } = useNotificationCount();

  const navigate = useNavigate(); 

    const { state } = useAuth(); 

    let user_data = state.user_data;
    
    const loader = document.getElementById('loading');
    
    
    const [loading, setLoading] = useState(false);
    
    const [urole_title, setUroleTitle] = useState('');
    const [isSuperAdmin, setIsSuperAdmin] = useState(0);


    const [errors, setErrors] = useState({urole_title:  ''});

    const handleSuperAdmin = ()=>{

      if (isSuperAdmin==0) {
        setIsSuperAdmin(1);
      }
      else{
        setIsSuperAdmin(0);
      }
    }
    

    const closeModal = () =>{
        setUroleTitle('');
        setIsSuperAdmin(0);

        setErrors({});
    
        setShowModal(false);
    }
    
    
    const addRole = async() => {
        // Start the loading state
        loader.style.display = 'block';
        setLoading(true);
    
        try {
          // Clear previous errors
          setErrors({});
    
          const formData = { urole_title, isSuperAdmin };
    
          const response = await createRole(user_data.user_token, formData);
    
          if (response.status) {
            toast.success(response.message);
    
            closeModal();
    
            saveRolesToStorage(response.data.user_roles);
    
            fetchRoles(); 

            setNotificationCount(localStorage.getItem('unread_notification_count'));
          }
          else{
            setErrors(response.error);
            toast.error(response.message);
          }  
    
        } catch (error) {
          apiError(error);

          if (error.response.data.invalid_token==1) {
            navigate('/re-login');
          }
        }
    
        // Stop the loading state
        loader.style.display = 'none';
        setLoading(false);
    }   
  

  return (
    <Modal show={showModal} onHide={closeModal} size='sm'>
        <Modal.Header closeButton>
            <Modal.Title>Add Role</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <div className="row gy-2">
                <div className="col-xl-12">
                    <label className="form-label">Name <span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Role Name" 
                    value={urole_title} onChange={(e) => setUroleTitle(e.target.value)}/>

                    
                    {errors.urole_title && <p style={{ color: 'red' }}>{errors.urole_title}</p>}
                </div>
                <div className="col-xl-12">
                    <label htmlFor="note_desc" className="form-label">All Permission <span className="text-danger">*</span></label>

                    <div className="form-check form-check-lg form-switch">
                        <input className="form-check-input" type="checkbox" role="switch"
                          value={isSuperAdmin} onChange={(e) => handleSuperAdmin(e.target.value)}   />
                    </div>
                </div>
                
            </div>
        </Modal.Body>

        <Modal.Footer>
            <button type="button" className="btn btn-light" onClick={closeModal}>Cancel</button>
            <button type="button" className="btn btn-primary" onClick={addRole}>
            {loading ? (
                    <>
                    <span className="spinner-border spinner-border-sm align-middle"></span>
                    Loading...
                    </>
                ) : (     
                    'Submit'
            )}
            </button>
        </Modal.Footer>
    </Modal>
  );
};

export default AddUserRoleModal;
