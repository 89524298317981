import dateFormat from "dateformat";

export const utcToLocal = (timestamp, format = '', blanktext = '') => {
    if (timestamp) {
        if (format) {
            return dateFormat(new Date(timestamp+' UTC'), format);
        }

        return (new Date(timestamp+' UTC')).toLocaleString(['en-in'], {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit'
        });
    }

    return blanktext;
};

export const secondsToTime = (seconds) => {
    if (seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = Math.floor(seconds % 60);

        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }
    else{
        return '00:00:00';
    }
}


export const formatMonthYear = (dateString) => {
    const [month, year] = dateString.split('-');
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const formattedMonth = monthNames[parseInt(month, 10) - 1]; // Adjust for zero-based index
    
    return `${formattedMonth} ${year}`;
}  



export const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    let suffix = 'th';
    if (day === 1 || day === 21 || day === 31) {
      suffix = 'st';
    } else if (day === 2 || day === 22) {
      suffix = 'nd';
    } else if (day === 3 || day === 23) {
      suffix = 'rd';
    }
    return `${day}${suffix} ${month} ${year}`;
};


export const timeAgo = (timestamp) => {
    const currentDate = new Date();
    const pastDate = new Date(timestamp);

    const elapsed = currentDate - pastDate;

    // Calculate time differences in seconds, minutes, hours, and days
    const seconds = Math.floor(elapsed / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
        return `${days} days ago`;
    } else if (hours > 0) {
        return `${hours} hours ago`;
    } else if (minutes > 0) {
        return `${minutes} minutes ago`;
    } else {
        return `${seconds} seconds ago`;
    }
}