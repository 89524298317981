import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiError } from '../../../helpers/ErrorHelper';
import { useAuth } from '../../../context/AuthContext';
import { removeUser, saveUsersToStorage } from '../../../models/UserModel';
import { useNotificationCount } from '../../../context/NotificationCountContext';

const DeleteMemberModal = ({showModal, setShowModal, userData, fetchUsers, limit, page}) => {

  const { notificationCount, setNotificationCount } = useNotificationCount();

    const navigate = useNavigate();

    const { state } = useAuth(); 

    let user_data = state.user_data;
    
    const loader = document.getElementById('loading');

    const [loading, setLoading] = useState(false);
    
    const [user_unique_id, setUserUniqueId] = useState('');

    const closeModal = () =>{
        setUserUniqueId('');
        setShowModal(false);
    }

    useEffect(() => {
        // This effect will be called whenever userData changes
        if (userData) {
            setUserUniqueId(userData.user_unique_id);
        }
    }, [userData]);


    const deleteUser = async() => {
        // Start the loading state
        loader.style.display = 'block';
    
        try {
          const response = await removeUser(user_data.user_token, user_unique_id);
    
          if (response.status) {
            toast.success(response.message);
    
            closeModal();
    
            saveUsersToStorage(response.data.users, response.data.pagination);
    
            fetchUsers(limit, page); 

            setNotificationCount(localStorage.getItem('unread_notification_count'));
          }
          else{
            toast.error(response.message);
          }  
    
        } catch (error) {
          apiError(error);

          if (error.response.data.invalid_token==1) {
            navigate('/re-login');
          }
        }
    
        // Stop the loading state
        loader.style.display = 'none';
    }


    return (
        <Modal show={showModal} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Member</Modal.Title>
            </Modal.Header>
    
            <Modal.Body>
                <p>Do you want to delete this member ? This process can't be undone</p>
            </Modal.Body>
    
            <Modal.Footer>
                <button type="button" className="btn btn-light" onClick={closeModal}>Cancel</button>
                <button type="button" className="btn btn-danger" onClick={deleteUser}>Delete</button>
            </Modal.Footer>
        </Modal>
    ); 
};

export default DeleteMemberModal;