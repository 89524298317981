import { useTheme } from "../../context/ThemeContext"
import ReactSelect from 'react-select';
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useMemo } from "react";
import JoditEditor from "jodit-react";
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
// import TimePicker from 'rc-time-picker';
// import 'rc-time-picker/assets/index.css';

export const Text = ({fields, setFields, column, label = null, required = false, errors = {}, validator = null, rules = ''}) => {
  const validatorKey = label.replace(/ /g, "_");
    return <>
            <label htmlFor={column} className="form-label">{label}
              {required ? <span className="text-danger"> *</span> : ''}
            </label>

            <input type="text" className="form-control" id={column} placeholder={label} value={fields[column]}
              required={required} onChange={(e) => {
                setFields((prev) => ({ ...prev, [column]: e.target.value })); validator?.current.showMessageFor(validatorKey);
              }}
              // onBlur={() => validator?.current.showMessageFor(column)}
              />

            {validator?.current.message(validatorKey, fields[column], rules, { className: 'text-danger' }) ||
              errors[column] && <div className="text-danger">{errors[column]}</div>}
        </>
}

export const TextNumber = ({fields, setFields, column, label = null, required = false, errors = {}, validator = null, rules = ''}) => {
  const validatorKey = label.replace(/ /g, "_");
  const keys = ["Backspace",  "Delete", "ArrowLeft", "ArrowRight", "Tab", "-"];

    return <>
            <label htmlFor={column} className="form-label">{label}
              {required ? <span className="text-danger"> *</span> : ''}
            </label>
                
            <input type="text" className="form-control" id={column} placeholder={label} value={fields[column]} autoComplete="off"
              onKeyDown ={(e) => ! keys.includes(e.key) && !/\d/.test(e.key) && e.preventDefault()}
              required={required} onChange={(e) => {
                setFields((prev) => ({ ...prev, [column]: e.target.value })); validator?.current.showMessageFor(validatorKey);
              }} />

            {validator?.current.message(validatorKey, fields[column], rules, { className: 'text-danger' }) ||
              errors[column] && <div className="text-danger">{errors[column]}</div>}
        </>
}

export const TextFloat = ({fields, setFields, column, label, required = false, errors = {}, validator = null, rules = ''}) => {
  const validatorKey = label.replace(/ /g, "_");
  const keys = ["Backspace",  "Delete", "ArrowLeft", "ArrowRight", "Tab", "-", "."];

    return <>
            <label htmlFor={column} className="form-label">{label}
              {required ? <span className="text-danger"> *</span> : ''}
            </label>
                
            <input type="text" className="form-control" id={column} placeholder={label} value={fields[column]} autoComplete="off"
              onKeyDown ={(e) => ! keys.includes(e.key) && !/\d/.test(e.key) && e.preventDefault()}
              required={required} onChange={(e) => {
                setFields((prev) => ({ ...prev, [column]: e.target.value })); validator?.current.showMessageFor(validatorKey);
              }} />

            {validator?.current.message(validatorKey, fields[column], rules, { className: 'text-danger' }) ||
              errors[column] && <div className="text-danger">{errors[column]}</div>}
        </>
}

export const TextEmail = ({fields, setFields, column, label, required = false, errors = {}, validator = null, rules = ''}) => {
  const validatorKey = label.replace(/ /g, "_");

    return <>
            <label htmlFor={column} className="form-label">{label}
              {required ? <span className="text-danger"> *</span> : ''}
            </label>
                
            <input type="email" className="form-control" id={column} placeholder={label} value={fields[column]}
              required={required} onChange={(e) => {
                setFields((prev) => ({ ...prev, [column]: e.target.value })); validator?.current.showMessageFor(validatorKey);
              }} />

            {validator?.current.message(validatorKey, fields[column], rules, { className: 'text-danger' }) ||
              errors[column] && <div className="text-danger">{errors[column]}</div>}
        </>
}

export const TextPassword = ({isEdit = false, fields, setFields, column, label, required = false, errors = {}}) => {

  let help = '';
  if (isEdit === true) {
    required = false;
    help = "You can leave Password blank, if you don't want to change";
  }

  let toggleFlag = column + '_toggle';
  
  return <>
        <label htmlFor={column} className="form-label">{label}
          {required ? <span className="text-danger"> *</span> : ''}
        </label>

        <div className="input-group">
          <input type={(fields[toggleFlag] ?? false) ? 'text' : 'password'} className="form-control" id={column} placeholder={label}
            required={required} onChange={(e) => setFields((prev) => ({ ...prev, [column]: e.target.value }))} />
          <button type="button" className="input-btn btn btn-light" onClick={(e) => setFields((prev) => ({ ...prev, [toggleFlag]: (fields[toggleFlag] ?? false) ? false : true }))}>
            <i className={'bi bi-eye'+ ((fields[toggleFlag] ?? false) ? '-slash' : '') +' align-middle'}></i>
          </button>
        </div>

        {errors[column] && <div className="text-danger">{errors[column]}</div>}
        {help && <p><small>{help}</small></p>}
    </>
}

export const TextArea = ({fields, setFields, column, label, required = false, errors = {}}) => {
    return <>
            <label htmlFor={column} className="form-label">{label}
              {required ? <span className="text-danger"> *</span> : ''}
              
            </label>

            <textarea type="text" className="form-control" id={column} placeholder={label} required={required}
              onChange={(e) => setFields((prev) => ({ ...prev, [column]: e.target.value }))} value={fields[column]}>
            </textarea>

            {errors[column] && <div className="text-danger">{errors[column]}</div>}
        </>
}

export const MyReactSelect = ({options, fields, setFields, column, label = null, required = false, errors = {}, disabled = false, firstOption = true, quickAddKey = null, quickAddSet = null}) => {
  
  const { theme, toggleTheme, selectBoxTheme } = useTheme();

  let placeholder = '';
  let noOptionsMessage = 'Nothing found!';
  if (label) {
    noOptionsMessage = `No ${label.toLowerCase()} found!`;
  }

  if (label && firstOption === true) {
    placeholder = `Select ${label.toLowerCase()}`;
  } else if (firstOption) {
    placeholder = firstOption;
  }

  if (quickAddKey) {
    noOptionsMessage = <div>{noOptionsMessage}  <button className="btn btn-info btn-sm" type="button" onClick={() => quickAddSet((prev) => ({ ...prev, [quickAddKey]: true }) )}><i className="bx bx-plus"></i> New</button></div>
  }

  return <>
          {label && <label htmlFor={column} className="form-label">{label}
            {required ? <span className="text-danger"> *</span> : ''}
          </label>}

            <ReactSelect
              value={fields[column]}
              onChange={(e) => setFields((prev) => ({ ...prev, [column]: e }))}
              options={options}
              placeholder={placeholder}
              isClearable={true}
              isSearchable="true"
              noOptionsMessage={() => noOptionsMessage}
              styles={selectBoxTheme}
              isDisabled={disabled}
              // theme={(selectBoxTheme) => ({
              //   ...selectBoxTheme,
              //   borderRadius: 0,
              // })}
            />
          {errors[column] && <div className="text-danger">{errors[column]}</div>}
        </>
}

export const MyReactSelectMultiple = ({options, fields, setFields, column, label, required = false, errors = {}, disabled = false, validator = null, rules = '', quickAddKey = null, quickAddSet = null, firstOption = true}) => {
  
  const { theme, toggleTheme, selectBoxTheme } = useTheme();
  const validatorKey = validator ? label.replace(/ /g, "_") : '';

  let placeholder = '';
  let noOptionsMessage = 'Nothing found!';
  if (label) {
    noOptionsMessage = `No ${label.toLowerCase()} found!`;
  }

  if (label && firstOption === true) {
    placeholder = `Select ${label.toLowerCase()}`;
  } else if (firstOption) {
    placeholder = firstOption;
  }

  if (quickAddKey) {
    noOptionsMessage = <div>{noOptionsMessage}  <button className="btn btn-info btn-sm" type="button" onClick={() => quickAddSet((prev) => ({ ...prev, [quickAddKey]: true }) )}><i className="bx bx-plus"></i> New</button></div>
  }

  return <>
          <label htmlFor={column} className="form-label">{label}
            {required ? <span className="text-danger"> *</span> : ''}
          </label>

          <ReactSelect
            value={fields[column]}
            onChange={(e) => { setFields((prev) => ({ ...prev, [column]: e })); validator?.current.showMessageFor(validatorKey); }}
            options={options}
            placeholder={placeholder}
            isSearchable="true"
            noOptionsMessage={() => noOptionsMessage}
            styles={selectBoxTheme}
            isDisabled={disabled}
            isMulti={true}
            openIsOpen={true}
            // theme={(selectBoxTheme) => ({
            //   ...selectBoxTheme,
            //   borderRadius: 0,
            // })}
          />

          {validator?.current.message(validatorKey, fields[column], rules, { className: 'text-danger' }) ||
            errors[column] && <div className="text-danger">{errors[column]}</div>}
        </>
}

export const MySelect = ({options, fields, setFields, column, label = null, required = false, errors = {}, firstOption = true}) => {
  return <>
          {label &&
          <label htmlFor={column} className="form-label">{label}
            {required ? <span className="text-danger"> *</span> : ''}
          </label>}

          <select name={column} id={column} required={required} className="form-control"
            onChange={(e) => setFields((prev) => ({ ...prev, [column]: e.target.value }))} value={fields[column]}>
            {firstOption === true ? <option value="">Select {label.toLowerCase()}</option> : ''}
            {firstOption && firstOption !== true ? <option value="">{firstOption}</option> : ''}
            {options?.map(val => <option key={val.value} value={val.value}>{val.label}</option>)}
          </select>
          
          {errors[column] && <div className="text-danger">{errors[column]}</div>}
        </>
}

export const DatePicker = ({fields, setFields, column, label = null, required = false, errors = {}, pluginProps = {}}) => {
  let years = [];
  const maxYear = (new Date()).getFullYear() + 20;
  for (let year = 1950; year <= maxYear; year++) {
    years.push(year);
  }

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return <>
          <label htmlFor={column} className="form-label">{label}
            {required ? <span className="text-danger"> *</span> : ''}
          </label>

          <ReactDatePicker 
            {...pluginProps}
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
              }) => (
              <div style={{margin: 5, display: "flex", justifyContent: "center"}}>
                <button type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled} className="react-datepicker__navigation react-datepicker__navigation--previous">
                  <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">Previous</span>
                </button>
      
                <select className="date-dropdown year" value={months[date.getMonth()]} onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                  }>
                  {months.map((option) => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
                <select className="date-dropdown month" value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)}>
                  {years.map((option) => ( <option key={option} value={option}>{option}</option> ))}
                </select>
      
                <button type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled} className="react-datepicker__navigation react-datepicker__navigation--next">
                  <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">Next</span>
                </button>
              </div>
            )}
            className="form-control" placeholderText="DD-MM-YYYY" id={column} autoComplete="off" required={required}
            selected={fields[column]} onSelect={(e) => setFields((prev) => ({ ...prev, [column]: e }))}
            onChange={(e) => setFields((prev) => ({ ...prev, [column]: e }))} dateFormat="dd-MM-yyyy" />

          {errors[column] && <div className="text-danger">{errors[column]}</div>}
        </>
}

export const MyTimePicker = ({fields, setFields, column, label = null, required = false, errors = {}}) => {

  return <>
          <label htmlFor={column} className="form-label">{label}
            {required ? <span className="text-danger"> *</span> : ''}
          </label>

          {/* <TimePicker className="form-control" showSecond={false} format="hh:mm a" use12Hours={true} minuteStep={15} 
            // clearIcon={<i className="bx bxs-edit"></i>}
            value={fields[column]} onChange={(e) => setFields((prev) => ({ ...prev, [column]: e }))} /> */}

          <TimePicker className="form-control" clearIcon="" disableClock={true} format="h:m a"
            value={fields[column]} onChange={(e) => setFields((prev) => ({ ...prev, [column]: e }))} />

          {errors[column] && <div className="text-danger">{errors[column]}</div>}
        </>
}

export const Editor = ({fields, setFields, column, label = null, required = false, errors = {}, height = 300, editorRef = null}) => {

  const editorConfig = useMemo(
    () => ({
      readonly: false, 
      height: height,
      placeholder: 'Enter '+ label +'...'
    }),
    [label, height]
  );

  return <>
      <label htmlFor={column} className="form-label">{label}
        {required ? <span className="text-danger"> *</span> : ''}
      </label>

      <JoditEditor
        ref={editorRef}
        value={fields[column]}
        config={editorConfig}
        onBlur={(value) => setFields((prev) => ({ ...prev, [column]: value }))}/>

      {errors[column] && <div className="text-danger">{errors[column]}</div>}
    </>
}

export const InputFile = ({fields, setFields, column, label = null, required = false, errors = {}}) => {

  if (required && fields[column]) {
    required = false;
  }

  return <div>
          <label htmlFor={column} className="form-label">{label}
            {required ? <span className="text-danger"> *</span> : ''}
          </label>
            
          <input type="file" className="form-control" id={column} placeholder={label}
            required={required} onChange={(e) => setFields((prev) => ({ ...prev, [column]: e.target.files[0] }))} />
          
          {fields[column] && fields[column + '_file'] &&
            <div className="mt-2">
              <a href={process.env.REACT_APP_ASSETS_URL + '/' + fields[column + '_path']} target="_blank" className="text-primary">{fields[column + '_file']}</a>
              &nbsp;&nbsp;
              <a href="#" onClick={(e) => {
                  e.preventDefault();
                  setFields((prev) => ({ ...prev, [column]: '' }));
                }}>
                  <i className="bi bi-x-lg text-danger" style={{fontSize: '14px'}}></i>
              </a>
            </div>
          }

          {errors[column] && <div className="text-danger">{errors[column]}</div>}
      </div>
}
