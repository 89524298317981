import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiError } from '../../../helpers/ErrorHelper';
import { useAuth } from '../../../context/AuthContext';
import { useNotificationCount } from '../../../context/NotificationCountContext';
import { activateForm, saveFormsToStorage } from '../../../models/FormModel';


const ActivateFormModal = ({showModal, setShowModal, formData, modalKey, fetchForms, limit, page}) => {

  const { notificationCount, setNotificationCount } = useNotificationCount();

  const navigate = useNavigate();

    const { state } = useAuth(); 

    let user_data = state.user_data;
    
    const loader = document.getElementById('loading');

    const [loading, setLoading] = useState(false);
    
    const [form_unique_id, setFormUniqueID] = useState('');

    const closeModal = () =>{
        setFormUniqueID('');
        setShowModal(false);
    }

    useEffect(() => {
        // This effect will be called whenever noteData changes
        if (formData) {
            setFormUniqueID(formData.form_unique_id);
        }
    }, [formData, modalKey]);


    const doActivateForm = async() => {
        // Start the loading state
        loader.style.display = 'block';
    
        try {
          const response = await activateForm(user_data.user_token, form_unique_id);
    
          if (response.status) {
            toast.success(response.message);
    
            closeModal();
    
            saveFormsToStorage(response.data.forms, response.data.pagination);
    
            fetchForms(limit, page); 

            setNotificationCount(localStorage.getItem('unread_notification_count'));
          }
          else{
            toast.error(response.message);
          }  
    
        } catch (error) {
          apiError(error);

          if (error.response.data.invalid_token==1) {
            navigate('/re-login');
          }
        }
    
        // Stop the loading state
        loader.style.display = 'none';
    }

    return (
        <Modal show={showModal} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Activate Form</Modal.Title>
            </Modal.Header>
    
            <Modal.Body>
                <p>Do you want to activate this form ?</p>
            </Modal.Body>
    
            <Modal.Footer>
                <button type="button" className="btn btn-light" onClick={closeModal}>Cancel</button>
                <button type="button" className="btn btn-danger" onClick={doActivateForm}>Submit</button>
            </Modal.Footer>
        </Modal>
    );  

}; 


export default ActivateFormModal;