export const createFakeResponse = (param = {}) => {
    
    return {
        status: param.status ? param.status : true,
        maintenance: param.maintenance ? param.maintenance : 0,
        new_version: param.new_version ? param.new_version : 0,
        force_update: param.force_update ? param.force_update : 0,
        invalid_token: param.force_update ? param.force_update : 0,
        refresh_token: param.refresh_token ? param.refresh_token : '',
        message: param.message ? param.message : '',
        message_type:'',
        is_enc:0,
        data: param.data ? param.data : {},
        error: param.error ? param.error : {}
    };
};