import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dateFormat from "dateformat";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AdminLayout from '../layout/AdminLayout';
import { useAuth } from '../../context/AuthContext';
import { useTheme } from '../../context/ThemeContext';



function Error403() {

  const { theme, toggleTheme } = useTheme();

  const { state } = useAuth(); 

  let user_data = state.user_data;
 

  return (
    <>
    
        <AdminLayout>
        
            <Helmet>
                <title>Error 403 - Funnl Onboarding </title>
                
            </Helmet>

            <div className="main-content app-content">

                
                <div className="container-fluid">

                    {/* <!-- Page Header --> */}
                    <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                        <h1 className="page-title fw-semibold fs-18 mb-0">Error 403 </h1>
                    </div>
                    {/* <!-- Page Header Close --> */}

                    {/* <!-- Start::row-1 --> */}
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card custom-card">
                                <div className="card-body">
                                    <img src={`${process.env.REACT_APP_BASE_URL}/assets/images/403.png`} />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!--End::row-1 --> */}

                </div>


            </div>

            <Helmet>
                
            </Helmet>
        
        </AdminLayout>
    </>
  )
}

export default Error403
