import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import LoginLayout from '../layout/LoginLayout';
import config from '../../config/config';
import { useAuth } from '../../context/AuthContext';
import { apiError } from '../../helpers/ErrorHelper';
import { userResetPassword } from '../../models/UserModel';



function ResetPassword() {


    const { state, dispatch } = useAuth(); 

    const navigate = useNavigate();

    const loader = document.getElementById('loading'); 
    
  //Reset Password Start  
  const { token } = useParams(); 

  const [new_password, setNewPassword] = useState('');
  const [confirm_password, setConfirmPassword] = useState('');

  const [errors, setErrors] = useState({new_password:  '', confirm_password: ''});
  const [loading, setLoading] = useState(false); 

  const doResetPassword = async() => {
    // Start the loading state
    loader.style.display = 'block';
    setLoading(true);

    try {
        // Clear previous errors
        setErrors({});

        const formData = { token, new_password, confirm_password };
    
        const response = await userResetPassword(formData);

        if (response.status) {
            toast.success(response.message);

            setNewPassword('');
            setConfirmPassword('');

            navigate(`/login`);
        }
        else{
            setErrors(response.error);
            toast.error(response.message);
        }
        
    } catch (error) {
        apiError(error);
    }

    // Stop the loading state
    loader.style.display = 'none';
    setLoading(false);

  }  
  //Reset Password End  
    
  return (
    <>
    
        <LoginLayout>
        
            <Helmet>
                <title>Reset Password - Funnl Onboarding</title>
            </Helmet>

            <div className="container">
                <div className="row justify-content-center align-items-center authentication authentication-basic h-100">
                    <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-6 col-sm-8 col-12">
                        <div className="my-5 d-flex justify-content-center">
                            <Link to={process.env.REACT_APP_WEBSITE_URL} target='_blank'>
                                <img src={`${process.env.REACT_APP_BASE_URL}/assets/images/logo-header.png`} alt="logo" className="desktop-logo" />
                                <img src={`${process.env.REACT_APP_BASE_URL}/assets/images/logo-header.png`} alt="logo" className="desktop-dark" />
                            </Link>
                        </div>
                        <div className="card custom-card">
                            <div className="card-body p-5">
                                <p className="h5 fw-semibold mb-2 text-center">Reset Password</p>
                                <div className="row gy-3">
                                    <div className="col-xl-12 mb-2">
                                        <label className="form-label text-default d-block">New Password <span className="text-danger">*</span></label>
                                        <div className="input-group">
                                            <input type='password' className="form-control form-control-lg" placeholder="password" 
                                            value={new_password} onChange={(e) => setNewPassword(e.target.value)} />
                                        </div>

                                        <p id='user_password_error'>
                                            {errors.new_password && <p style={{ color: 'red' }}>{errors.new_password}</p>}
                                        </p>
                                    </div>

                                    <div className="col-xl-12 mb-2">
                                        <label className="form-label text-default d-block">Confirm Password <span className="text-danger">*</span></label>
                                        <div className="input-group">
                                            <input type='password' className="form-control form-control-lg" placeholder="password" 
                                            value={confirm_password} onChange={(e) => setConfirmPassword(e.target.value)} />
                                        </div>

                                        <p id='user_password_error'>
                                            {errors.confirm_password && <p style={{ color: 'red' }}>{errors.confirm_password}</p>}
                                        </p>
                                    </div>

                                    <div className="col-xl-12 mb-2">
                                        <label htmlFor="signin-password" className="form-label text-default d-block">
                                            <Link to={`${config.adminDir}/login`} className="float-end text-danger">Back to Login</Link>
                                        </label>
                                        
                                    </div>
                                    <div className="col-xl-12 d-grid mt-2">
                                        <button type='button' className="btn btn-lg btn-primary" onClick={doResetPassword}>
                                            {loading ? (
                                                <>
                                                <span className="spinner-border spinner-border-sm align-middle"></span>
                                                Loading...
                                                </>
                                            ) : (     
                                                'Submit'
                                            )}
                                        </button>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Helmet>
                
            </Helmet>
        
        </LoginLayout>
    </>
  )
}

export default ResetPassword
