import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './context/AuthContext';
import { ThemeProvider } from './context/ThemeContext';
import { SideMenuProvider } from './context/SideMenuContext';
import { SubMenuProvider } from './context/SubMenuContext';
import { NotificationCountProvider } from './context/NotificationCountContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <NotificationCountProvider>
    <SubMenuProvider>

    <ThemeProvider>
      <SideMenuProvider>
    <AuthProvider>
      <App />
    </AuthProvider>
    </SideMenuProvider>
    </ThemeProvider>

    </SubMenuProvider>
    </NotificationCountProvider>
  </React.StrictMode>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
