import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AdminLayout from '../layout/AdminLayout';
import { useAuth } from '../../context/AuthContext';
import { getTeamWithMembers, getUsers } from '../../models/UserModel';
import { profilePic } from '../../helpers/GeneralHelper';
import AddNewMemberModal from '../modals/Users/AddNewMemberModal';
import DeleteMemberModal from '../modals/Users/DeleteMemberModal';
import TeamMemberCards from '../skeletons/TeamMemberCards';
import { useNotificationCount } from '../../context/NotificationCountContext';



function TeamMembers() {

  const { state } = useAuth(); 

  let user_data = state.user_data;  

  const { notificationCount, setNotificationCount } = useNotificationCount();

  const navigate = useNavigate();  

  const currentRoute = useLocation().pathname;  
  
  const loader = document.getElementById('loading'); 


  //Add User Start
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  //Add User End

  //Delete User Start
  const [userData, setUserData] = useState({});
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);

  const openDeleteUserModal = (user_data) =>{
    setUserData(user_data);
    setShowDeleteUserModal(true);
  } 
  //Delete User End

  //Flush Users and Teams Start
  const flushUserAndTeam = async() => {
    localStorage.removeItem("users");
    fetchUsers();
  }  
  //Flush Users and Teams End
 
  // Get Users Start
  const [users, setUsers] = useState([]);
  const [cacheTime, setCacheTime] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [prevPage, setPrevPage] = useState('');
  const [nextPage, setNextPage] = useState('');

  const fetchUsers = async (param = {}) => {
    setLoading(true);
    try {
        let page  = param.page ? param.page : 1;
        let limit = param.limit ? param.limit : 10;
  
        let apiParam = {page: page, limit: limit, user_token: user_data.user_token};
        const response = await getUsers(apiParam);

        if (response.status) {
            if (response.data.users) {
                setUsers(response.data.users);
            }

            if (response.data.pagination.total_pages) {
                setTotalPages(response.data.pagination.total_pages);
            }
            
            if (response.data.pagination.current_page) {
                setPage(response.data.pagination.current_page);
            }

            if (response.data.pagination.total_rows) {
                setTotalRows(response.data.pagination.total_rows);
            }

            if (response.data.pagination.prev_page) {
                setPrevPage(response.data.pagination.prev_page);
            }

            if (response.data.pagination.next_page) {
                setPrevPage(response.data.pagination.next_page);
            }

            if (response.data.time) {
                setCacheTime(response.data.time);
            }

            setNotificationCount(localStorage.getItem('unread_notification_count'));

            setLoading(false);
        }
        else{
            if (response.response.data.invalid_token=='1') {
                navigate(`/re-login?redirect=${currentRoute}`);
            }
        }
        
    } catch (error) {
        toast.error(error);
    }

    
  };
  // Get Users End


  
  useEffect(() => {  
    fetchUsers();
  }, []);
    
  return (
    <>
    
        <AdminLayout>
        
            <Helmet>
                <title>Team Members - Funnl Onboarding</title>
            </Helmet>

            <div className="main-content app-content">

                
                <div className="container-fluid">

                    {/* <!-- ROW OPEN --> */}
                    <div className="row mt-4">
                        <div className="col-xl-12">
                            <div className="team-members" id="team-members">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="card custom-card">
                                            <div className="card-body">
                                                 

                                                <div className="d-flex align-items-center justify-content-between flex-wrap gap-3">
                                        
                                                    <div className="card-title me-auto">
                                                        Last Synced On {cacheTime}
                                                    </div>

                                                    <button className="btn btn-primary btn-sm me-1" onClick={() => setShowAddUserModal(true)}>
                                                            <i className="ri-add-line me-1 fw-semibold align-middle"></i>New Member
                                                    </button>

                                                    <button className="btn btn-success-light btn-sm" onClick={() => flushUserAndTeam()}>
                                                        <i className="ri-refresh-line me-1 fw-semibold align-middle"></i>Refresh
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    {/* <!--Add User Modal Start--> */}
                                    <AddNewMemberModal
                                        showModal={showAddUserModal}
                                        setShowModal={setShowAddUserModal}
                                        fetchUsers={fetchUsers}
                                        limit={limit}
                                        page={page} />
                                    {/* <!--Add User Modal End--> */}


                                    {/* <!--Delete User Modal Start--> */}
                                    <DeleteMemberModal
                                        showModal={showDeleteUserModal}
                                        setShowModal={setShowDeleteUserModal}
                                        userData={userData}
                                        fetchUsers={fetchUsers}
                                        limit={limit}
                                        page={page}  />
                                    {/* <!--Delete User Modal End--> */}

                                    {/* <!-- User Card List Row Start --> */}
                                    <div className="row" style={{minHeight: '500px'}}>
                                    {loading ? (
                                        [1,2,3,4,5,6,7,8,9].map((n) => (
                                            <TeamMemberCards />
                                        ))
                                    ) : (  
                                    users.map((user) => (
                                        <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-sm-12"> 
                                            <div className="card custom-card team-member-card">
                                                <div className="teammember-cover-image">
                                                    <img src="assets/images/media/team-covers/1.jpg" className="card-img-top" alt="..." />
                                                    <span className="avatar avatar-xl avatar-rounded">
                                                        {profilePic(user.user_first_name, user.user_last_name, user.user_image, '67px', '25px')}
                                                    </span>
                                                </div>
                                                <div className="card-body p-0"> 
                                                    <div className="d-flex flex-wrap align-item-center mt-sm-0 mt-5 justify-content-between border-bottom border-block-end-dashed p-3">
                                                        <div className="team-member-details flex-fill" style={{maxWidth:"20rem"}}>
                                                            <p className="mb-0 fw-semibold fs-16 text-truncate">
                                                                <Link to={`/profile/${user.user_unique_id}`}>{user.user_first_name} {user.user_last_name}</Link>
                                                            </p>
                                                            <p className="mb-0 fs-12 text-muted text-break">
                                                                <Link to={`mailto:${user.user_email}`}>
                                                                    {user.user_email}
                                                                </Link>
                                                            </p>
                                                        </div>
                                                        <div className="dropdown">
                                                            <button className="btn btn-sm btn-icon btn-light btn-wave" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className="ti ti-dots-vertical"></i>
                                                            </button>
                                                            <ul className="dropdown-menu">
                                                                <li><Link className="dropdown-item" to={`/profile/${user.user_unique_id}`}>Edit</Link></li>

                                                                {user_data.user_unique_id!=user.user_unique_id && user_data.urole_permission=='*' ? (
                                                                <li>
                                                                    <button className="dropdown-item" onClick={() => openDeleteUserModal(user)}>Delete</button>
                                                                </li>
                                                                ):('')}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="team-member-stats d-sm-flex justify-content-evenly my-2">
                                                        <div className="text-center p-1" style={{flex:"0 0 50%", maxWidth:"50%"}}>
                                                            <p className="fw-semibold mb-0">Member Since</p>
                                                            <span className="text-muted fs-12">{user.member_since}</span>
                                                        </div>
                                                        
                                                        <div className="text-center p-1 text-truncate" style={{flex:"0 0 50%", maxWidth:"50%"}}>
                                                            <p className="fw-semibold mb-0">Position</p>
                                                            <span className="text-muted fs-12" style={{maxWidth:"4rem", overflow:"hidden", textOverflow:"ellipsis",whiteSpace:"nowrap"}}>{user.user_job_title}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    ))
                                    )}
                                    </div>
                                    {/* <!-- User Card List Row End --> */}

                                    
                                    
                                    {/* <!---Pagination Start---> */}
                                    {users ? (
                                    <nav aria-label="...">
                                        <ul className="pagination justify-content-end">
                                        <li className={`page-item ${prevPage==='' ? 'disabled' : ''}`}>
                                            <a className="page-link" href="javascript:void(0);" onClick={() => fetchUsers({page:Number(prevPage)})}>Previous</a>
                                        </li>

                                        {Array.from({ length: totalPages }).map((_, index) => (
                                        <li key={index} className={`page-item ${page===Number(index+1) ? 'active' : ''}`} title={`Page ${index+1}`}>
                                            <a className="page-link" href="javascript:void(0);" onClick={() => fetchUsers({page:Number(index + 1)})}>{index + 1}</a>
                                        </li>
                                        ))}

                                        <li className={`page-item ${nextPage==='' ? 'disabled' : ''}`}>
                                            <a className="page-link" href="javascript:void(0);" onClick={() => fetchUsers({page:Number(nextPage)})}>Next</a>
                                        </li>
                                        </ul>
                                    </nav>
                                    ) : ('' )}
                                    {/* <!---Pagination End---> */}

                                </div>
                            </div>
                        </div>
                        
                    </div>
                    {/* <!-- ROW CLOSE --> */}


                </div>


            </div> 

            <Helmet>
                
            </Helmet>
        
        </AdminLayout>
    </>
  )
}

export default TeamMembers
