import React, { createContext, useState, useContext, useEffect } from 'react';
import { decryptData } from '../helpers/EncryptHelper';

const SubMenuContext = createContext();

export const SubMenuProvider = ({ children }) => {
  // Initialize Sub Menu state with an empty value
  const [subMenuOpen, setSubMenuOpen] = useState(null);

  return (
    <SubMenuContext.Provider value={{ subMenuOpen, setSubMenuOpen }}>
      {children}
    </SubMenuContext.Provider>
  );
};

export const useSubMenu = () => useContext(SubMenuContext);
