import React, { useEffect, useState, Suspense   } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AdminLayout from '../layout/AdminLayout';
import { useAuth } from '../../context/AuthContext';
import { useTheme } from '../../context/ThemeContext';
import { useNotificationCount } from '../../context/NotificationCountContext';


function Dashboard() {

    const currentRoute =  useLocation().pathname;  

    const navigate = useNavigate(); 

    const { notificationCount, setNotificationCount } = useNotificationCount();

    const { state } = useAuth(); 

    let user_data = state.user_data;  

    const [greeting, setGreeting] = useState('');

    useEffect(() => {
        const hour = new Date().getHours();
        let newGreeting = '';
    
        if (hour >= 5 && hour < 12) {
          newGreeting = 'Good Morning '+user_data.user_first_name;
        } else if (hour >= 12 && hour < 17) {
          newGreeting = 'Good Afternoon '+user_data.user_first_name;
        } else {
          newGreeting = 'Good Evening '+user_data.user_first_name;
        }
    
        setGreeting(newGreeting);
      }, []);

    

    const { theme, toggleTheme, selectBoxTheme } = useTheme();





  return (
    <>
    
        <AdminLayout>
        
            <Helmet>
                <title>Dashboard - Funnl Onboarding</title>
            </Helmet>

            <div className="main-content app-content">

                <div className="container-fluid">

                    {/* <!-- Page Header --> */}
                    <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                        <h1 className="page-title fw-semibold fs-18 mb-0">Dashboard</h1>
                        <h6 >{greeting}</h6>
                        <div className="ms-md-1 ms-0">
                            <nav>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item active"><a href="javascript:void(0);">Dashboard</a></li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    {/* <!-- Page Header Close --> */}




                </div>

            </div> 

            <Helmet>
                
            </Helmet>
        
        </AdminLayout>
    </>
  )
}

export default Dashboard
