import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import AdminLayout from '../../layout/AdminLayout';
import { useTheme } from '../../../context/ThemeContext';
import { useAuth } from '../../../context/AuthContext';
import { useNotificationCount } from '../../../context/NotificationCountContext';
import config from '../../../config/config';
import { getInteractions } from '../../../models/InteractionModel';
import InteractionTables from '../../tables/InteractionTables';
import DeleteInteractionModal from '../../modals/Interactions/DeleteInteractionModal';
import { getForms } from '../../../models/FormModel';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { useDebouncedCallback  } from 'use-debounce';

function Interactions() {

  const { notificationCount, setNotificationCount } = useNotificationCount();

  const { theme, toggleTheme, selectBoxTheme } = useTheme();

  const { state } = useAuth(); 

  let user_data = state.user_data;

  const navigate = useNavigate();  

  const currentRoute = useLocation().pathname;  

  const loader = document.getElementById('loading');


  const [modalKey, setModalKey] = useState(0);

  const [interactionData, setInteractionData] = useState({});

  //Flush Interactions Start
  const flushInteractions = async() => {
    localStorage.removeItem("interactions");
    fetchInteractions(limit, page); 
  }  
  //Flush Interactions End

  //Delete Interaction Start
  const [showDeleteInteractionModal, setShowDeleteInteractionModal] = useState(false);

  const openDeleteInteractionModal = (interaction_data) =>{
    setInteractionData(interaction_data);
    setModalKey(prevKey => prevKey + 1); 
    setShowDeleteInteractionModal(true);
  } 
  //Delete Interaction End


  //Interactions Start
  const [data, setData] = useState([]);
  const [cacheTime, setCacheTime] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);


  const fetchInteractions = async(newLimit, page) => {
    setLoading(true);

    try {        
        let apiParam = {page: page, limit: newLimit, user_token: user_data.user_token, _form_id : _form_id.value ? _form_id.value : '', 
        interaction_is_completed: interaction_is_completed.value ? interaction_is_completed.value : '', search};

        //console.log(apiParam);

        const response = await getInteractions(apiParam);

        if (response.status) {

          if (response.data.interactions) {
            setData(response.data.interactions);
          }

          if (response.data.pagination.total_rows) {
            setTotalRows(response.data.pagination.total_rows);
          }

          if (response.data.time) {
            setCacheTime(response.data.time);
          }

          setLimit(newLimit);
          setPage(page);

        }
        else{
          if (response.response.data.invalid_token=='1') {
              toast.error(response.response.data.message);
              navigate(`${config.adminDir}/re-login?redirect=${currentRoute}`);
          }
        }   
        
        setLoading(false);
    } catch (error) {
      navigate(`${config.adminDir}/re-login?redirect=${currentRoute}`);
    }

    
  };
  //Interactions End


  //Forms Start
  const [_form_id, setFormId] = useState('');
  const [forms, setForms] = useState([]);

  const fetchForms = async() => {
    
    try {        
        let apiParam = {page: 1, limit: 100, user_token: user_data.user_token};

        const response = await getForms(apiParam);

        

      if (response.status) {
        //console.log(response.data.forms);

        if (response.data.forms) {
          const formOptions = response.data.forms.map(form => ({
            label: form.form_title,
            value: form._form_id
          }));

          setForms(formOptions);
        }

      }
      else{
        if (response.response.data.invalid_token=='1') {
            toast.error(response.response.data.message);
            navigate(`${config.adminDir}/re-login?redirect=${currentRoute}`);
        }
      }   
        
        
    } catch (error) {
      navigate(`${config.adminDir}/re-login?redirect=${currentRoute}`);
    }
    
  };

    
  //Forms End

  const [interaction_is_completed, setInteractionStatus] = useState('');
  const [interactionStatusList, setInteractionStatusList] = useState([]);

  const [search, setSearch] = useState('');

  useEffect(() => {
    fetchForms();

    const statusOptions = 
    [
        {label: 'Not Completed', value: '0'},
        {label: 'Completed', value: '1'}
    ];

    setInteractionStatusList(statusOptions);

  }, [_form_id, interaction_is_completed, search]); 

  const [dateRange, setDateRange] = useState([null, null]);
  const [date_from, date_to] = dateRange;
  
  // const clearSearch = () =>{
  //   setSearch('');
  //   fetchInteractions(limit, page);
  // }

  const debounced = useDebouncedCallback(
    // function
    (value) => {
      fetchInteractions(limit, page);
    },
    // delay in ms
    500
  );
    
  return (
    <>
        <AdminLayout>
        
            <Helmet>
                <title>Interactions - Funnl Onboarding </title>
            </Helmet>

            <div className="main-content app-content">

                
                <div className="container-fluid">

                    {/* <!-- Page Header --> */}
                    <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                        <h1 className="page-title fw-semibold fs-18 mb-0">Interactions</h1>
                        <div className="ms-md-1 ms-0">
                            <nav>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><a href="javascript:void(0);">Dashboard</a></li>
                                    <li className="breadcrumb-item">Logs</li>
                                    <li className="breadcrumb-item active" aria-current="page">Interactions</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    {/* <!-- Page Header Close --> */}

                    {/* <!-- Start::row-1 --> */}
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card custom-card">
                                <div className="card-header d-flex align-items-center justify-content-between flex-wrap gap-3">
                                    <div className="card-title">
                                      Last Synced On {cacheTime}
                                    </div>

                                    <div className="d-flex flex-wrap gap-2">
                                        <button className="btn btn-success-light btn-sm" onClick={() => flushInteractions()}>
                                            <i className="ri-refresh-line me-1 fw-semibold align-middle"></i>Refresh
                                        </button>
                                    </div>
                                </div>

                                <div className="card-header">
                                    
                                    <div className="w-100">

                                      <div className='row'>

                                        <div className='col-lg-3'>
                                          <div className="input-group">
                                              <Select
                                                  value={_form_id}
                                                  onChange={setFormId}
                                                  options={forms}
                                                  placeholder="- Select Form -"
                                                  isSearchable="true"
                                                  noOptionsMessage={() => "No form found"}
                                                  styles={selectBoxTheme}
                                                  theme={(selectBoxTheme) => ({
                                                  ...selectBoxTheme,
                                                  borderRadius: 0
                                                  })} /> 
                                                  <button className="btn btn-light" type="button" onClick={() => setFormId('')}
                                                      style={{height: '38px', background: '#ccc', borderRadius: '0 4px 4px 0', color: '#333' , display: _form_id !== '' ? 'block' : 'none'}}>
                                                      x
                                                  </button>
                                          </div>
                                        </div>

                                        <div className='col-lg-3'>
                                          <div className="input-group">
                                              <Select
                                                  value={interaction_is_completed}
                                                  onChange={setInteractionStatus}
                                                  options={interactionStatusList}
                                                  placeholder="- Select Status -"
                                                  isSearchable="true"
                                                  noOptionsMessage={() => "No status found"}
                                                  styles={selectBoxTheme}
                                                  theme={(selectBoxTheme) => ({
                                                  ...selectBoxTheme,
                                                  borderRadius: 0
                                                  })} /> 
                                                  <button className="btn btn-light" type="button" onClick={() => setInteractionStatus('')}
                                                      style={{height: '38px', background: '#ccc', borderRadius: '0 4px 4px 0', color: '#333' , display: interaction_is_completed !== '' ? 'block' : 'none'}}>
                                                      x
                                                  </button>
                                          </div>
                                        </div>

                                        <div className='col-lg-3'>
                                          <DatePicker
                                              className="form-control"
                                              selectsRange={true}
                                              startDate={date_from}
                                              endDate={date_to}
                                              onChange={(update) => {setDateRange(update);}}
                                              isClearable={true}
                                              dateFormat="dd-MM-yyyy"
                                              placeholderText="From Date - To Date"
                                              maxDate={new Date()} />
                                        </div>

                                        <div className='col-lg-3'>
                                          <div className="input-group">
                                            <input className='form-control' type='text' placeholder='Search by name, email, mobile...' value={search} onChange={(e) => { setSearch(e.target.value); debounced(e.target.value) }} style={{height: '38px'}} />
                                            <button className={`btn btn-light ${search!='' ? 'd-none' : ''}`} type="button" onClick={() => fetchInteractions(limit, page)} style={{height: '38px'}}>
                                              <i className="bi bi-search"></i>
                                            </button>

                                            <button className={`btn btn-light ${search=='' ? 'd-none' : ''}`} type="button" onClick={() => { setSearch(''); debounced('') }}
                                                style={{height: '38px', background: '#ccc', borderRadius: '0 4px 4px 0', color: '#333'}}>
                                                x
                                            </button>
                                          </div>
                                          
                                        </div>

                                      </div>

                                       
                                    </div>
                                </div>


                                <div className="card-body">
                                    <div className="table-responsive">
                                      <InteractionTables 
                                          data={data}
                                          setData={setData}
                                          loading={loading}
                                          setLoading={setLoading}
                                          totalRows={totalRows}
                                          setTotalRows={setTotalRows}
                                          page={page}
                                          setPage={setPage}
                                          limit={limit}
                                          setLimit={setLimit}
                                          fetchInteractions={fetchInteractions}
                                          openDeleteInteractionModal={openDeleteInteractionModal}
                                          _form_id={_form_id}
                                          interaction_is_completed={interaction_is_completed}
                                          search={search} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!--End::row-1 --> */}


                    {/* <!--Delete Interaction Modal Start--> */}
                    <DeleteInteractionModal
                    showModal={showDeleteInteractionModal}
                    setShowModal={setShowDeleteInteractionModal}
                    interactionData={interactionData}
                    modalKey={modalKey}
                    fetchInteractions={fetchInteractions}
                    limit={limit}
                    page={page}  />
                    {/* <!--Delete Interaction Modal End--> */}


                </div>


            </div>

            <Helmet>
                
            </Helmet>
        
        </AdminLayout>
        
    </>
  )
}

export default Interactions
