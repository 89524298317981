import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import LoginLayout from '../layout/LoginLayout';
import { apiError } from '../../helpers/ErrorHelper';
import { useAuth } from '../../context/AuthContext';
import { clearStorageData, getUserCredentialsFromStorage, saveUserDataToStorage, userReLogin } from '../../models/UserModel';
import { profilePic } from '../../helpers/GeneralHelper';
import config from '../../config/config';


function ReLogin() {

    const { state, dispatch } = useAuth(); 

    const [user_data, setUserData] = useState({}); 

    const location = useLocation();

    const navigate = useNavigate();

    const [redirectURL, setRedirectURL] = useState('');


    useEffect(()=>{
        const searchParams = new URLSearchParams(location.search);

        // Getting a specific query parameter by name
        if (searchParams.get('redirect')) {
            setRedirectURL(searchParams.get('redirect'));
        }


        if (!state.isAuthenticated) {
            navigate(`${config.adminDir}/login`);
        }
        else{
            setUserData(state.user_data);
        }
           
    }, [state, navigate]);

    

    const loader = document.getElementById('loading');


    const [showPass, setShowPass] = useState('hide');

    const toggleShowPass = () => {
        if (showPass==='show') {
            setShowPass('hide');
        }
        else{
            setShowPass('show');
        }
    }

    //ReLogin Start
    const [user_password, setUserPassword] = useState('');
    const [errors, setErrors] = useState({user_email:  '',  user_password: '',});
    const [loading, setLoading] = useState(false); 

    const doReLogin = async() => {
        // Start the loading state
        loader.style.display = 'block';
        setLoading(true);

        try {
             // Clear previous errors
          setErrors({});
    
          const formData = { user_password };
    
          const response = await userReLogin(user_data.user_token, formData);

          if (response.status) {
            toast.success(response.message);
    
            const user_data = response.data.user_data;

            saveUserDataToStorage(user_data);

            dispatch({ type: 'LOGIN', payload: {user_data} });

            if (redirectURL) {
                navigate(redirectURL);
            }
            else{
                navigate(`${config.adminDir}/dashboard`);
            }
          }
          else{
            setErrors(response.error);
            toast.error(response.message);
          }
            
        } catch (error) {
            apiError(error);

            if (error.response.data.invalid_token==1) {
                localStorage.clear();
    
                dispatch({ type: 'LOGOUT', payload: {} });
                navigate(`${config.adminDir}/login`);
            }
        }

        // Stop the loading state
        loader.style.display = 'none';
        setLoading(false);

    }    
    //Login End

    const goToLogin = () => {
        clearStorageData();
    
        dispatch({ type: 'LOGOUT', payload: {} });
        navigate(`${config.adminDir}/login`);
    }

    useEffect(()=>{
        const credentials = getUserCredentialsFromStorage();
        
        setUserPassword(credentials.user_password);
    }, []);
    
  return (
    <>
    
        <LoginLayout>
        
            <Helmet>
                <title>Re-Login - Funnl Onboarding</title>
            </Helmet>

            <div className="container-lg">
            <div className="row justify-content-center authentication authentication-basic align-items-center h-100">
                <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-6 col-sm-8 col-12">
                    <div className="my-5 d-flex justify-content-center">
                        <Link to={process.env.REACT_APP_WEBSITE_URL}>
                            <img src={`${process.env.REACT_APP_BASE_URL}/assets/images/logo-wide.png`} alt="logo" className="desktop-logo" />
                            <img src={`${process.env.REACT_APP_BASE_URL}/assets/images/logo-wide.png`} alt="logo" className="desktop-dark" />
                        </Link>
                    </div>
                    <div className="card custom-card">
                        <div className="card-body p-5">
                            <p className="h5 fw-semibold mb-2 text-center">Unlock to Continue !</p>
                            <p className="mb-3 text-muted op-7 fw-normal text-center">Hello {user_data.user_first_name} !</p>
                            <div className="d-flex align-items-center mb-3">
                                <div className="lh-1">
                                    <span className="avatar avatar-md avatar-rounded">
                                        {profilePic(user_data.user_first_name, user_data.user_last_name, user_data.user_image, '32px', '12px')}
                                    </span>
                                </div>
                                <div className="ms-3">
                                    <p className="mb-0 fw-semibold text-dark">{user_data.user_email}</p>
                                </div>
                            </div>
                            <div className="row gy-3">
                                <div className="col-xl-12 mb-2">
                                        <label htmlFor="signin-password" className="form-label text-default d-block">Password <span className="text-danger">*</span><Link to="javascript:void(0);" className="float-end text-danger" onClick={goToLogin}>Not You ?</Link></label>
                                        <div className="input-group">
                                            <input type={showPass==='show' ? 'text' : 'password'} className="form-control form-control-lg" id="user_password" placeholder="password" value={user_password} onChange={(e) => setUserPassword(e.target.value)} />
                                            <button className="btn btn-light" type="button" onClick={toggleShowPass} title={showPass==='show' ? 'Hide Password' : 'Show Password'}>
                                                <i className={`bi bi-${showPass==='show' ? 'eye-slash' : 'eye'} align-middle`}></i>
                                            </button>
                                        </div>

                                        <p id='user_password_error'>
                                            {errors.user_password && <p style={{ color: 'red' }}>{errors.user_password}</p>}
                                        </p>
                                        
                                    </div>
                                <div className="col-xl-12 d-grid mt-2">
                                    <button className="btn btn-lg btn-primary" onClick={doReLogin}>
                                    {loading ? (
                                                <>
                                                <span className="spinner-border spinner-border-sm align-middle"></span>
                                                Loading...
                                                </>
                                            ) : (     
                                                'Submit'
                                            )}
                                    </button>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>

            <Helmet>

            </Helmet>
        
        </LoginLayout>
    </>
  )
}

export default ReLogin
