// AdminLayout.js
import React from 'react';
import { Helmet } from 'react-helmet';
import RightSwitcher from '../common/RightSwitcher';
import Header from '../common/Header';
import LeftSideBar from '../common/LeftSideBar';
import Footer from '../common/Footer';

const AdminLayout = ({ children }) => {
  return (
    <div>
      <Helmet>
      
        <link id="style" href={`${process.env.REACT_APP_BASE_URL}/assets/libs/bootstrap/css/bootstrap.min.css`} rel="stylesheet" />

        <link href={`${process.env.REACT_APP_BASE_URL}/assets/css/icons.css`} rel="stylesheet" />

        <link href={`${process.env.REACT_APP_BASE_URL}/assets/css/styles.css`} rel="stylesheet" />

        <link href={`${process.env.REACT_APP_BASE_URL}/assets/css/custom.css`} rel="stylesheet" />

        <script src={`${process.env.REACT_APP_BASE_URL}/assets/js/main.js`}></script>

        <link href={`${process.env.REACT_APP_BASE_URL}/assets/libs/node-waves/waves.min.css`} rel="stylesheet" /> 

        <link href={`${process.env.REACT_APP_BASE_URL}/assets/libs/simplebar/simplebar.min.css`} rel="stylesheet" />

        <link href={`${process.env.REACT_APP_BASE_URL}/assets/libs/flatpickr/flatpickr.min.css`}  rel="stylesheet"/>
        <link href={`${process.env.REACT_APP_BASE_URL}/assets/libs/%40simonwep/pickr/themes/nano.min.css`} rel="stylesheet" />

        <link href={`${process.env.REACT_APP_BASE_URL}/assets/libs/choices.js/public/assets/styles/choices.min.css`} rel="stylesheet" />

        <script src={`${process.env.REACT_APP_BASE_URL}/assets/libs/choices.js/public/assets/scripts/choices.min.js`}></script>
      </Helmet>
      
      <RightSwitcher/>

        {/* <!-- LOADER --> */}
		<div id="loader">
			<img src={`${process.env.REACT_APP_BASE_URL}/assets/images/media/loader.svg`} alt="" />
		</div>
		{/* <!-- END LOADER --> */}

        {/* <!-- PAGE --> */}
		<div className="page">

            <Header />

            <LeftSideBar />

            {/* Render the page content */}
            {children}

        </div>
        {/* <!-- END PAGE--> */}

      

        <Footer/>

      <Helmet>
        <script src={`${process.env.REACT_APP_BASE_URL}/assets/libs/%40popperjs/core/umd/popper.min.js`}></script>

        <script src={`${process.env.REACT_APP_BASE_URL}/assets/libs/bootstrap/js/bootstrap.bundle.min.js`}></script>

        <script src={`${process.env.REACT_APP_BASE_URL}/assets/libs/node-waves/waves.min.js`}></script>

        <script src={`${process.env.REACT_APP_BASE_URL}/assets/libs/%40simonwep/pickr/pickr.es5.min.js`}></script>

        {/* <script src={`${process.env.REACT_APP_BASE_URL}/assets/js/sticky.js`}></script> */}
      </Helmet>
    </div>
  );
};

export default AdminLayout;
