import React, { useEffect,  useRef,  useState }  from 'react'

function ProfileTabs() { 

    return (
        <>
            <div className="row">
                <div className="col-xxl-4 col-xl-12">
                    <div className="card custom-card overflow-hidden placeholder-glow">
                        <div className="card-body p-0">
                            <div className="d-sm-flex align-items-top p-4 border-bottom-0 main-profile-cover placeholder col-12">
                                <div>
                                    <span className="avatar avatar-xxl avatar-rounded online me-3 placeholder col-3">
                                        
                                    </span>
                                </div>
                                <div className="flex-fill main-profile-info">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h6 className="fw-semibold mb-1 text-fixed-white placeholder col-6"></h6>
                                    </div>
                                    <p className="mb-1 text-muted text-fixed-white op-7 placeholder col-3"></p>
                                    
                                    <span className="me-3 d-block placeholder col-1"></span> 
                                    
                                    
                                </div>
                            </div>  
                            <div className="p-4 border-bottom border-block-end-dashed">
                                <p className="fs-15 mb-2 me-4 fw-semibold placeholder col-6"></p>
                                <div className="text-muted">
                                    <p className="mb-2">
                                        <span className="avatar avatar-sm avatar-rounded me-2 bg-light text-muted placeholder col-3">
                                            
                                        </span>
                                        <span className="placeholder col-3"></span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="avatar avatar-sm avatar-rounded me-2 bg-light text-muted placeholder col-3">
                                            
                                        </span>
                                        <span className="placeholder col-3"></span>
                                    </p>
                                    
                                </div>
                            </div>
                            
                            
                            
                        </div>
                    </div>
                </div>
                <div className="col-xxl-8 col-xl-12">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card custom-card placeholder-glow">
                                <div className="card-body p-0">
                                    
                                    {/* <!--Profile Card Start---> */}
                                    <div className="card custom-card">
                                        <div className="card-header">
                                            <div className="card-title placeholder col-2">
                                                
                                            </div>
                                        </div>
                                        <div className="card-body">

                                            <div className="row gy-3 mb-3">

                                                <div className="col-xl-3">
                                                    <label className="form-label placeholder col-8"> </label>
                                                    <input type="text" className="form-control placeholder col-12"/>
                                                </div>
                                                <div className="col-xl-3">
                                                    <label className="form-label placeholder col-8"> </label>
                                                    <input type="text" className="form-control placeholder col-12"/>
                                                </div>
                                                <div className="col-xl-4">
                                                    <label className="form-label placeholder col-8"> </label>
                                                    <input type="text" className="form-control placeholder col-12"/>
                                                </div>
                                                

                                            </div>

                                            <div className="row gy-3 mb-3">

                                                <div className="col-xl-4">
                                                    <label className="form-label placeholder col-8"> </label>
                                                    <input type="text" className="form-control placeholder col-12"/>
                                                </div>
                                                <div className="col-xl-4">
                                                    <label className="form-label placeholder col-8"> </label>
                                                    <input type="text" className="form-control placeholder col-12"/>
                                                </div>
                                                <div className="col-xl-4">
                                                    <label className="form-label placeholder col-8"> </label>
                                                    <input type="text" className="form-control placeholder col-12"/>
                                                </div>

                                            </div>   

                                        </div>  
                                        
                                        <div className="card-footer">
                                            <button className="btn btn-primary-light btn-wave ms-auto float-end placeholder col-2"></button>
                                        </div>  
                                        
                                    </div>
                                    {/* <!--Profile Card End---> */}


                                    {/* <!--Account Info Card Start---> */}
                                    <div className="card custom-card">
                                        <div className="card-header">
                                            <div className="card-title placeholder col-2">
                                                
                                            </div>
                                        </div>
                                        <div className="card-body">

                                            <div className="row gy-3">

                                                <div className="col-xl-4">
                                                    <label className="form-label placeholder col-8"> </label>
                                                    <input type="text" className="form-control placeholder col-12"/>
                                                </div>

                                            </div>    

                                        </div>  
                                                            
                                        <div className="card-footer">
                                            <button className="btn btn-primary-light btn-wave ms-auto float-end placeholder col-2"></button>
                                        </div>   
                                        
                                    </div> 
                                    {/* <!--Account Info Card End---> */}


                                    {/* <!--Change Password Card Start---> */}
                                    <div className="card custom-card">
                                        <div className="card-header">
                                            <div className="card-title placeholder col-2">
                                                
                                            </div>
                                        </div>
                                        <div className="card-body">

                                            <div className="row gy-3">

                                                <div className="col-xl-4">
                                                    <label className="form-label placeholder col-8"> </label>
                                                    <input type="text" className="form-control placeholder col-12"/>
                                                </div>
                                                <div className="col-xl-4">
                                                    <label className="form-label placeholder col-8"> </label>
                                                    <input type="text" className="form-control placeholder col-12"/>
                                                </div>
                                                <div className="col-xl-4">
                                                    <label className="form-label placeholder col-8"> </label>
                                                    <input type="text" className="form-control placeholder col-12"/>
                                                </div>
                                            </div>    

                                        </div>  

                                        <div className="card-footer">
                                            <button className="btn btn-primary-light btn-wave ms-auto float-end placeholder col-2"></button>
                                        </div>   
                                    </div> 
                                    {/* <!--Change Password Card End--->    */}
                                                   
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </>

    );    

}    


export default ProfileTabs