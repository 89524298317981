import { DEL, GET, POST, PUT, setAuthToken } from "../config/ApiConfig";
import { decryptData, encryptData } from "../helpers/EncryptHelper";
import { apiError } from "../helpers/ErrorHelper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dateFormat from "dateformat";
import config from "../config/config";

const module = {
    endPoint: '/companies',
    key: 'companies'
};

export const create = async (user_token, formData) => {
  setAuthToken(user_token);

  return await POST(`${module.endPoint}/add`, formData);
};

export const update = async (user_token, id, formData) => {
  setAuthToken(user_token);

  return await PUT(`${module.endPoint}/update/${id}`, formData);
};

export const remove = async (user_token, id) => {
  setAuthToken(user_token);

  return await DEL(`${module.endPoint}/delete/${id}`);
};

export const saveToStorage = (data, pagination) => {
  if (! config.isCache) {
    return;
  }

  let currentTime = dateFormat("", "dd mmm yyyy HH:MM:ss");

  const dataToStore = {
    [module.key]: data,
    pagination: pagination,
    time: currentTime,
  };
  localStorage.setItem(module.key, encryptData(dataToStore));
};

export const getData = async (param = {}) => {
  let user_token = param.user_token;

  if (! user_token) {
    return [];
  }

  let page = param.page ? param.page : 1;
  let limit = param.limit ? param.limit : 10;

  if (page == 1 && limit == 10 && localStorage.getItem(module.key)) {
    return decryptData(localStorage.getItem(module.key));
  }

  delete param.page;
  delete param.limit;
  let query = new URLSearchParams(param).toString();

  try {
    setAuthToken(user_token);

    const url = `${module.endPoint}/list?page=${page}&limit=${limit}&${query}`;
    const response = await GET(url);
    if (! response.status) { 
        toast.error(response.message);
    }

    if (page == 1 && limit == 10) {
        saveToStorage(response.data[module.key], response.data.pagination);
    }

    return response.data;

  } catch (error) {
      apiError(error);

      console.log(error);
  }
};

export const getFields = async (user_token, id) => {
  setAuthToken(user_token);

  return await GET(`${module.endPoint}/get-fields/${id}`);
};