import { Modal } from "react-bootstrap";

export const ConfirmDelete = ({module, showModal, setShowModal, loading, onConfirm}) => {
  const closeModal = () => {
    setShowModal(false);
  }

  return (
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Delete {module.singularTitle}?</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Are you sure you want to delete this {module.singularTitle.toLowerCase()}?<br /><br />Please note that this action cannot be reversed.</p>
      </Modal.Body>

      <Modal.Footer>
        <button type="button" className="btn btn-light" onClick={closeModal}>Cancel</button>
        <button type="button" className="btn btn-danger" onClick={onConfirm} disabled={loading}>
              {loading ? <span><span className="spinner-border spinner-border-sm align-middle"></span> Loading...</span> : 'Delete'}
          </button>
      </Modal.Footer>
    </Modal>
  );
};
