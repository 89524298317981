import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { getData as getCompanies } from '../../../models/CompaniesModel';
import { useAuth } from '../../../context/AuthContext';
import { createForm, saveFormsToStorage } from '../../../models/FormModel';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useNavigate } from 'react-router-dom';
import config from '../../../config/config';
import { useNotificationCount } from '../../../context/NotificationCountContext';
import Select from 'react-select';
import { useTheme } from '../../../context/ThemeContext';

const AddFormModal = ({ showModal, setShowModal, modalKey }) => {
  const { theme, toggleTheme, selectBoxTheme } = useTheme(); 

  const { state } = useAuth(); 
  let user_data = state.user_data;

  const navigate = useNavigate();  
  const currentRoute = useLocation().pathname;  

  const { notificationCount, setNotificationCount } = useNotificationCount();

  const [title, setTitle] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [companies, setCompanies] = useState([]);

  const [errors, setErrors] = useState({ title: '' });

  const closeModal = () => {
    setTitle('');
    setCompanyId('');
    setErrors({});
    setShowModal(false);
  };

  useEffect(() => {
    const fetchCompanies = async() => {
      try {
        const response = await getCompanies({ user_token: user_data.user_token, limit: '-1'});
        if (typeof response != typeof undefined && response) {
          let result = [];
          response.companies.forEach(function(item) {
            result.push({value: item._company_id, label: item.company_name});
          });

          setCompanies(result);
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchCompanies();
  }, [modalKey]);

  const addForm = async() => {
    // Clear previous errors
    setErrors({});

    console.log(companyId);

    const validationErrors = {};
    if (! title) {
      validationErrors.title = 'Title is required';
    }
    if (! companyId?.value) {
      validationErrors.companyId = 'Company is required';
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const form_data = {form_title: title, form_company_id: companyId.value};
      const response = await createForm(user_data.user_token, form_data);

      if (response.status) {
          toast.success(response.message);
          saveFormsToStorage(response.data.forms, response.data.pagination);
          setNotificationCount(localStorage.getItem('unread_notification_count'));

          navigate(`${config.adminDir}/forms/edit-form/${response.data.form_unique_id}`);
      }
      else {
        setErrors(response.error);
        toast.error(response.message);
      }
    } catch (error) {
      if (error.response.data.invalid_token==1) {
        toast.error(error.response.data.message);
        navigate(`${config.adminDir}/re-login?redirect=${currentRoute}`);
      }
    }

    closeModal();
  };

  return (
    <Modal show={showModal} onHide={closeModal} size="" style={{marginTop: '100px'}}>
      <Modal.Header closeButton>
        <Modal.Title>Add Form</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row gy-2">
          <div className="col-xl-12">
            <label className="form-label">Title: <span className="text-danger">*</span></label>
            <input type="text" className="form-control" placeholder="Form Title" value={title} onChange={(e) => setTitle(e.target.value)} />
            {errors.title && <p style={{ color: 'red' }}>{errors.title}</p>}
          </div>
          
          <div className="col-xl-12">
            <label className="form-label">Company: <span className="text-danger">*</span></label>
            <Select
              value={companyId}
              onChange={setCompanyId}
              options={companies}
              placeholder="Select Company"
              isSearchable={true}
              styles={selectBoxTheme}
              theme={(selectBoxTheme) => ({
                ...selectBoxTheme,
                borderRadius: 0,
                width: '100%'
              })}
            />

            {errors.companyId && <p style={{ color: 'red' }}>{errors.companyId}</p>}
          </div>
          
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-light" onClick={closeModal}>Cancel</button>
        <button type="button" className="btn btn-primary" onClick={addForm}>Submit</button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddFormModal;
