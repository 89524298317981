import React, { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import DataTable, { createTheme } from "react-data-table-component";
import { useTheme } from "../../../context/ThemeContext";
import TableLoader from "../../skeletons/TableLoader";
import { checkPermission } from "../../../helpers/GeneralHelper";
import { utcToLocal } from "../../../helpers/DateHelper";

export default function CompanyTable({
  module,
  data,
  loading,
  totalRows,
  page,
  limit,
  fetchData,
  openUpdateModal,
  openDeleteModal,
  handleView,
  isTrash,
}) {

  const columns = [
    {
      name: "#",
      selector: (row, index) => <div>{ ((page -1 ) * limit) + index +1 }.</div>,
      width: '50px'
    },
    {
      name: "Company Name",
      selector: (row) => row.company_name
    },
    // {
    //   name: "Added On",
    //   selector: (row) => utcToLocal(row.company_create_time)
    // },
    // {
    //   name: "Last Edited On",
    //   selector: (row) => utcToLocal(row.company_update_time)
    // },
  ];

  if (! isTrash && (checkPermission(module.route, 'edit') || checkPermission(module.route, 'delete'))) {
    columns.push({
      name: "Action",
      right: "true",
      cell: row => <div className="hstack gap-2 fs-15">
                    {checkPermission(module.route, 'edit') && <button type="button" className="btn btn-icon btn-sm btn-info" title="Edit" onClick={() => openUpdateModal(row)}>
                      <i className='bx bxs-edit'></i>
                    </button>}

                    {/* {checkPermission(module.route, 'delete') && <button type="button" className="btn btn-icon btn-sm btn-danger" title="Delete" onClick={() => openDeleteModal(row)}>
                      <i className='bx bxs-trash'></i>
                    </button>} */}
                  </div>
    });
  }

  if (isTrash) {
    columns.push({
      name: "Deleted At",
      selector: (row) => utcToLocal(row.company_delete_time)
    });
  }

  const { theme, toggleTheme } = useTheme();

  const handlePageChange = async (page) => {
    fetchData(limit, page);
  };

  const handleLimitChange = async (newLimit, page) => {
    if ((newLimit !== 10 && page !== 1) || localStorage.getItem(module.key)) {
      fetchData(newLimit, page);
    }
  };

  useEffect(() => {
    fetchData(limit, page);
  }, [isTrash]);

  createTheme("dark", {
    text: {
      primary: "#a2a3a4",
      secondary: "#2aa198",
    },
    background: {
      default: "#252729",
    },
    context: {
      background: "#cb4b16",
      text: "#a2a3a4",
    },
    divider: {
      default: "#073642",
    },
    action: {
      button: "rgba(0,0,0,.54)",
      hover: "rgba(0,0,0,.08)",
      disabled: "rgba(0,0,0,.12)",
    },
  });

  return (
    <DataTable
      // title={`Manage Notes (Private)`}
      className="clickable"
      // onRowClicked={(row) => handleView(row)}
      columns={columns}
      data={data}
      pagination
      paginationServer
      paginationTotalRows={totalRows}
      onChangeRowsPerPage={handleLimitChange}
      onChangePage={handlePageChange}
      progressPending={loading}
      progressComponent={<TableLoader noOfColumn={columns.length} />}
      fixedHeader
      fixedHeaderScrollHeight="550px"
      // highlightOnHover
      theme={`${theme === "light" ? "light" : "dark"}`}
    />
  );
};
